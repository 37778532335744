import React from 'react';

import Layout from '../../components/Layout';

export default function Notifications() {
  return (
    <Layout>
      <h4>Notifications</h4>
    </Layout>
  );
}

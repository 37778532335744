import React, { useState, useEffect } from 'react';
import './index.css';

import Layout from '../../components/Layout';
import Stepper from '../../components/Stepper';
import _ from 'lodash';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import store, { actions } from '../../store';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import useAsyncEffect from 'use-async-effect';
import apiClient from '../../utils/apiClient';
import { StateTransitionListener } from './StateTransitionListener';
import { PrimaryButton } from '../../components/Button';

function CreateProviderLocation() {
  const globalProvider = useSelector((state: store) => state.onboarding.provider);
  const globalLocation = useSelector((state: store) => state.onboarding.location);

  const [state, setState] = useState({
    locations: [],
    selectedLocation: '',
  });

  useAsyncEffect(async () => {
    const locations = await apiClient.get(`/strategies/fetch/locations`, {
      provider: globalProvider.id,
    });
    setState({
      ...state,
      locations,
    });
  }, []);

  const handleSelection = (e) => {
    const _selectedLocation = e.target.value;
    setState({
      ...state,
      selectedLocation: _selectedLocation,
    });
  };

  const handleSave = async () => {
    const { selectedLocation } = state;
    if (selectedLocation) {
      try {
        await apiClient.put(`/locations/${globalLocation.id}`, {
          externalLocationId: selectedLocation,
        });
        actions.nextState();
      } catch (err) {
        toast.error('There was an error associating location');
      }
    }
  };

  return (
    <Layout>
      <StateTransitionListener />
      <h4>Provider Onboarding</h4>
      <div className="mt-6">
        <Stepper />
      </div>
      <div className="upk-form mt-2 mb-3" style={{ backgroundColor: 'white' }}>
        <div className="header">Associate API Location</div>
        <div className="body">
          <FormControl fullWidth={true} className="mb-2">
            <InputLabel id="product-code-label">Location</InputLabel>
            <Select
              labelId="product-code-label"
              id="product-code"
              value={state.selectedLocation || ''}
              fullWidth={true}
              onChange={handleSelection}>
              {state.locations.map((l: any, i) => (
                <MenuItem key={`strategy-${i}`} value={l.externalId}>
                  {l.streetAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <PrimaryButton label="Next" onClick={handleSave} />
        </div>
      </div>
    </Layout>
  );
}

export default CreateProviderLocation;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export default withStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: '1rem',
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: 'white',
    '&$checked': {
      transform: 'translateX(12px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#00AF9B',
        borderColor: '#00AF9B',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `#A0A3BD`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#A0A3BD',
  },
  checked: {},
})(Switch);
import React from 'react';
import apiClient from '../../utils/apiClient';
import { toast } from 'react-toastify';
import './ImageUploader.css';
import { ReactComponent as CameraIcon } from '../../assets/Icons/camera.svg';
import { isDevelopment } from '../../utils/common';

export interface PathInputEventI {
  target: {
    value: string
    preview: string
  }
}

interface ImageUploaderPropsI {
  onChange: (event: PathInputEventI) => void
  value: string
  isLogo?: boolean
}

function ImageUploader (props: ImageUploaderPropsI) {
  const {
    onChange,
    value,
    isLogo,
  } = props;

  const hiddenFileInput = React.useRef(null);

  const handleUploadClick = (e: any) => {
      hiddenFileInput.current.click();
  }

  const handleUpload = async (event) => {
    const files = event.target.files;
    if(files.length){
      try {
        toast.info('Uploading Image...')
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        const { path } = isDevelopment ? { path: 'a.mock.path' } : await apiClient.upload('/upload',formData);
        if (value) {
          window.URL.revokeObjectURL(value);
        }
        const localImageUrl = window.URL.createObjectURL(file);
        onChange({
          target: {
            value: path,
            preview: localImageUrl,
          }
        });
        toast.success('Successfully Uploaded Image')
      } catch(err){
          console.error(err);
      }
    }
  }
  return (
    <div onClick={handleUploadClick} className={`image-uploader${value ? ' filled' : ''}${isLogo ? ' logo' : ''}`}>
      {
        value ? (
          <img
            src={value}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 'auto',
              color: '#D9DBE9',
            }}
          >
            <CameraIcon className="camera-icon" />
            <span>{isLogo ? 'Upload Logo' : 'Upload Cover Image'}</span>
          </div>
        )
      }
      <input
        style={{ display: "none" }}
        type="file"
        ref={hiddenFileInput}
        onChange={(e: any) => {handleUpload(e)}}
      />
    </div>
  )
}

export default ImageUploader;

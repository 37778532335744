import React from 'react';
import './TextInput.css';

export type Ref = HTMLInputElement;

interface TextInputPropsI {
  id: string
  placeholder: string
  name: string
  type: string
  error?: boolean
  value?: string
  helperText?: string | false
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputProps?: any
}

const TextInput = React.forwardRef<Ref, TextInputPropsI>((props, ref) => {
  const {
    id,
    name,
    type,
    error,
    placeholder,
    value,
    helperText,
    onChange,
    inputProps,
  } = props;
  return (
    <div className="text-input-container text-small">
      <input
        ref={ref}
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        className={`text-input${error ? ' error' : ''}`}
        {...inputProps}
      />
      <div className={`helper-text${error ? ' error' : ''}`}>{helperText}</div>
    </div>
  )
});

export default TextInput;

import React, { useEffect, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import Layout from '../../components/Layout';
import apiClient from '../../utils/apiClient';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PriceField from '../../components/PriceField';
import { PrimaryButton } from '../../components/Button';
import TextField from '../../components/TextField';

function CreditsView() {

    const [credits, setCredits] = useState([]);
    const [credit, setCredit] = useState(null);

    const history = useHistory();

    useAsyncEffect(async () => {
        const _credits = await apiClient.get(`/credits`);
        setCredits(_credits);
    }, []);

    const handleSelection = (e, c) => setCredit(c);

    const handleUpdate = (attr) => (e) => setCredit({
        ...credit,
        [attr]: e.target.value
    });

    const getCreditAttr = (attr) => _.get(credit,attr,'');

    const saveCredit = async() => {
        try {
            await apiClient.put(`/credits/${credit.id}`, credit);
        } catch(err){
            toast.error('Error updating credit.')
            console.error(err);
        }
    }
    const deleteCredit = async() => {
        try {
            await apiClient.delete(`/credits/${credit.id}`);
        } catch(err){
            toast.error('Error deleting credit.')
            console.error(err);
        }
    };
    const createNewCredit = () => history.push('/credits/create')

    return (
        <Layout>
            <h4>Credits</h4>
            <div className="upk-form mt-2 mb-3" style={{ backgroundColor: "white" }}>
                <div className="header">
                    <div className='title'>Manage Credits</div>
                    <PrimaryButton label='Create New' style={{ marginLeft: 'auto' }} onClick={createNewCredit}/>
                </div>
                <div className="body">
                    <Autocomplete
                        id="credits-autocomplete"
                        className="mb-1"
                        options={credits}
                        fullWidth
                        getOptionLabel={(c) => c.title}
                        disabled={!credits.length}
                        onChange={handleSelection}
                        renderInput={(params) => <TextField {...params} label="Credits" variant="outlined" />}
                    />
                    {
                        /* --------------- Edit Credit ----------------- */
                        _.has(credit, 'id') && (
                            <div className="border">
                                <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}> Credit Details</h4>

                                <TextField
                                    marginTop
                                    label="Title"
                                    fullWidth={true}
                                    onChange={handleUpdate('title')}
                                    value={getCreditAttr('title')} />

                                <PriceField
                                    marginTop
                                    label="Total Amount Available (in dollars)"
                                    fullWidth={true}
                                    disabled
                                    onChange={handleUpdate('totalInCents')}
                                    value={getCreditAttr('totalInCents')} />

                                <TextField
                                    style={{ margin: '1rem 0 2rem 0'}}
                                    label="Description"
                                    fullWidth={true}
                                    multiline
                                    rows={1}
                                    rowsMax={3}
                                    onChange={handleUpdate(`description`)}
                                    value={getCreditAttr('description')} />

                                    <PrimaryButton label='Update' onClick={saveCredit}/>

                                    {/*<Button onClick={deleteCredit}>
                                        Delete
                                    </Button>*/}
                            </div>
                        )
                        /* --------------- End Edit Credit ----------------- */
                    }
                </div>
            </div>
        </Layout>
    )
}

export default CreditsView;

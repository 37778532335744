import React from 'react';
import './SidePanel.css';
import logo from './../../assets/logoNew.png';

export default (props: any) => {
  return (
    <div className="side-panel">
      <div className="logo-container">
        <img src={logo} className="logo"/>
        <div className="logo-text">For Business</div>
      </div>
      {props.children}
    </div>
  );
}

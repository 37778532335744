import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import store from '../../../store';
import payoutImage from '../../../assets/payout.png';
import Button, { ColorEnum } from '../../../components/ProviderOnboarding/Button';
import OnboardingStepper from '../../../components/ProviderOnboarding/Stepper';
import TextInput from '../../../components/ProviderOnboarding/TextInput';

interface StripeAccountCreationProps {
  handleReset?: () => void,
  handleClick: () => void,
  handleAttachStripeAccount?: (stripeAccountId: string) => void,
  loading: boolean,
  retry: boolean,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    background: '#F8F9FF',
  },
  container: {
    maxWidth: '500px',
    textAlign: 'center',
    margin: 'auto',
    marginTop: '30vh',
  },
  text: {
    fontSize: '1rem',
  },
  button: {
    fontFamily: 'Poppins',
  },
  spinner: {
    color: 'inherit',
  },
  emphasis: {
    color: '#00C996',
  },
  h4: {
    fontFamily: 'Poppins',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#4E4B66',
  },
  img: {
    marginBottom: theme.spacing(2),
    width: '80%',
  },
}));

const SpinnerAdornment = props => (
  <CircularProgress
    className={props.class}
    size={20}
  />
);

const StripeAccountCreation = (props: StripeAccountCreationProps) => {
  const isAdmin = useSelector((state:store)=>state.user.isAdmin);
  const { handleReset, handleClick, handleAttachStripeAccount, loading, retry } = props;
  const classes = useStyles();

  const [stripeAccountId, setStripeAccountId] = useState('');

  const actionText = retry ? 'Complete Stripe Account' : 'Connect To Stripe';
  return (
    <div className={classes.root}>
      <OnboardingStepper totalSteps={2} activeStep={0} checkedSteps={0} />
      <div className={classes.container}>
        <Typography variant="h4" className={classes.h4} gutterBottom>
        Get <span className={classes.emphasis}>paid</span>, set up payouts <span className={classes.emphasis}>now</span>!
        </Typography>
        <Typography variant="body1" className={classes.text} style={{marginBottom: '0'}} gutterBottom>
          We use Stripe to provide you with quick and secure payments for your hard work.
        </Typography>
        <img src={payoutImage} className={classes.img} />
        <div style={{
          width: '200px',
          margin: 'auto',
        }}>
          <Button
            type="button"
            fullWidth
            color={ColorEnum.primary}
            style={{
              fontSize: '0.75rem',
              color: 'white',
              width: 'fit-content',
              lineHeight: '0.6rem',
              padding: '1rem',
              minWidth: '160px',
              alignItems: 'center',
            }}
            active={!loading}
            disabled={loading}
            onClick={handleClick}
            >
            {loading ? (
              <div style={{position: 'relative', bottom: '0.375rem'}}>
                <SpinnerAdornment class={classes.spinner}/>
              </div>
            ) : actionText}
          </Button>
        </div>
        OR
        <TextInput
          id="stripeAccountId"
          name="stripeAccountId"
          type="text"
          placeholder="Stripe Account ID"
          value={stripeAccountId}
          onChange={(e)=> setStripeAccountId(e.target.value)}
          inputProps={{
            style: {
              outline: '2px auto black',
              background: 'white',
            }
          }}
        />
        <Button
          type="button"
          fullWidth
          color={ColorEnum.primary}
          active={!!stripeAccountId}
          disabled={!stripeAccountId}
          onClick={() => handleAttachStripeAccount(stripeAccountId)}
        >
          Attach Stripe Account
        </Button>
        {handleReset &&
          <Button 
            color={ColorEnum.black}
            active={true}
            type="button"
            onClick={handleReset}
            class={classes.button}
            fullWidth
            style={{marginTop: '1rem', color: 'white'}}
          >
            Reset
          </Button>
        }
      </div>
    </div>
  );
};

export default StripeAccountCreation;

import React from 'react';
import './index.css';

import appointmentImage from '../../assets/appointment.png';
import _ from 'lodash';
import store, { actions } from '../../store';
import { useSelector } from 'react-redux';

import { StateTransitionListener } from './StateTransitionListener';
import ProviderServiceItemMapping from '../../components/ProviderServiceItemMapping';

import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import OnboardingStepper from '../../components/ProviderOnboarding/Stepper';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import { ManualServiceItemSelect } from '../../components/ManualServiceItemSelect';
import { ServicesMenu } from '../../components/ServicesMenu';
import { StrategyTypes } from '../../enums/Strategies';

const contentByStrategyType = {
    [StrategyTypes.MINDBODY]: {
        header: 'Assign Mindbody service items to Upkeep service items',
        body: "Match your service items with the displayed Upkeep categories. We'll use this information to help you populate your listing on our system.",
    },
    [StrategyTypes.BOOKER]: {
        header: 'Assign Booker service items to Upkeep service items',
        body: "Match your service items with the displayed Upkeep categories. We'll use this information to help you populate your listing on our system.",
    },
    [StrategyTypes.MANUAL]: {
        header: 'Select supported Upkeep service items',
        body: "Configure your appointment availability for each service item. We'll use this information to help you populate your listing on our system.",
    },
    [StrategyTypes.BOULEVARD]: {
        header: 'Select supported Upkeep service items',
        body: "Configure your appointment availability for each service item. We'll use this information to help you populate your listing on our system.",
    },
    [StrategyTypes.SQUARE]: {
        header: 'Assign Square service items to Upkeep service items',
        body: 'Match your service items with the displayed Upkeep categories. We\'ll use this information to help you populate your listing on our system.',
    },
};

export default function CreateProviderSvcItems() {
    const globalProvider = useSelector((state: store) => _.get(state, 'onboarding.provider'));
    const globalLocation = useSelector((state: store) => _.get(state, 'onboarding.location'));
    const selectedStrategy = useSelector((state:store)=> _.get(state,'onboarding.selectedStrategy'));

    const handlePrevious = () => {
        actions.prevState();
    }

    const handleNext = () => {
        actions.nextState();
    }

    const content = contentByStrategyType[selectedStrategy];

    return (
        <Layout>
            <StateTransitionListener/>
            <SidePanel>
                <SidePanelImage imgSrc={appointmentImage} caption={'“What services does your business provide?”'}/>
            </SidePanel>
            <OnboardingStepper activeStep={2} totalSteps={3} checkedSteps={2} />
            <div className="content-container">
                <div className="content wide">
                    <h1>{content.header}</h1>
                    <p>{content.body}</p>
                    {
                        globalProvider && selectedStrategy === StrategyTypes.MANUAL
                        ? (
                            <>
                                <ServicesMenu providerId={globalProvider.id} locationId={globalLocation.id}></ServicesMenu>
                                <br />
                                <h1>Select your time slots, when will you be available?</h1>
                                <ManualServiceItemSelect locationId={globalLocation.id} providerId={globalProvider.id} provider={globalProvider} />
                            </>
                        )
                        : <ProviderServiceItemMapping providerId={globalProvider.id} locationId={globalLocation.id} />
                    }
                    <div className="actions">
                        <Button
                            type="button"
                            class="next"
                            color={ColorEnum.transparent}
                            textColor="#4E4B66"
                            onClick={handlePrevious}
                        >
                            Back
                        </Button>
                        <Button
                            type="button"
                            class="next"
                            color={ColorEnum.primary}
                            textColor="white"
                            active={true}
                            onClick={handleNext}
                        >
                            Get Started
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

import React from 'react';
import { Grid, FormControlLabel, Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import ImageUploader from '../ImageUploader';
import TextField from '../TextField';
import { StrategyTypes } from '../../enums/Strategies';
import Switch from '../Switch';
import Typography from '../Typography';

interface ManageProviderProps {
  provider: any;
  handleProviderInput: (text: string) => (e: any) => void;
  residualProvider: (text: string) => any;
  residualLocation: (text: string) => boolean;
  updateLocation: (attr: string, value: boolean) => void;
  updateProvider: (attr: string, value: boolean) => void;
}

export default function ManageProvider({
  provider,
  handleProviderInput,
  residualProvider,
  residualLocation,
  updateLocation,
  updateProvider,
}: ManageProviderProps): JSX.Element {
  const handleSwitchInput = (attr: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    attr === 'isSMSNotificationsEnabled' ? updateProvider(attr, value) : updateLocation(attr, value);
  };

  const onChangeAvailableDay = (event, value) => {
    updateProvider('availableAfterDays', value);
  };

  return (
    <div className="border mb-1">
      <Grid container>
        <Grid item xs={8}>
          <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
            {' '}
            Manage Provider - {residualProvider('strategy')}
          </h4>
        </Grid>
        <Grid item xs={4}>
            <FormControlLabel
              label="Availability visible in mobile app?"
              control={
                <Switch checked={residualLocation('isPublic') || false} onChange={handleSwitchInput('isPublic')} />
              }
            />
        </Grid>
      </Grid>
      <TextField
        marginTop={true}
        label="Name"
        fullWidth={true}
        onChange={handleProviderInput(`name`)}
        value={residualProvider('name')}
      />

      <TextField
        marginTop={true}
        label="Description"
        fullWidth={true}
        onChange={handleProviderInput(`description`)}
        value={residualProvider('description')}
      />

      <TextField
        marginTop={true}
        label="phoneNumber"
        fullWidth={true}
        onChange={handleProviderInput(`phoneNumber`)}
        value={residualProvider('phoneNumber')}
      />

      <FormControlLabel
        label="Enable Message Notification?"
        control={
          <Switch checked={residualProvider('isSMSNotificationsEnabled') || false} onChange={handleSwitchInput('isSMSNotificationsEnabled')} />
        }
      />
          
      <Box mt={2}>
        <Typography gutterBottom>Available after days:</Typography>
        <ToggleButtonGroup
          value={residualProvider('availableAfterDays')}
          exclusive
          onChange={onChangeAvailableDay}
          aria-label="text alignment">
          <ToggleButton value={0} aria-label="left aligned">
            Same day
          </ToggleButton>
          <ToggleButton value={1} aria-label="centered">
            Next day
          </ToggleButton>
          <ToggleButton value={2} aria-label="right aligned">
            2 days
          </ToggleButton>
          <ToggleButton value={7} aria-label="justified">
            1 week
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <ImageUploader label="Logo URL" onChange={handleProviderInput(`logoURL`)} value={residualProvider('logoURL')} />

      <ImageUploader
        label="Cover Image URL"
        onChange={handleProviderInput(`coverImageURL`)}
        value={residualProvider('coverImageURL')}
      />
    </div>
  );
}

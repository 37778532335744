import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { InfoOutlined } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import store, { actions } from '../../store';
import apiClient from '../../utils/apiClient';
import { getSettlementAccount } from '../../utils/settlementAccountHelpers';
import { Progress } from './types';
import Checklist from './Checklist';
import ProviderCover from './ProviderCover';
import Activity from './Activity';
import Appointments from './Appointments';
import DrawerContent from './DrawerContent';
import Layout from '../../components/Layout';
import { PrimaryButton } from '../../components/Button';
import CustomDrawer from '../../components/CustomDrawer';
import Calendar from '../../components/Calendar/Calendar';
import TextField from '../../components/TextField';
import moment from 'moment-timezone';

import './index.css';
import CreateAppointmentModal from '../Provider/CreateAppointmentModal';

const initialProgress: Progress = {
  completed: false,
  steps: { availability: true, stripe: false, cover: false },
};

export enum AppointmentStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  LATE_CANCELLED = "LATE_CANCELLED",
  FILMING = 'FILMING'
}

export default function ProviderOverview(): JSX.Element {
  const history = useHistory();
  const isAdmin = useSelector((state: store) => state.user.isAdmin);
  const provider = useSelector((state: store) => state.onboarding.provider);
  const viewAsProvider = useSelector((state: store) => state.user.viewAsProvider);
  const [progress, setProgress] = useState<null | Progress>(null);
  const [appointments, setAppointments] = useState<null | any[]>(null);
  const [showCreateAppointmentModal, setShowCreateAppointmentModal] = useState(false);
  const [drawerState, setDrawerState] = useState<{ open: boolean; context: any }>({
    open: false,
    context: null,
  });
  const [providers, setProviders] = useState<null | any[]>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const checkStripeOnboardingStatus = useCallback(async () => {
    if (!provider.location?.settlementAccount) {
      return;
    }
    const settlementAccountDetails = await getSettlementAccount(provider.location.settlementAccount.id);
    if (settlementAccountDetails?.charges_enabled && settlementAccountDetails?.payouts_enabled) {
      return true;
    }
    const remainingRequirements = settlementAccountDetails?.requirements?.currently_due;

    if (_.isUndefined(remainingRequirements)) {
      return false;
    }
    const requirements = [
      'business_profile.url',
      'company.name',
      'company.tax_id',
      'tos_acceptance.date',
      'tos_acceptance.ip',
    ];
    let completed = true;
    for (let req of remainingRequirements) {
      if (requirements.includes(req)) {
        completed = false;
        break;
      }
    }
    return completed;
  }, [provider.location]);

  const checkProfileProgress = useCallback(async () => {
    if (!provider) return;
    let status = initialProgress;
    let completed = true;
    const { location } = provider;
    const stripeOnboardingCompleted = await checkStripeOnboardingStatus();
    status.steps.stripe = !!stripeOnboardingCompleted;
    if (provider.coverImageURL) {
      status.steps.cover = true;
    }
    let availabilityKeys = [];
    const daysInWeek = Array.from(Array(7).keys());
    for (let day of daysInWeek) {
      availabilityKeys = [...availabilityKeys, 'day' + (day + 1) + 'close', 'day' + (day + 1) + 'open'];
    }
    for (let key of availabilityKeys) {
      if (!location[key]) {
        status.steps.availability = false;
        break;
      }
    }
    for (let key in status.steps) {
      if (!status.steps[key]) {
        completed = false;
        break;
      }
    }
    status.completed = completed;
    setProgress(status);
  }, [checkStripeOnboardingStatus, provider]);

  useEffect(() => {
    (async () => {
      if (isAdmin || viewAsProvider) return;
      const listProviders = await apiClient.get('/providers');
      setProviders(listProviders);
      const initialProvider = listProviders[0];
      actions.setProvider(initialProvider);
      actions.setLocation(initialProvider.location);
    })();
  }, [isAdmin, viewAsProvider]);

  const loadAppointments = useCallback(async () => {
    closeDrawer();
    const appts = await apiClient.get('/appointments', {
      provider: provider.id,
      user: true,
    });
    const tz =  provider.timezone || 'America/Los_Angeles'
    appts.forEach(appointment => appointment.startDate = moment(appointment.startDate).tz(tz).format())
    setAppointments(appts);
  }, [provider.id]);

  useEffect(() => {
    if (!_.get(provider, 'id') || !isNaN(provider.location)) {
      return;
    }
    setLoading(true);
    loadAppointments();
    checkProfileProgress();
  }, [checkProfileProgress, loadAppointments, provider]);

  useEffect(() => {
    if (appointments && progress) {
      setLoading(false);
    }
  }, [appointments, progress]);

  const handleCalEventClick = (calEvent) => {
    const { data } = calEvent.event.extendedProps;
    const { appointment } = data;
    setDrawerState({
      open: true,
      context: appointment,
    });
  };

  const switchProvider = (name: string) => {
    if (!name) return;
    const found = providers.find((p) => p.name === name);
    actions.setProvider(found);
    actions.setLocation(found.location);
  };

  const closeDrawer = () => {
    setDrawerState({ context: null, open: false });
  };

  const callAppointments = async () => {
    const appts = await apiClient.get('/appointments', {
      provider: provider.id,
      group: true,
    });
    const tz = provider.timezone || 'America/Los_Angeles'
    appts.map((appt: any) => appt.startDate = moment(appt.startDate).tz(tz).format())
    setAppointments(appts);
  }

  return (
    <Layout>
      <CreateAppointmentModal 
      open={showCreateAppointmentModal} 
      setOpen={setShowCreateAppointmentModal}
      provider={provider}
      callAppointments={callAppointments}
      providerView= {true}
      />
      <CustomDrawer closeDrawer={closeDrawer} open={drawerState.open}>
        {drawerState.context && (
          <DrawerContent
            closeDrawer={closeDrawer}
            appointment={drawerState.context}
            timezone={provider.timezone}
            loadAppointments={loadAppointments}
          />
        )}
      </CustomDrawer>
      <div className="overview-heading">
        <div className="heading-medium">Overview</div>
        {!isAdmin && providers?.length > 1 && (
          <div style={{ width: '30%', float: 'left' }}>
            <Autocomplete
              options={providers.map((p) => p.name)}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => value}
              onChange={(event: any, selectedProvider: any) => switchProvider(selectedProvider)}
              renderInput={(params) => <TextField {...params} label="Switch Provider" />}
            />
          </div>
        )}
        {isAdmin && (
          <>
            <PrimaryButton
              style={{ margin: '5px' }}
              label="View As Admin"
              onClick={() => {
                actions.setViewAsAdmin();
                history.push({ pathname: '/providers', state: { fromViewAsAdmin: true } });
              }}
            />
          </>
        )}
      </div>
      {loading ? (
        <div className="mt-2">
          Loading Details...
          <CircularProgress color="inherit" size={20} />
        </div>
      ) : (
        <>
          {!progress?.steps.stripe && (
            <div className="banner mt-2">
              <div>
                <InfoOutlined fontSize="small" style={{ color: '#348D84' }} />
                <span className="ml-1">
                  Get <b style={{ color: '#348D84' }}>paid</b>, set up payouts now!
                </span>
              </div>
              <PrimaryButton label="Connect to stripe" onClick={() => history.push('/provider/onboarding/stripe')} />
            </div>
          )}
          <div className="overview-container">
            <div className="provider-info pt-1">
              {progress && (
                <ProviderCover name={provider.name} coverImageURL={provider.coverImageURL} progress={progress} />
              )}
              {appointments && provider.location && (
                <div>
                  <Activity appointments={appointments} settlementAccount={provider.location.settlementAccount} />
                  <Appointments
                    timezone={provider.timezone}
                    appointments={appointments}
                    setDrawerState={setDrawerState}
                    loadAppointments={loadAppointments}
                  />
                  <div className="upk-card mt-1 calendar">
                    <Calendar clickHandler={handleCalEventClick} appointments={appointments} setShowCreateAppointmentModal={setShowCreateAppointmentModal} />
                  </div>
                </div>
              )}
            </div>
            <div className="checklist-container pt-1 pl-1">
              {provider && _.get(provider, 'id') && progress && (
                <Checklist strategy={provider.strategy} steps={progress.steps} />
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';

const drawerWidth = 500;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: '100%',
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
    },
    drawerPaper: {
      width: '100%',
      padding: theme.spacing(5, 4),
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
    },
  }),
);

export default function CustomDrawer({ open, children, closeDrawer }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={closeDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        {children}
      </Drawer>
    </div>
  );
}

import React, { useState } from 'react';
import { MenuItem, Grid, makeStyles, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Select from './Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import Switch from './Switch';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& .MuiInputBase-input': {
      height: '35px',
    },
  },
  select: {
    borderRadius: '17px',
    backgroundColor: 'lightgrey', 
  },
}));

const propertyMapping: Record<string, string> = {
  Injectables: 'Injectables',
  Facials: 'Facials',
  'Hair Removal': 'HairRemoval',
  'Body Contour': 'BodyContour',
};

export default function CommissionAndFees({ services, setServices, residualProvider, updateProvider }) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:599px)')
  const isTabScreen = useMediaQuery('(min-width:600px) and (max-width:1279px)');
  const [providerPays, setProviderPays] = useState<number>(1);
  const [numberper, setNumberPer] = useState({
    Injectables: '',
    Facials: '',
    HairRemoval: '',
    BodyContour: '',
  });

  const handleSelectionChange = (serviceCategoryId: string, value: number, name: string) => {
    if (propertyMapping[name]) {
      const updatedProperty = propertyMapping[name];
      console.log(updatedProperty, name);
      setNumberPer({ ...numberper, [updatedProperty]: '' });
    }
    setServices({
      ...services,
      [serviceCategoryId]: {
        ...services[serviceCategoryId],
        percentage: value,
      },
    });
  };

  const handlesubmit = (e, id: string, name: string,value: number) => {
    e.preventDefault();
    setServices({
      ...services,
      [id]: {
        ...services[id],
        percentage: Number(numberper[name.split(" ").join("")]) || value
      },
    });
  };

  const handleChangePer = (id: string, name: string, value: string | number) => {
    if(Number(value) > 100){
      value = '100';
    }
    if(Number(value) < 0){
      value = '';
    }
    if (propertyMapping[name]) {
      const updatedProperty = propertyMapping[name];
      setNumberPer({ ...numberper, [updatedProperty]: value });
      setServices({
        ...services,
        [id]: {
          ...services[id],
          percentage: Number(value) 
        },
      });
    }
  };

  const handleSwitchInput = (attr: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    updateProvider(attr, value)
  };

  return (
    <>
      <Grid item >
        <FormControlLabel
          label="Enable Upkeep Paying Processing Fees?"
          control={
            <Switch checked={residualProvider('isUpkeepPayingProcessingFees') || false} onChange={handleSwitchInput('isUpkeepPayingProcessingFees')}/>
          }
        />
      </Grid>
      {Object.keys(services).map((id) => {
        const service = services[id];
        return (
          <Grid container direction="row" justifyContent="space-between" alignItems="center" key={id}>
            <Grid item xs={12} lg={6}>
              <div style={{ marginLeft: '1rem' }}>{service.name}</div>
            </Grid>
            <Grid item xs={6} lg={2} style={{}}>
              <Select
                key={service.commissionId}
                labelId={id}
                id={id}
                classes={{ select: numberper[service.name.split(" ").join("")].length > 0 || (service.percentage !== 15 && service.percentage !== 20) ?  classes.select : null }}
                onChange={(event) => handleSelectionChange(id, event.target.value, service.name)}
                defaultValue={20}
                value={numberper[service.name.split(" ").join("")] || service.percentage}>
                <MenuItem style={{ fontFamily: 'Poppins' }} key={15} value={15}>
                  15 %
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins' }} key={20} value={20}>
                  20 %
                </MenuItem>
              </Select>

            </Grid>
            <Grid item xs={6} lg={2}>
              <TextField
                key={service.commissionId}
                id={id}
                sx={{ ml: 2, mt: '10px' , ...(isTabScreen && {
                  width: '100%'
                })}}
                label="Custom%"
                variant="outlined"
                type="Number"
                InputProps={{
                  className: classes.customTextField,
                }}
                value={(numberper[service.name.split(" ").join("")].length > 0 || Number(service.percentage) === 15 || Number(service.percentage) === 20 ? numberper[service.name.split(" ").join("")] : service.percentage) || ''}
                onChange={(e) => handleChangePer(id, service.name, e.target.value)}
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={2} >
              <Button
                sx={{
                  ml: 2,
                  mt: '10px',
                  height: '45px',
                  backgroundColor: '#348D84',
                  color: 'white',
                  ':hover': {
                    bgcolor: '#348D84',
                    color: 'white',
                  },
                  ...(isSmallScreen && {
                    marginBottom: '10px',
                    marginLeft: '65%'
                  }),
                  ...(isTabScreen && {
                    marginLeft: '85%'
                  })
                }}
                variant="contained"
                onClick={(e) => handlesubmit(e, id, service.name,service.percentage)}>
                Submit
              </Button>
            </Grid>
          </Grid>
        );
      })}
      {/* <Typography style={{ marginTop: '2rem' }}>Provider pays fees?</Typography> */}
      <Select labelId="pay" id="pay" value={providerPays} onChange={(event) => setProviderPays(event.target.value)}>
        {/* <MenuItem style={{ fontFamily: 'Poppins' }} key={`yes`} value={`yes`}>
          Yes
        </MenuItem> */}
        {/* <MenuItem style={{ fontFamily: 'Poppins' }} key={`no`} value={`no`}>
          No
        </MenuItem> */}
      </Select>
    </>
  );
}

import store from '../../store';
import { useSelector } from "react-redux";
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { stateTransitions, StepEnum } from '../../store/onboarding/steps';

export const StateTransitionListener = function(){
    const statePointer = useSelector((state:store)=>state.onboarding.statePointer);
    const history = useHistory();

    let stepHistory = useRef(stateTransitions[statePointer]);

    useEffect(() => {
        const nextStep = stateTransitions[statePointer];
        stepHistory.current = nextStep;

            switch(nextStep){
                case StepEnum.STRATEGY:
                    history.push('/provider/onboarding')
                break;
                case StepEnum.CREDENTIALS:
                    history.push({
                        pathname: '/provider/onboarding/credentials',
                        search: window.location.search,
                    });
                break;
                case StepEnum.LOCATION:
                    history.push('/provider/onboarding/location')
                break;
                case StepEnum.PROVIDER:
                    history.push('/provider/onboarding/create')
                break;
                case StepEnum.PROVIDER_SERVICE_ITEMS:
                    history.push('/provider/onboarding/serviceitems')
                break;
                case StepEnum.PROVIDER_LOCATION:
                    history.push('/provider/onboarding/apilocation')
                break;
                case StepEnum.FINISHED:
                    history.push('/provider/onboarding/stripe');
                break;
            }
    },[statePointer]);

    return <React.Fragment></React.Fragment>;
}

import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme';

const useStyles = makeStyles({
  sidebar: {
    width: 334,
    borderRight: `1px solid ${grey[200]}`,
    height: '100vh',
    '& .header-title': {
      color: colors.grey[600],
    },
    '& .title': {
      color: colors.grey[400],
    },
  },
  content: {
    background: '#F7F7FC',
    padding: '52px 42px',
    height: '100vh',
    overflow: 'auto',
  },
  addTier: {
    height: 73,
    cursor: 'pointer',
    color: colors.grey[400],
  },
  priceTiers: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  priceTier: {
    padding: '21px 16px',
    cursor: 'pointer',
    borderRadius: 8,
    color: colors.grey[600],
    fontWeight: 600,
    position: 'relative',
    paddingRight: 40,
    '& .btn-delete-tier': {
      position: 'absolute',
      right: '8px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '35px',
      minWidth: 'initial',
      padding: 0,
      borderRadius: '8px',
    },
    '&:hover': {
      background: grey[100],
    },
    '&.active': {
      background: grey[100],
    },
  },
});

export default useStyles;

enum OnboardActionTypes {
    SET_CREDENTIALS  = "SET_CREDENTIALS",
    SET_LOCATION = "SET_LOCATION",
    SET_PROVIDER = "SET_PROVIDER",
    SET_SETTLEMENT_ACCOUNT = "SET_SETTLEMENT_ACCOUNT",
    SET_ROOM_IMPORT_STATUS = "SET_ROOM_IMPORT_STATUS",
    SET_PROVIDER_SVC_ITEMS_STATUS = "SET_PROVIDER_SVC_ITEMS_STATUS",
    ABORT_ONBOARDING = "ABORT_ONBOARDING",
    SET_STRATEGY = "SET_STRATEGY",
    SET_STATE_POINTER = "SET_STATE_POINTER",
    NEXT_STATE = "NEXT_STATE",
    PREV_STATE = "PREV_STATE"
}

interface NextState {
    type: OnboardActionTypes.NEXT_STATE
}
interface PrevState {
    type: OnboardActionTypes.PREV_STATE
}
interface SetStatePointer {
    type: OnboardActionTypes.SET_STATE_POINTER,
    statePointer: number
}

interface SetStrategy {
    type: OnboardActionTypes.SET_STRATEGY,
    payload: string
}

interface SetCredentials {
    type: OnboardActionTypes.SET_CREDENTIALS,
    payload: any
}

interface SetLocation {
    type: OnboardActionTypes.SET_LOCATION,
    payload: any
}

interface SetProvider {
    type: OnboardActionTypes.SET_PROVIDER,
    payload: any
}

interface SetSettlementAccount {
    type: OnboardActionTypes.SET_SETTLEMENT_ACCOUNT,
    payload: any
}

interface SetRoomImportStatus {
    type: OnboardActionTypes.SET_ROOM_IMPORT_STATUS,
    payload: any
}

interface SetProviderServiceItemStatus {
    type: OnboardActionTypes.SET_PROVIDER_SVC_ITEMS_STATUS,
    payload: any
}

interface AbortOnboarding {
    type: OnboardActionTypes.ABORT_ONBOARDING,
    payload: any
}

export { OnboardActionTypes };
export type OnboardActions = {
    PrevState,
    NextState,
    SetStatePointer,
    SetStrategy,
    SetCredentials,
    SetLocation,
    SetProvider,
    SetSettlementAccount,
    SetRoomImportStatus,
    SetProviderServiceItemStatus,
    AbortOnboarding
}

import React from 'react';
import { Box, Typography, IconButton, Grid, TextField } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ServiceCategory } from '.';
import useStyles from './index.styles';

type EditServiceCategoryProps = {
  category: ServiceCategory;
  onBack: () => void;
};

const EditServiceCategory = ({ category, onBack }: EditServiceCategoryProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.editCategoryWrapper}>
      <IconButton aria-label="back" classes={{ root: classes.backBtn }} onClick={onBack}>
        <ChevronLeftIcon fontSize="large" />
      </IconButton>

      <Typography>Edit {category.name} Service</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <TextField
            id="outlined-read-only-input"
            label="Name of Treatment Item"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-read-only-input"
            label="Name of Treatment Item"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-read-only-input"
            label="Name of Treatment Item"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-read-only-input"
            label="Name of Treatment Item"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={7}>
          right
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditServiceCategory;

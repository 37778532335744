import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, InputLabel, FormControl, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const Outline = withStyles({
  root: {
    '& > * > *': {
      borderRadius: '15px', 
    },
    '& input': {
      fontFamily: 'Poppins', 
      fontSize: '1rem',
      borderRadius: '0'
    },
    '& textarea': {
      marginTop: '.4rem',
      marginBottom: '-.4rem',
      fontFamily: 'Poppins', 
      fontSize: '1rem',
      borderRadius: '0'
    }
  }

})(props => <TextField variant="outlined" {...props} />);

export default function OutlineTextField({ label = '', placeholder = null, marginTop = false, style = {}, ...props }) {

  const [focus, setFocus] = React.useState(false);
  
  React.useEffect(() => {
    const value = props?.value || props?.inputProps?.value;
    if (placeholder) setFocus(true);
    if (value || value === 0) setFocus(true);
  }, [placeholder, props]);

  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = (e) => {
    if (e.target.value) setFocus(true);
    else setFocus(false);
  };

  return  (
    <FormControl style={{ width: '100%', marginTop: marginTop && '1rem', ...style }} onFocus={onFocus} onBlur={onBlur}>
      <InputLabel style={{ display: focus && 'none', fontFamily: 'Poppins', fontSize: '14px', margin: '-.4rem 0 2rem 1rem' }}>{label}</InputLabel>
      <Outline {...props} />
    </FormControl>
  )
};

const SearchBar = withStyles({
  root: {
    '& > * > *': {
      borderRadius: '15px', 
    },
    '& input': {
      marginTop: '.4rem',
      marginBottom: '-.4rem',
      fontFamily: 'Poppins', 
      fontSize: '12px',
    },
  },

})(props => <TextField InputProps={{
  startAdornment: (
    <InputAdornment position="start">
      <SearchIcon style={{color: '#C4C4C4'}}/>
    </InputAdornment>
  ),
}} variant="outlined" {...props} />);

export function Search({ label='', ...props }) {
  const [focus, setFocus] = React.useState(false);
  const onFocus = (e) => {
    if (!e.target.value) setFocus(!focus);
  };
  const onBlur = (e) => {
    setFocus(!focus);
    if (e.target.value) setFocus(true);
  };

  return (
    <FormControl style={{width: '100%'}} onFocus={onFocus} onBlur={onBlur}>
      <InputLabel shrink style={{ display: !focus && 'none', fontFamily: 'Poppins', fontSize: '14px', margin: '.4rem 0 2rem 2.85rem'}}>{label}</InputLabel>
      <InputLabel style={{ display: focus && 'none', fontFamily: 'Poppins', fontSize: '14px', margin: '-.4rem 0 2rem 2.9rem' }}>{label}</InputLabel>
      <SearchBar {...props}/>
    </FormControl>
  )
}


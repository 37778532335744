import React, { useEffect, useState } from "react";
import "./index.css";

import Layout from "../../components/Layout";
import useDraft from "../../utils/useDraft";
import apiClient from "../../utils/apiClient";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import PriceField from "../../components/PriceField";
import { PrimaryButton } from "../../components/Button";
import TextField from "../../components/TextField";
import { ServiceMultiSelector } from "../../components/ServiceMultiSelector";

export default function CreatePromocode() {
  const [
    promocode,
    setPromocode,
    residualPromocode,
    updatePromocode,
    handlePromocodeInput,
  ] = useDraft({});

  const [serviceItems, setServiceItems] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      setServiceItems(await apiClient.get("/svc-item"));
    })();
  }, []);

  const history = useHistory();

  const save = () => {
    (async () => {
      const _promocode = {
        ...promocode,
        type: "PER_USER",
      };
      await apiClient.post("/promo", _promocode);
      toast.success("Created promocode");
      history.push("/promocodes");
    })();
  };

  return (
    <Layout>
      <h4>Promocodes</h4>
      <Breadcrumbs aria-label="breadcrumb" className="mt-4 mb-2">
        <Link color="inherit" href="#/promocodes">
          Promocodes
        </Link>
        <Typography color="textPrimary">Create Promocode</Typography>
      </Breadcrumbs>
      <div
        className="upk-card shadow mt-2 bg-card-secondarypb-3"
        style={{ backgroundColor: "white" }}
      >
        <div className="header">Create Promocode</div>
        <div className="body">
          <ServiceMultiSelector
            serviceItems={serviceItems}
            activeServiceItems={residualPromocode("allowedServices") || []}
            handleChange={handlePromocodeInput("allowedServices")}
          />

          <TextField
            marginTop
            label="Code"
            fullWidth={true}
            onChange={handlePromocodeInput(`code`)}
            value={residualPromocode("code")}
          />

          <TextField
            marginTop
            label="Absolure Quantity"
            fullWidth={true}
            onChange={handlePromocodeInput(`quantityAbsolute`)}
            value={residualPromocode("quantityAbsolute")}
          />

          <TextField
            marginTop
            label="Per User Quantity"
            fullWidth={true}
            onChange={handlePromocodeInput(`quantityPerUser`)}
            value={residualPromocode("quantityPerUser")}
          />

          <PriceField
            marginTop
            label="Amount In Dollars"
            fullWidth={true}
            onChange={handlePromocodeInput(`amountInCents`)}
            value={residualPromocode("amountInCents")}
          />

          <PrimaryButton
            label="Save"
            style={{ margin: "2rem 1rem 0 0" }}
            onClick={save}
          />
        </div>
      </div>
    </Layout>
  );
}

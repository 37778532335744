import React, { useEffect, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import Layout from '../../components/Layout';
import apiClient from '../../utils/apiClient';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PriceField from '../../components/PriceField';
import { PrimaryButton } from '../../components/Button';
import TextField from '../../components/TextField';

function CreateCreditsView() {

    const [credit, setCredit] = useState(null);

    const history = useHistory();

    const handleUpdate = (attr) => (e) => setCredit({
        ...credit,
        [attr]: e.target.value
    });

    const getCreditAttr = (attr) => _.get(credit, attr, '');
    const goBack = () => history.push('/credits')

    const saveCredit = async() => {
        try {
            await apiClient.post(`/credits`,credit);
            toast.success('Created credit.')
        } catch(err){
            toast.error('There was an error creating the credit.');
            console.error(err);
        }
     };

    return (
        <Layout>
            <h4>Credits</h4>
            <Breadcrumbs aria-label="breadcrumb" className="mt-4 mb-2">
                <Link color="inherit" href="#/credits" >
                    Credits
                </Link>
                <Typography color="textPrimary">Create Credit</Typography>
            </Breadcrumbs>
            <div className="upk-form mt-2 mb-3" style={{ backgroundColor: "white" }}>
                <div className="header">
                    Create New Credit
                </div>
                <div className="body">

                    <TextField
                        marginTop
                        label="Title"
                        fullWidth={true}
                        onChange={handleUpdate('title')}
                        value={getCreditAttr('title')} />

                        

                    <PriceField
                        marginTop
                        label="Total Amount Available (in dollars)"
                        fullWidth={true}
                        onChange={handleUpdate('totalInCents')}
                        value={getCreditAttr('totalInCents')} />

                    <TextField
                        style={{ margin: '1rem 0 2rem 0'}}
                        label="Description"
                        fullWidth={true}
                        multiline
                        rows={1}
                        rowsMax={3}
                        onChange={handleUpdate(`description`)}
                        value={getCreditAttr('description')} />

                    <PrimaryButton label='Create' onClick={saveCredit}/>

                </div>
            </div>
        </Layout>
    )
}

export default CreateCreditsView;

import React, { useEffect, useState } from "react"
import { InputAdornment } from "@material-ui/core"
import TextField from './TextField';

export default ({
    className = 'mt-1', 
    label = '', 
    fullWidth = true, 
    onChange = (e) => {},
    value = 0,
    ...props
}) => {

    const [price, setPrice] = useState(0);

    const transformToDollars = (p) => {
        
        if(typeof p !== 'number'){
            p = Number(p);
        }
        if(isNaN(p)){
            p = 0;
        }
        return p/100;
    }

    const handleChange = (e) => {
        if(typeof onChange === 'function'){
            console.log('changed price',{
                target: {
                    value: Number(e.target.value)*100
                }
            })
            onChange({
                target: {
                    value: Number(e.target.value)*100
                }
            });
        }
    }

    useEffect(()=>{
        setPrice(transformToDollars(value));
    },[value])

    return (
        <TextField 
            label={label} 
            fullWidth={true} 
            onChange={handleChange} 
            value={price}
            InputProps={{
                startAdornment: <InputAdornment position="start" style={{ paddingTop: '.8rem'}}>$</InputAdornment>,
              }}
            {...props} 
        />
    )
}



import React, { useState } from 'react';
import { toast } from 'react-toastify';
import apiClient from '../../utils/apiClient';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import './index.css';
import { AppointmentStatus } from './ProviderOverview';
import { TextField } from '@material-ui/core';
import moment from 'moment-timezone';

interface CompletedAppointmentProps {
  appointment: any;
  loadAppointments: () => void;
  className?: string;
  showLabel?: boolean;
}

export default function CompletedAppointment({
  appointment,
  loadAppointments,
  className = '',
  showLabel=true,
}: CompletedAppointmentProps): JSX.Element {
  const [value, setValue] = useState<string>('');
  const tz = appointment?.location?.timezone || 'America/Los_Angeles'
  const startTime = moment.tz(appointment.startDate,'UTC').tz(tz);
  const now = moment.tz(tz);
  const isLessThan24Diff = moment.duration(startTime.diff(now)).asHours() < 24;

  const updateAppointment = async (status: string) => {
    try {
      await apiClient.put(`/appointments/${appointment.id}`, {
        ...appointment,
        status: status
      });
      loadAppointments();
      toast.success('Appointment updated');
    } catch (err) {
      toast.error('Error updating appointment');
    }
  }

  const cancelAppointment = () => {
    toast.warn('Please contact support to cancel this appointment');

    // (maz): Tiffany doesn't want providers to be able to cancel anymore
    // try {
    //   await apiClient.delete(`/appointments/${appointment.groupId}`);
    //   loadAppointments();
    //   toast.success('Appointment canceled');
    // } catch (err) {
    //   toast.error('Error cancelling appointment');
    // }
  }

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    if (selectedStatus === AppointmentStatus.CANCELLED || selectedStatus === AppointmentStatus.LATE_CANCELLED) {
      cancelAppointment();
    } else {
      selectedStatus !== AppointmentStatus.ACTIVE && updateAppointment(selectedStatus);
    }

    setValue(selectedStatus as string);
  };

  return (
    <Box sx={{ minWidth: 100 }}>
      <FormControl fullWidth >
        <TextField
          variant="outlined"
          select
          key={appointment.id}
          id={appointment.id}
          value={value || appointment.status}
          onChange={handleChange}
          InputLabelProps={{shrink: false}}
          defaultValue={AppointmentStatus.ACTIVE}
          disabled={appointment.status === AppointmentStatus.CANCELLED || appointment.status === AppointmentStatus.LATE_CANCELLED}
        >
          <MenuItem value={'COMPLETED'}>COMPLETED</MenuItem>
          <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
          <MenuItem value={'CANCELLED'} disabled={isLessThan24Diff}>CANCELLED</MenuItem>
          <MenuItem value={'LATE_CANCELLED'} disabled={true}>LATE_CANCELLED</MenuItem>
          <MenuItem value={'FILMING'}>FILMING</MenuItem>
        </TextField>
      </FormControl>
    </Box>
  );
}

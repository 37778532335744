import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { Popover, ListItem, ListItemText, ListItemIcon, Box } from '@material-ui/core';
import Typography from '../Typography';
import useStyles from './SidebarItem.styles';

interface SidebarItemProps {
  text: string;
  open: boolean;
  href?: string;
  icon: ReactElement;
  isSelected?: boolean;
  clickHandler?: () => void;
}

export default function SidebarItem({
  text,
  open,
  href,
  icon,
  isSelected,
  clickHandler,
}: SidebarItemProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    if (!open) {
      setAnchorEl(null);
    }
  };

  const openPopover = Boolean(anchorEl);

  const handleItemClick = () => {
    if (href) {
      history.push(href);
    }
    if (clickHandler) {
      clickHandler();
    }
  };

  return (
    <div className={open ? classes.listItemWrapperOpen : ''}>
      <ListItem
        button
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-owns={open ? `mouse-over-popover-${text.replace(' ', '-').toLowerCase()}` : undefined}
        aria-haspopup="true"
        className={classNames([
          open ? classes.listItem : classes.listItemClosed,
          isSelected ? classes.selectedTab : null,
        ])}
        onClick={handleItemClick}>
        {open ? (
          <div className={classes.tab}>
            <ListItemIcon className={classes.icon} style={{ marginLeft: text === 'Log Out' ? '8px' : '' }}>
              {icon}
            </ListItemIcon>
            <ListItemText
              className={isSelected ? classes.selectedTabText : classes.tabText}
              primary={text}
              style={{ marginLeft: text === 'Log Out' ? '-8px' : '' }}
            />
          </div>
        ) : (
          <div className={classes.icon} style={{ marginLeft: text === 'Log Out' ? '15px' : '' }}>
            {icon}
          </div>
        )}
      </ListItem>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverPaper,
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Box className={classes.popoverContent}>
          <Typography>{text}</Typography>
        </Box>
      </Popover>
    </div>
  );
}

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import AppState from './../';
import { UserActionTypes } from './types';

export const login = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: UserActionTypes.LOGIN,
  });
};

export const logout = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: UserActionTypes.LOGOUT,
  });
};

export const setAdmin = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: UserActionTypes.SET_ADMIN,
  });
};

export const setViewAsProvider = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: UserActionTypes.SET_VIEW_AS_PROVIDER,
  });
};

export const setViewAsAdmin = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: UserActionTypes.SET_VIEW_AS_ADMIN,
  });
};

import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { StateTransitionListener } from './StateTransitionListener';
import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import OnboardingStepper from '../../components/ProviderOnboarding/Stepper';
import appointmentImage from '../../assets/appointment.png';
import settingsOnboardingImage from '../../assets/blvd-onboarding-settings.png'
import installOnboardingImage from '../../assets/blvd-onboarding-install.png'
import TextInput from '../../components/ProviderOnboarding/TextInput';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Box from '@mui/material/Box';

import { useForm } from 'react-hook-form';
import apiClient from '../../utils/apiClient';
import { StrategyTypes } from '../../enums/Strategies';

const APP_ID = process.env.REACT_APP_BOULEVARD_APP_ID

type Inputs = {
    businessId: string,
    timezone: string
}

interface BoulevardConnectPropsI {
    getInitialValue: (input: keyof Inputs) => string
    onValueUpdate: (input: keyof Inputs) => (val?: any) => void
    handleNext: () => void
    handlePrevious:  () => void
}

function BoulevardConnect(props: BoulevardConnectPropsI) {
    const {
        getInitialValue,
        onValueUpdate,
        handleNext,
        handlePrevious,
    } = props;

    const DEFAULT_CONNECTED_STATE = {
        isConnected: false,
        message: 'Validating Connection...'
    }

    const [stepperCount, setStepperCount] = useState(0)
    const [connectedState, setConnectedState] = useState(DEFAULT_CONNECTED_STATE)

    const { register, handleSubmit, setError, formState: { errors, ...formStateResidual }, getValues } = useForm<Inputs>({
        defaultValues: {
            businessId: getInitialValue('businessId'),
        }
    });

    const handleNextStep = () => {
        const nextStepCount = stepperCount + 1;
        if (nextStepCount < 3) {
            setStepperCount(nextStepCount)
        }
    }

    const handlePreviousStep = () => {
        const nextStepCount = stepperCount - 1;
        if (nextStepCount >= 0) {
            setStepperCount(nextStepCount)
        }
        else {
            handlePrevious()
        }
    }

    let currentStepComponent = null;

    useEffect(()=>{
        let timer = null;
        if(stepperCount === 2){
            setConnectedState(DEFAULT_CONNECTED_STATE)
            const testConnection = async()=>{
                const businessId = getInitialValue('businessId');
                try {
                    const strategyProviders = await apiClient.post('/strategies/fetch/providers',{
                        strategy: StrategyTypes.BOULEVARD,
                        businessId
                    })
                    if(strategyProviders.length){
                        const updateTimezone = onValueUpdate('timezone');
                        updateTimezone({
                            target: {
                                value: strategyProviders[0].timezone
                            }
                        });
                        setConnectedState({
                            isConnected: true,
                            message: "Connected."
                        })
                        setTimeout(handleNext,1000) 
                    }
                    else{
                        setConnectedState({
                            ...connectedState,
                            message: "Connected. But no valid locations found, please ensure all Boulevard locations have a valid address."
                        })
                    }
                    
                } catch(err){
                    console.error(err);
                    setConnectedState({
                        ...connectedState,
                        message: "Unable to connect, provided business ID might be incorrect or app not yet installed, retrying..."
                    })
                }
            }
            testConnection();
            timer = setInterval(testConnection,5000)
        }
        return () => timer && clearInterval(timer)
    },[stepperCount])

    switch (stepperCount) {
        case 0:
            currentStepComponent = (
                <>
                    <h1>Connect your <em>Boulevard</em> Account</h1>
                    <p>By granting access, we’ll use your Boulevard account to list you on our Upkeep system. Fill out the following information to get started. </p>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit(handleNextStep)}>
                        <div className="input-fields">
                            <TextInput
                                id="businessId"
                                name="businessId"
                                type="text"
                                placeholder="Boulevard Business ID"
                                ref={register({
                                    required: 'Business ID required',
                                })}
                                onChange={onValueUpdate('businessId')}
                                error={!!errors.businessId}
                                helperText={errors.businessId && errors.businessId.message}
                            />
                        </div>
                        <div className="actions">
                            <Button
                                type="button"
                                color={ColorEnum.transparent}
                                textColor="#4E4B66"
                                // @ts-ignore
                                onClick={handlePreviousStep}
                            >
                                Back
                            </Button>
                            <Button
                                type="button"
                                class="next"
                                color={ColorEnum.secondary}
                                textColor="white"
                                active={_.isEmpty(errors)}
                                onClick={handleNextStep}
                            >
                                Next
                            </Button>
                        </div>
                    </form>
                </>
            )
            break;
            case 1:
                currentStepComponent = (
                    <>
                    <h1>Connect your <em>Boulevard</em> Account</h1>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit(handleNextStep)}>
                        <div className="input-fields" style={{ height: 'auto'}}>
                            <p>Install our application on your Boulevard Dashboard by Navigating to "Settings" (top right icon), and then "Apps & Integrations".</p>
                            <img src={settingsOnboardingImage}/>
                            <p>At the bottom of the page in the "Custom Apps" click "Install"<br/>
                            Enter our Application ID and click "Install"</p>
                            <p><mark>{APP_ID}</mark></p>

                        </div>
                        <div className="actions">
                            <Button
                                type="button"
                                color={ColorEnum.transparent}
                                textColor="#4E4B66"
                                // @ts-ignore
                                onClick={handlePreviousStep}
                            >
                                Back
                            </Button>
                            <Button
                                type="button"
                                class="next"
                                color={ColorEnum.secondary}
                                textColor="white"
                                onClick={handleNextStep}
                            >
                                Next
                            </Button>
                        </div>
                    </form>
                    </>
                )
            break;
            case 2:
                currentStepComponent = (
                    <>
                    <h1>Connect your <em>Boulevard</em> Account</h1>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit(handleNextStep)}>
                        <div className="input-fields">
                            <p>Please wait while we verify the connection to Boulevard</p>
                            <p>{connectedState.message}</p>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', fontSize: '60px'}}>
                                { !connectedState.isConnected ? <CircularProgress/> : <CheckCircleIcon fontSize='inherit' color='success'/> }
                            </Box>
                            

                        </div>
                        <div className="actions">
                            <Button
                                type="button"
                                color={ColorEnum.transparent}
                                textColor="#4E4B66"
                                // @ts-ignore
                                onClick={handlePreviousStep}
                            >
                                Back
                            </Button>
                        </div>
                    </form>
                    </>
                )
            break;
    }

    return (
        <Layout>
            <StateTransitionListener />
            <SidePanel>
                <SidePanelImage imgSrc={appointmentImage} caption={'“Let\'s import some information about your business.”'} />
            </SidePanel>
            <OnboardingStepper totalSteps={3} activeStep={stepperCount} checkedSteps={stepperCount + 1} />
            <div className="content-container">
                <div className="content">
                    {currentStepComponent}
                </div>
            </div>
        </Layout>
    )
}

export default BoulevardConnect;

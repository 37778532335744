import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'none',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: '#4A4A49',
        padding: '.5rem 1rem',
    },
})(MuiAccordionSummary);

export const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        borderBottom: '1px solid #EFF0F6',
        marginTop: '1px',
        fontFamily: 'Poppins',
        "&.MuiAccordion-root:before": {
            backgroundColor: 'white',
        }
    }
})(MuiAccordion);

import React, { useState, useEffect } from 'react';
import { Chart, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import chartPointer from '../../assets/chart-pointer.svg';
import { StrategyTypes } from '../../enums/Strategies';
import { Steps } from './types';

import './index.css';

interface ProgressChartProps {
  steps: Steps;
  strategy: string;
}

interface StepsCount {
  completed: number;
  total: number;
}

Chart.register(ArcElement);

const options = {
  circumference: 250,
  rotation: -125,
  borderRadius: 30,
  cutout: 50,
  responsive: true,
  maintainAspectRatio: true,
  radius: 60,
  borderWidth: 0,
};

interface Dataset {
  data: number[];
  backgroundColor: string[];
}

export default function ProgressChart({ steps, strategy }: ProgressChartProps): JSX.Element {
  const [stepsCount, setStepsCount] = useState<StepsCount>({ completed: 0, total: 0 });
  const [graphData, setGraphData] = useState<{ datasets: Dataset[] }>({
    datasets: [
      {
        data: [],
        backgroundColor: ['#00AF9B', 'transparent'],
      },
    ],
  });

  useEffect(() => {
    let completed = 0;
    for (let key in steps) {
      if (steps[key]) {
        completed += 1;
      }
    }
    if (strategy !== StrategyTypes.MANUAL) {
      completed += 1;
    }
    const total = Object.keys(steps).length + (strategy !== StrategyTypes.MANUAL ? 1 : 0);
    const updatedData = { ...graphData };
    updatedData.datasets[0].data = [completed, total - completed];
    setGraphData(updatedData);
    setStepsCount({ completed, total });
  }, []);

  return (
    <div className="my-2 progress-chart">
      <div className="background-bar">
        <Doughnut data={{ datasets: [{ data: [100], backgroundColor: ['#D9DBE9'] }] }} options={options} />
        <div className="progress-bar">
          {graphData.datasets[0].data.length && <Doughnut data={graphData} options={options} />}
        </div>
        <div className="chart-pointer-container">
          <div
            className="chart-pointer-box"
            style={{ transform: `rotate(${(stepsCount.completed / stepsCount.total) * 180}deg)` }}>
            <img className="chart-pointer" src={chartPointer} alt="pointer" />
          </div>
        </div>
      </div>
    </div>
  );
}

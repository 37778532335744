import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import apiClient from "../utils/apiClient";
import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { toast } from "react-toastify";

interface PropsType {
    providerId: number;
    locationId: number;
}

const GreenCheckbox = withStyles({
    root: {
        color: '#00AF9B',
        '&$checked': {
            color: '#00AF9B',
        },
        checked: {},
    }
})((props: any) => <Checkbox color="default" {...props} />);

const GreenTabs = withStyles({
    indicator: {
        backgroundColor: '#00AF9B',
    },
})(Tabs);

export class ServicesMenu extends Component<PropsType, any> {

    constructor(props: PropsType) {
        super(props)

        console.log("[ServicesMenu] loading ServicesMenu: ", props)

        this.state = {
            locationId: props.locationId,
            providerId: props.providerId,
            selectedTab: 0,
            isLoading: true,
        }
    }

    async componentDidMount() {
        let [
            providerServiceItems,
            serviceCategories,
            bundles
        ] = await Promise.all([
            apiClient.get(`/providers/svc-items`, { location: this.props.locationId }),
            apiClient.get('/svc-category', { locationId: this.props.locationId }),
            apiClient.get('/bundle')
        ])

        
        // Assign bundles to serviceCategories, and add bundleId key to each bundle (id -> bundleId)
        let serviceItemIdToCategoryId = (()=>{
            let map = {};
            serviceCategories.forEach(svcCategory => {
                svcCategory.serviceItems.forEach(svcItem => {
                    map[svcItem.id] = svcCategory.id
                })
            });
            return map;
        })();
        bundles = bundles.map(bundle => {
            let serviceCategoryId = bundle.serviceItems.length && serviceItemIdToCategoryId[bundle.serviceItems[0].id];
            return {
                ...bundle,
                bundleId: bundle.id,
                serviceCategoryId
            }
        })
        serviceCategories = serviceCategories.map(svcCategory => {
            return {
                ...svcCategory,
                bundles: bundles.filter(bundle=>bundle.serviceCategoryId === svcCategory.id)
            }
        })

        console.log("[ServicesMenu] componentDidMount", {
            providerServiceItems,
            serviceCategories,
            bundles
        })
        
        this.setState({
            providerServiceItems,
            serviceCategories,
            isLoading: false,
            isDisabled: false
        })
    }

    selectTab(n: number) { this.setState({ selectedTab: n }) }

    isBundle(serviceItemOrBundle): boolean {
        return  typeof serviceItemOrBundle.bundleId === 'number';
    }
    // returns the corresponding provider's service item, if it exists
    providerServiceItem(serviceItemOrBundle): any {
        return this.state.providerServiceItems.find(psi => {
            return this.isBundle(serviceItemOrBundle) ? psi.bundleId == serviceItemOrBundle.bundleId : psi.serviceItemId == serviceItemOrBundle.id
        });
    }

    async handleServiceItemChecked(event, checked, item) {
        this.setState({ isDisabled: true })

        const providerServiceItems = this.state.providerServiceItems

        if (checked) {
            // CREATE PROVIDER SERVICE ITEM
            try {

                const isBundle = this.isBundle(item)
                const res = await apiClient.post(`/providers/svc-items`, {
                    externalId: item.externalId,
                    locationId: this.props.locationId,
                    serviceItemId: !isBundle ? item.id : null,
                    bundleId: isBundle ? item.bundleId : null,
                })
                res.message && toast.success(res.message);
                toast.success(`Created Provider Service Item "${item.name}"`);

                providerServiceItems.push(res)
                this.setState({ providerServiceItems })
            } catch (err) {
                toast.error(err.message);
                console.error(err);
            }

        } else {
            // DELETE PROVIDER SERVICE ITEM
            const providerSvcItem = this.providerServiceItem(item)
            if (!providerSvcItem) {
                console.error(`could not find provider service item for ${item.name}`)
                toast.error(`could not find provider service item for ${item.name}`)
            } else {
                try {
                    const res = await apiClient.delete(`/providers/svc-items/${providerSvcItem.id}`)
                    if (res.message) {
                        toast.success(res.message || `Successfully unlinked service item`);

                        // remove the matching PSI from the local array
                        const filteredProviderServiceItems = providerServiceItems.filter(psi => psi.id !== Number(res.id))
                        this.setState({ providerServiceItems: filteredProviderServiceItems })

                    }
                } catch (err) {
                    toast.error(err.message);
                    console.error(err);
                }
            }
        }

        this.setState({ isDisabled: false })
    }


    render() {
        return this.state.isLoading ?
            (
                <Grid container
                    style={{ borderRadius: '10px', backgroundColor: 'rgba(248, 249, 255, 0.45)', padding: "1em 1em" }}>
                    <CircularProgress />
                </Grid >
            ) : (
                <Grid container
                    style={{ borderRadius: '10px', backgroundColor: 'rgba(248, 249, 255, 0.45)', padding: "0" }}
                >

                    {/* START tab menu */}
                    <Grid container>
                        <GreenTabs
                            value={this.state.selectedTab}
                            variant="scrollable"
                            indicatorColor="primary"
                        >
                            {this.state.serviceCategories.map((i, x) =>
                                <Tab key={i.id} label={i.name} onClick={(e) => this.selectTab(x)} />
                            )}
                        </GreenTabs>
                    </Grid>
                    {/* END tab menu */}

                    <Grid container
                        style={{ borderRadius: '0 0 10px 10px', backgroundColor: 'rgba(50, 50, 93, 0.05)', padding: "2em"}}
                    >
                        {/* START selected tab */}
                        {this.state.serviceCategories.map((category, x) =>
                            x === this.state.selectedTab && (
                                [...category.serviceItems, ...category.bundles].map((serviceItemOrBundle, y) =>
                                    <Grid item key={y} xs={12}>
                                        <FormControlLabel
                                            control={<GreenCheckbox />}
                                            label={serviceItemOrBundle.name}
                                            onChange={(event, checked) => this.handleServiceItemChecked(event, checked, serviceItemOrBundle)}
                                            checked={!!this.providerServiceItem(serviceItemOrBundle)}
                                            disabled={this.state.isDisabled}
                                        />
                                    </Grid>
                                )
                            )
                        )}
                    </Grid>
                    {/* END selected tab */}

                </Grid>
            )
    }
}

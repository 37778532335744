import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const colors = {
  grey: {
    '50': '#F8F9FF',
    '200': '#EFF0F6',
    '300': '#D9DBE9',
    '400': '#A0A3BD',
    '500': '#6E7191',
    '600': '#4E4B66',
  },
  fuschia: {
    '100': '#EF5DA8',
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00AF9B',
    },
    secondary: {
      main: colors.fuschia['100'],
    },
  },
});

export { ThemeProvider, theme, colors };

import React from 'react';
import { ManualServiceItemSelect } from '../ManualServiceItemSelect';

interface CategoryTimingProps {
  provider: any;
}

export default function CategoryTiming({ provider }: CategoryTimingProps): JSX.Element {
  
  return (
    <>
    <h4 className="heading-small text-muted mb-1" style={{ marginLeft: 'auto' }}>
      {' '}
      Manage Category Timing
    </h4>
    <ManualServiceItemSelect
      providerId={provider.id}
      locationId={provider.location.id}
      provider={provider}
    />
  </>
  );
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import FormCheckbox from '../../components/FormCheckbox';

import './index.css';
import ProgressChart from './ProgressChart';
import { Steps } from './types';

interface CheckboxItemProps {
  completed?: boolean;
  text: string;
  action?: string;
  clickHandler?: { (): void };
}

interface ChecklistProps {
  strategy: string;
  steps: Steps;
}

function CheckboxItem({ completed = true, text, action, clickHandler }: CheckboxItemProps): JSX.Element {
  return (
    <FormCheckbox
      className="my-2"
      fixed={true}
      checked={completed}
      type={completed ? 'primary' : 'warning'}
      label={
        completed ? (
          <div className="label">{text}</div>
        ) : (
          <div className="label">
            <div>{text}</div>
            <div className="required">
              Required
              <span className="action">
                {' '}
                - <a onClick={clickHandler}>{action}</a>
              </span>
            </div>
          </div>
        )
      }
    />
  );
}
export default function Checklist({ strategy, steps }: ChecklistProps): JSX.Element {
  const history = useHistory();

  return (
    <div className="upk-card checklist">
      <div className="heading-medium">Checklist</div>
      <div className="progress-chart-container">
        <ProgressChart steps={steps} strategy={strategy} />
      </div>
      <CheckboxItem
        completed={steps.availability}
        text="Set up your Availability"
        action="Add now"
        clickHandler={() => history.push('/provider/profile')}
      />
      <CheckboxItem
        completed={steps.stripe}
        text="Connect to stripe"
        action="Connect"
        clickHandler={() => history.push('/provider/onboarding/stripe')}
      />
      {(strategy.toUpperCase() === 'MINDBODY' || strategy.toUpperCase() === 'BOOKER') && (
        <CheckboxItem text={`Verified ${strategy.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}`} />
      )}
      <CheckboxItem
        completed={steps.cover}
        text="Upload cover photos"
        action="Upload now"
        clickHandler={() => history.push('/provider/profile')}
      />
    </div>
  );
}

import React from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

import './calendar.css';
import { AppointmentStatus } from '../../views/Provider';

interface CalendarProps {
  appointments: any[];
  clickHandler?: { (event?: any): void };
  setShowCreateAppointmentModal?: Function;
}

export default function Calendar({ appointments, clickHandler, setShowCreateAppointmentModal }: CalendarProps): JSX.Element {
  return (
    <FullCalendar
      defaultView="dayGridMonth"
      timeZone="UTC-coercion"
      plugins={[dayGridPlugin, interactionPlugin]}
      eventClick={clickHandler}
      customButtons={{
        createAppointment: {
          text: 'Create Appointment',
          click: function () {
            setShowCreateAppointmentModal(true);
          }
        }
      }}
      header={{
        left: 'title',
        right: 'createAppointment,prev,next'
      }}
      events={appointments.map((appointment) => {
        return {
          title: `| ${appointment.serviceItemDisplayName ? appointment.serviceItemDisplayName + '| ' : ''}${
            appointment.user.email
          }`,
          date: appointment.startDate,
          data: {
            appointment,
          },
          className: appointment.status === AppointmentStatus.CANCELLED || appointment.status === AppointmentStatus.LATE_CANCELLED ? "fc-event-cancelled" : "fc-event-success"
        };
      })}
    />
  );
}

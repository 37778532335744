import * as firebase from 'firebase/app';
import 'firebase/auth';
import UserRoles from './enums/UserRoles';
import { actions } from './store';
import UpkError from './Error';

const ID_TOKEN_KEY = 'idToken';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_NESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebaseApp.auth();

const getIdToken = () => localStorage.getItem(ID_TOKEN_KEY);
const setIdToken = (idToken: string) => localStorage.setItem(ID_TOKEN_KEY, idToken);
const removeIdToken = () => localStorage.removeItem(ID_TOKEN_KEY);

const logout = async () => {
  removeIdToken();
  await firebaseAuth.signOut();
};

const login = async (email: string, password: string) => {
  const result = await firebaseAuth.signInWithEmailAndPassword(email, password);
  const { user } = result;
  if (user) {
    const { claims } = await user.getIdTokenResult();
    const { role } = claims;
    if (role === UserRoles.ADMIN || role === UserRoles.PROVIDER) {
      const idToken = await user.getIdToken();
      setIdToken(idToken);
      actions.login();
      if (role === UserRoles.ADMIN) {
        actions.setAdmin();
      }
    } else {
      await logout();
      throw new UpkError(`Unauthorized.`, 'auth/authorization-denied');
    }
  }
};

const resetPassword = async (email: string) => {
  try {
    await firebaseAuth.sendPasswordResetEmail(email);
    return {
      success: true,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

const getDecodedToken = () => {
  const token = getIdToken();
  const payloads: Array<string> = (token || '').split('.');
  if (!payloads.length || !payloads[1]) {
    return null;
  }
  const decodedToken = JSON.parse(Buffer.from(payloads[1], 'base64').toString());
  return decodedToken;
};

const getRole = () => {
  const decodedToken = getDecodedToken();
  return decodedToken?.role;
};

const isAuthenticated = (roles) => {
  const decodedToken = getDecodedToken();

  if (!decodedToken) {
    return false;
  }
  const expTimeInMs = decodedToken && decodedToken.exp * 1000;
  const currTimeInMs = new Date().getTime();
  const isExpired = expTimeInMs - currTimeInMs <= 0;
  return decodedToken.role && roles.includes(decodedToken.role) && !isExpired;
};

// -- Refresh token periodically
(function () {
  let sessionObserver;
  const REFRESH_TOKEN_PERIOD = 1000 * 60 * 5;

  const refreshTokenHandler = async function (initialLoad?: boolean) {
    const firebaseUser = firebaseAuth.currentUser;
    const decodedToken = getDecodedToken();
    const expTimeInMs = decodedToken && decodedToken.exp * 1000;
    const currTimeInMs = new Date().getTime();

    if (initialLoad && expTimeInMs - currTimeInMs <= REFRESH_TOKEN_PERIOD) {
      const newIdToken = await firebaseUser.getIdToken(true);
      setIdToken(newIdToken);
    } else if (!initialLoad) {
      const newIdToken = await firebaseUser.getIdToken(true);
      setIdToken(newIdToken);
    }
  };

  firebaseAuth.onAuthStateChanged(function (user) {
    if (user) {
      sessionObserver && clearInterval(sessionObserver);
      refreshTokenHandler(true);
      sessionObserver = setInterval(refreshTokenHandler, REFRESH_TOKEN_PERIOD);
    }
  });
})();

export default firebaseAuth;

export { login, resetPassword, logout, getIdToken, setIdToken, removeIdToken, isAuthenticated, ID_TOKEN_KEY, getRole };

import './index.css';
import React, { useEffect, useState } from 'react';
import loginPanelImage from './../../assets/mobileApp.png';
import { login } from '../../firebaseAuth';
import Snackbar from '../../components/Snackbar';
import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import TextInput from '../../components/ProviderOnboarding/TextInput';

import { useHistory, useParams } from 'react-router-dom';
import apiClient from '../../utils/apiClient';
import { getQueryString } from '../../utils';
import { useForm } from 'react-hook-form';

type Inputs = {
  email: string,
  password: string,
  confirmPassword: string,
}

function ProviderAccountCreation() {
  const email = getQueryString('email');
  const { register, handleSubmit, setError, formState: { errors }, getValues } = useForm<Inputs>({
    defaultValues: {
      email,
      password: '',
      confirmPassword: '',
    }
  });

  const history = useHistory();
  const { code } = useParams<{ code: string}>();

  const [snackbarMsg,setSnackbarMsg] = useState('');

  const [invite, setInvite] = useState(null);
  const [isExpired, setIsExpired] = useState(null);

  useEffect(() => {
    (async()=>{
        try {
            const invite = await apiClient.get(`/provider/invite/${code}`, { email });
            setInvite(invite);
            setIsExpired(false);
        } catch(err){
            setIsExpired(true);
        }
    })();
  },[]);

  const isLoading = typeof isExpired !== 'boolean';

  const handleAccountCreation = async (data: Inputs) => {
    const {
      email,
      password,
      confirmPassword,
    } = data;

    setSnackbarMsg('');

    if(password !== confirmPassword){
      return setSnackbarMsg('Passwords do not match, please ensure passwords match.');
    }

    try {
      const createdAccount = await apiClient.post(`/provider/invite/user`, {
          "email": email,
          "password": password,
          "code": code
      });

      await login(email,password);
      if (createdAccount.providers && createdAccount.providers.length) {
        history.push('/provider/overview');
      } else {
        history.push('/provider/onboarding');
      }

    } catch(err) {
      if (err?.response?.data?.code === "provider/invite/register/user-exists") {
        setError('email', {
          message: 'Looks like there\'s an existing account',
          shouldFocus: true,
        });
      }
      setSnackbarMsg('Could not create account successfully. Please try again by refreshing the page.');
      console.error(err);
    }

    return;
  }

  return (
    <Layout>
      <SidePanel>
        <SidePanelImage imgSrc={loginPanelImage} caption={'“Welcome, to the future of beauty booking.\n Let’s get you started!”'} />
      </SidePanel>

      <div className="content">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(handleAccountCreation)}>
          <br/>
          <br/>
          {
            isLoading && <div><br/><span>Validating invite...</span></div>
          }
          {
            !isLoading && (
              <>
                {
                  !isExpired && (
                    <>
                      <h1>Create Your Account</h1>
                      <p>Fill out the following information to get you started.</p>
                      <div className="input-fields">
                        <TextInput
                          id="email"
                          name="email"
                          type="text"
                          placeholder="Email"
                          ref={register({
                            required: 'Email required',
                          })}
                          error={!!errors.email}
                          helperText={errors.email && errors.email.message}
                        />
                        <TextInput
                          id="password"
                          name="password"
                          placeholder="Password (at least 8 characters)"
                          ref={register({
                            required: true,
                            minLength: 8,
                          })}
                          type="password"
                          error={!!errors.password}
                          helperText={errors.password && 'Password needs 8 characters'}
                        />
                        <TextInput
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password (at least 8 characters)"
                          ref={register({
                            required: true,
                            minLength: 8,
                            validate: {
                              passwordEqual: value => (value === getValues().password) || 'Password does not match',
                            }
                          })}
                          error={!!errors.confirmPassword}
                          helperText={errors.confirmPassword && (
                            errors.confirmPassword.message || 'Password needs 8 characters'
                          )}
                        />
                      </div>
                      <Button type="submit" color={ColorEnum.primary} fullWidth>
                          Create Account
                      </Button>
                      <div style={{marginTop: '1rem', textAlign: 'center', fontFamily: 'SF Pro Display Regular'}}>
                        Already have an account? <a href={'/login'} style={{color: '#348D84'}}>Sign In</a>
                      </div>
                    </>
                  )
                }
                {
                  isExpired && (
                    <div><br/><span>The invite code provided is either expired or invalid.</span></div>
                  )
                }
              </>
            )
          }
        </form>
        <Snackbar
          message={snackbarMsg}
          autoHideDuration={2000}
          open={!!snackbarMsg}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom'}}
          onClose={() => setSnackbarMsg('')}
        />
      </div>
    </Layout>
  );
}

export default ProviderAccountCreation;

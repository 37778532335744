export default interface OnboardingStateI {
    statePointer: number,
    selectedStrategy: string,
    credentials: any,
    location: any,
    provider: any,
    roomImportStatus: { 
        imported: boolean 
    },
    providerServiceItems: {
        created: boolean
    }
}

const initialOnboardingState: OnboardingStateI = {
   statePointer: 0,
   selectedStrategy: '',
   credentials: {},
   location: {},
   provider: {},
   roomImportStatus: {
       imported: false
   },
   providerServiceItems: {
       created: false
   }
}

export { initialOnboardingState };
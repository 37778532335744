import React, { useEffect, useState } from "react";
import "./index.css";

import Layout from "../../components/Layout";
import { CircularProgress, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useDraft from "../../utils/useDraft";
import apiClient from "../../utils/apiClient";
import _ from "lodash";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PriceField from "../../components/PriceField";
import { PrimaryButton, TextButton } from "../../components/Button";
import TextField from "../../components/TextField";
import { ServiceMultiSelector } from "../../components/ServiceMultiSelector";

function Promocodes() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [serviceItems, setServiceItems] = useState<any[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    (async () => {
      setServiceItems(await apiClient.get("/svc-item"));
    })();
  }, []);

  const [
    promocode,
    setPromocode,
    residualPromocode,
    updatePromocode,
    handlePromocodeInput,
  ] = useDraft({});

  const history = useHistory();

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      let promocodes = await apiClient.get("/promo");
      promocodes = promocodes.map((promoCode) => ({
        ...promoCode,
        allowedServices: promoCode.allowedServices.map(({ id }) => id),
      }));

      if (active) {
        setOptions(promocodes);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const save = () => {
    (async () => {
      try {
        await apiClient.put(`/promo/${promocode.id}`, promocode);
        toast.success("Saved promocode");
      } catch (err) {
        toast.error("Error saving promocode");
      }
    })();
  };
  const create = () => {
    history.push("/promocodes/create");
  };
  const deletePromo = () => {
    (async () => {
      try {
        await apiClient.delete(`/promo/${promocode.id}`, promocode);
        toast.success("Deleted promocode");
        setOpen(false);
        setOptions([]);
        setPromocode({});
      } catch (err) {
        toast.error("Error deleting promocode");
      }
    })();
  };

  return (
    <Layout>
      <h4>Promocodes</h4>
      <div className="upk-form mt-2 mb-3">
        <div className="header">
          <div className="title">Manage Promocodes</div>
          <div>
            <PrimaryButton
              label="Create"
              style={{ marginLeft: "auto" }}
              onClick={() => create()}
            />
          </div>
        </div>
        <div className="body">
          <>
            <Autocomplete
              fullWidth={true}
              className="mb-2"
              style={{ width: 300 }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(event: any, selectedPromocode: any) =>
                setPromocode(selectedPromocode || {})
              }
              getOptionSelected={(option, value) => option.code === value.code}
              getOptionLabel={(option) => option.code}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Promocodes"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {console.log(promocode)}
            {
              /* --------------- Promo ----------------- */
              _.has(promocode, "id") && (
                <div className="border">
                  <h4
                    className="heading-small text-muted"
                    style={{ marginLeft: "auto" }}
                  >
                    Promocode Details
                  </h4>
                  <ServiceMultiSelector
                    serviceItems={serviceItems}
                    activeServiceItems={residualPromocode("allowedServices")}
                    handleChange={handlePromocodeInput("allowedServices")}
                  />

                  <TextField
                    marginTop
                    label="Absolure Quantity"
                    fullWidth={true}
                    onChange={handlePromocodeInput(`quantityAbsolute`)}
                    value={residualPromocode("quantityAbsolute")}
                  />

                  <TextField
                    marginTop
                    label="Per User Quantity"
                    fullWidth={true}
                    onChange={handlePromocodeInput(`quantityPerUser`)}
                    value={residualPromocode("quantityPerUser")}
                  />

                  <PriceField
                    marginTop
                    label="Amount In Dollars"
                    fullWidth={true}
                    onChange={handlePromocodeInput(`amountInCents`)}
                    value={residualPromocode("amountInCents")}
                  />

                  <PrimaryButton
                    label="Save"
                    style={{ margin: "2rem 1rem 0 0" }}
                    onClick={save}
                  />
                  <TextButton
                    label="Delete"
                    style={{ marginTop: "2rem" }}
                    onClick={deletePromo}
                  />
                </div>
              )
              /* --------------- End Promo ----------------- */
            }
          </>
        </div>
      </div>
    </Layout>
  );
}

export default Promocodes;

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mapWrapper: {
    position: 'relative',
    '& .overlap': {
      position: 'absolute',
      background: 'rgba(0,0,0,0.3)',
      zIndex: 9999,
      width: '100%',
      height: '100%',
      '& p': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '20px',
        width: '100%',
        textAlign: 'center'
      }
    },
  },
  polygons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
  },
}));

export default useStyles;

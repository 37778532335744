import React from 'react';
import { FormControlLabel } from '@material-ui/core';
import moment from 'moment';

import TextField from '../TextField';
import Typography from '../Typography';
import Switch from '../Switch';

interface AvailabilityProps {
  residualLocation: (text: string) => boolean;
  updateLocation: (attr: string, value: boolean) => void;
  handleLocationInput: (text: string) => void;
}

export default function Availability({ residualLocation, updateLocation, handleLocationInput }: AvailabilityProps): JSX.Element {
  const handleSwitchInput = (attr: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    updateLocation(attr, value);
  };

  const DayScheduleInput = (dayIndex: number) => {
    const isOpen = residualLocation(`isOpenDay${dayIndex}`);
    const dayString = moment(dayIndex === 7 ? 0 : dayIndex, 'e').format('dddd');
    return (
      <div className="mt-2 mb-2">
        <hr />
        <h6 style={{ fontSize: '14px', fontWeight: 600 }}>{dayString} Schedule:</h6>
        <FormControlLabel
          control={
            <Switch
              value={isOpen}
              checked={isOpen || false}
              onChange={handleSwitchInput(`isOpenDay${dayIndex}`)}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          labelPlacement="start"
          label={<Typography>Is Open?</Typography>}
        />
        {isOpen && (
          <TextField
            id="svc-item-name"
            label={`${dayString} Open Hours`}
            fullWidth={true}
            onChange={handleLocationInput(`day${dayIndex}open`)}
            value={residualLocation(`day${dayIndex}open`)}
            disabled={!isOpen}
            placeholder="8:00am"
          />
        )}
        {isOpen && (
          <TextField
            id="svc-item-name"
            marginTop={true}
            label={`${dayString} Closing Hours`}
            fullWidth={true}
            onChange={handleLocationInput(`day${dayIndex}close`)}
            value={residualLocation(`day${dayIndex}close`)}
            disabled={!isOpen}
            placeholder="9:00pm"
          />
        )}
      </div>
    );
  };
  return (
    <div className="border mb-1">
      <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
        Hour Availability
      </h4>
      <div className="mt-2">
        {DayScheduleInput(1)}
        {DayScheduleInput(2)}
        {DayScheduleInput(3)}
        {DayScheduleInput(4)}
        {DayScheduleInput(5)}
        {DayScheduleInput(6)}
        {DayScheduleInput(7)}
      </div>
    </div>
  );
}

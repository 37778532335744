// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Grid, Box, Chip, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { MapContainer, TileLayer, useMap, FeatureGroup, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { ToastContainer, toast } from 'react-toastify';

import LocationSearchInput, { PlaceType } from '../LocationSearchInput';
import useStyles from './ServiceMapping.styles';
import apiClient from '../../utils/apiClient';

type ServiceMappingProps = {
  tierId: number;
};

type TPolygon = {
  id: number;
  name: string;
  points: any;
};

const purpleOptions = { color: 'purple' };

function ChangeMapView({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
}

const _getArrayDepth = (value: any): any => {
  return Array.isArray(value) ? 1 + Math.max(0, ...value.map(_getArrayDepth)) : 0;
};

const ServiceMapping = ({ tierId }: ServiceMappingProps) => {
  const [polygons, setPolygons] = useState<TPolygon[]>([]);
  const [mapCenter, setMapCenter] = useState({ lat: 37.0902, lng: -95.7129 });
  const [polygonIds, setPolygonIds] = useState([]);
  const [polygonNames, setPolygonNames] = useState([]);
  const [isCustomPolygonCreated, setIsCustomPolygonCreated] = useState(false);
  const [customPolygonPoints, setCustomPolygonPoints] = useState([]);
  const [customPolygonName, setCustomPolygonName] = useState('');
  const classes = useStyles();

  const fetchTier = async () => {
    try {
      const res = await apiClient.get(`/price-tier/${tierId}`);

      setPolygons(res.polygons);
      const idsArr = res.polygons.map((polygon) => polygon.id);
      setPolygonIds(idsArr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTier();
  }, [tierId]);

  useEffect(() => {
    let uniqueNames = [];
    polygons.map((polygon) => {
      if (uniqueNames.findIndex((x) => x === polygon.name) === -1) {
        uniqueNames = [...uniqueNames, polygon.name];
      }
    });

    setPolygonNames(uniqueNames);
  }, [polygons]);

  const onSelectPlace = async (place: PlaceType) => {
    if (place && place.description !== '') {
      try {
        const res = await axios.get(
          `https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(
            place.description,
          )}&polygon_geojson=1&format=json&polygon_threshold=0.5`,
        );

        if (res.data.length > 0) {
          const administrative = res.data.find((item) => item.type === 'administrative');
          const polygonCoordinates = administrative.geojson.coordinates;
          setMapCenter({ lat: parseFloat(administrative.lat), lng: parseFloat(administrative.lon) });
          let newPolygonCoordinates = [];
          polygonCoordinates.map((coordinate, index) => {
            newPolygonCoordinates = [...newPolygonCoordinates, []];
            if (administrative.geojson.type === 'Polygon') {
              coordinate.map((latlng) => {
                newPolygonCoordinates[index] = [...newPolygonCoordinates[index], [latlng[1], latlng[0]]];
              });
            } else if (administrative.geojson.type === 'MultiPolygon') {
              coordinate.map((polygon, j) => {
                newPolygonCoordinates[index] = [...newPolygonCoordinates[index], []];
                polygon.map((latlng) => {
                  newPolygonCoordinates[index][j] = [...newPolygonCoordinates[index][j], [latlng[1], latlng[0]]];
                });
              });
            }
          });
          if (administrative.geojson.type === 'MultiPolygon') {
            newPolygonCoordinates = newPolygonCoordinates.flat().map((a)=> _getArrayDepth(a)<3 ? [a] : a);
          }
          let newPolygonIds = [...polygonIds];

          if (newPolygonCoordinates.length > 1) {
            await Promise.all(
              newPolygonCoordinates.map(async (polygon) => {
                const res = await apiClient.post('/polygon', {
                  name: place.description,
                  points: polygon,
                });
                newPolygonIds = [...newPolygonIds, res.id];
              }),
            );
          } else {
            const res = await apiClient.post('/polygon', {
              name: place.description,
              points: newPolygonCoordinates[0],
            });
            newPolygonIds = [...newPolygonIds, res.id];
          }

          await apiClient.put(`/price-tier/${tierId}`, {
            polygonIds: newPolygonIds,
          });

          fetchTier();
        } else {
          toast.error('Location can not be found', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const onDeleteCounty = async (polygonName: string) => {
    try {
      await apiClient.delete(`/polygon?name=${polygonName}&priceTierId=${tierId}`);
      fetchTier();
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onClickCounty = (polygonName: string) => {
    const arr = [...polygons];
    const clickedPolygon = arr.find((x) => x.name === polygonName);

    if (clickedPolygon) {
      if (typeof clickedPolygon.points[0][0] === 'string') {
        setMapCenter({ lat: clickedPolygon.points[0][0], lng: clickedPolygon.points[0][1] });
      } else {
        setMapCenter({ lat: clickedPolygon.points[0][0][0], lng: clickedPolygon.points[0][0][1] });
      }
    }
  };

  const onCreatedCustomPolygon = async (e) => {
    setIsCustomPolygonCreated(true);

    let arrPolygonPoints = [];
    e.layer._latlngs[0].map((point) => {
      arrPolygonPoints = [...arrPolygonPoints, [point.lat, point.lng]];
    });
    setCustomPolygonPoints(arrPolygonPoints);
  };

  const onSaveCustomPolygon = async () => {
    let newPolygonIds = [...polygonIds];

    try {
      const res = await apiClient.post('/polygon', {
        name: customPolygonName,
        points: [...customPolygonPoints, customPolygonPoints[0]],
      });
      newPolygonIds = [...newPolygonIds, res.id];

      await apiClient.put(`/price-tier/${tierId}`, {
        polygonIds: newPolygonIds,
      });

      setIsCustomPolygonCreated(false);
      setCustomPolygonName('');
      fetchTier();
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <LocationSearchInput onSelectPlace={onSelectPlace} />
          {isCustomPolygonCreated && (
            <Box mt={1}>
              <TextField
                label="Polygon name"
                variant="outlined"
                fullWidth
                style={{ marginBottom: 8 }}
                focused={isCustomPolygonCreated}
                required
                value={customPolygonName}
                onChange={(e) => setCustomPolygonName(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveCustomPolygon}
                disabled={customPolygonName === ''}>
                Save
              </Button>
            </Box>
          )}

          <Box className={classes.polygons}>
            {polygonNames.map((name, index) => (
              <Chip
                label={name}
                clickable
                onDelete={() => onDeleteCounty(name)}
                onClick={() => onClickCounty(name)}
                key={index}
              />
            ))}
          </Box>
        </Grid>
        <Grid item sm={9}>
          <Box style={{ height: '400px', width: '100%' }} className={`${classes.mapWrapper}`}>
            {isCustomPolygonCreated && (
              <div className="overlap">
                <p className="disable-text">Please set the custom polygon name and save!</p>
              </div>
            )}

            <MapContainer style={{ height: '100%', width: '100%' }} center={mapCenter} zoom={3} scrollWheelZoom={false}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <FeatureGroup>
                <EditControl
                  position="topright"
                  onEdited={(e) => {}}
                  onCreated={(e) => onCreatedCustomPolygon(e)}
                  onDeleted={(e) => {}}
                  draw={{
                    rectangle: false,
                    circle: false,
                    polyline: false,
                    marker: false,
                    circlemarker: false,
                  }}
                />
              </FeatureGroup>
              {polygons.map((polygon) => (
                <Polygon pathOptions={purpleOptions} positions={polygon.points} key={polygon.id} />
              ))}
              <ChangeMapView coords={mapCenter} />
            </MapContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceMapping;

import React, { useEffect, useState } from 'react';
import './index.css';
import appointmentImage from '../../assets/appointment.png';

import _ from 'lodash';
import { actions } from '../../store';
import useAsyncEffect from 'use-async-effect';
import apiClient from '../../utils/apiClient';
import { StrategyTypes } from '../../enums/Strategies';
import { StateTransitionListener } from './StateTransitionListener';
import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import OnboardingStepper from '../../components/ProviderOnboarding/Stepper';

function ChooseStrategy() {

    const [state, setState] = useState({
        selectedStrategy: '',
        strategies: []
    });


    useAsyncEffect(async()=>{
        const strategies = await apiClient.get(`/strategies`);
        setState({
            ...state,
            strategies
        })
    },[]);

    const handleSelection = (e) => {
        setState({
            ...state,
            selectedStrategy: e.target.value
        })
    }

    const handleSave = () => {
        const { selectedStrategy } = state;
        if(selectedStrategy){
            actions.setStrategy(selectedStrategy);
            switch(selectedStrategy){
                case StrategyTypes.MINDBODY:
                case StrategyTypes.BOULEVARD:
                case StrategyTypes.SQUARE:
                    actions.setStatePointer(1);
                    break;
                case StrategyTypes.BOOKER:
                case StrategyTypes.MANUAL:
                    actions.setStatePointer(2);
                    break;
            }
        }
    }

    useEffect(() => {
      actions.setProvider({});
      actions.setCredentials({});
      actions.setLocation({});
    }, []);


    return (
        <Layout>
            <StateTransitionListener/>
            <SidePanel>
                <SidePanelImage imgSrc={appointmentImage} caption={'“First off, let\'s set up your business profile.”'} />
            </SidePanel>
            <OnboardingStepper totalSteps={3} activeStep={0} checkedSteps={0} />
            <div className="content-container">
                <div className="content">
                    <h1>Welcome, let's start with your buisness basics</h1>
                    <p>Select one of the following to connect your credentials. <br/>
                    By connecting your credentials we’ll do all the grunt work for you!</p>
                    <div className="selection-buttons">
                        {
                            state.strategies.map((s: string) => (
                                <Button
                                    type="button"
                                    color={ColorEnum.white}
                                    textColor="#A0A3BD"
                                    fullWidth
                                    outlined
                                    key={`strategy-${s}`}
                                    active={state.selectedStrategy === s}
                                    value={s}
                                    onClick={handleSelection}
                                >
                                    {s === StrategyTypes.MANUAL ? `Start Manually` : `Connect ${s}`}
                                </Button>
                            ))
                        }
                    </div>
                    <div className="actions">
                        {/* positioning hack */}
                        <div></div>
                        <Button
                            type="button"
                            class="next"
                            color={ColorEnum.secondary}
                            textColor="white"
                            onClick={handleSave}
                            active={!!state.selectedStrategy}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ChooseStrategy;

import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

interface CallToActionProps {
  handleClick: () => void,
  handleSkip?: () => void,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    textAlign: 'center',
    margin: 'auto',
    marginTop: theme.spacing(3),
  },
  text: {
    fontSize: 'large',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const CallToAction = (props: CallToActionProps) => {
  const { handleClick, handleSkip } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Welcome to Upkeep
      </Typography>
      <Typography variant="body1" className={classes.text} gutterBottom>
        Let's start by onboarding your account
      </Typography>
      <Button color="primary" variant="contained" className={classes.button} onClick={handleClick}>
        Start here
      </Button>
      {handleSkip &&
        <Button color="default" variant="contained" className={classes.button} onClick={handleSkip}>
          Skip (dev only)
        </Button>
      }
    </div>
  );
};

export default CallToAction;

const SQUARE_OAUTH_PERMISSIONS: string[] = [
    'CUSTOMERS_WRITE',
    'CUSTOMERS_READ',
    'APPOINTMENTS_READ',
    'APPOINTMENTS_ALL_READ',
    'APPOINTMENTS_BUSINESS_SETTINGS_READ',
    'APPOINTMENTS_WRITE',
    'APPOINTMENTS_ALL_WRITE',
    'MERCHANT_PROFILE_READ',
    'ORDERS_READ',
    'ORDERS_WRITE',
    'ITEMS_READ',
];

export default SQUARE_OAUTH_PERMISSIONS;

import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import apiClient from '../utils/apiClient';
import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

interface PropsType {
  providerId: number;
  locationId: number;
  selectedSpecialDate: any;
}

const GreenCheckbox = withStyles({
  root: {
    color: '#00AF9B',
    '&$checked': {
      color: '#00AF9B',
    },
    checked: {},
  },
})((props: any) => <Checkbox color="default" {...props} />);

const GreenTabs = withStyles({
  indicator: {
    backgroundColor: '#00AF9B',
  },
})(Tabs);

export class SpecialDateServicesMenu extends Component<PropsType, any> {
  constructor(props: PropsType) {
    super(props);

    console.log('[SpecialDateServicesMenu] loading SpecialDateServicesMenu: ', props);

    this.state = {
      locationId: props.locationId,
      providerId: props.providerId,
      selectedTab: 0,
      isLoading: true,
      selectedSpecialDate: props.selectedSpecialDate,
    };
  }

  async componentDidMount() {
    let [providerServiceItems, serviceCategories, bundles] = await Promise.all([
      apiClient.get(`/providers/svc-items`, { location: this.props.locationId }),
      apiClient.get('/svc-category'),
      apiClient.get('/bundle'),
    ]);

    // Assign bundles to serviceCategories, and add bundleId key to each bundle (id -> bundleId)
    let serviceItemIdToCategoryId = (() => {
      let map = {};
      serviceCategories.forEach((svcCategory) => {
        svcCategory.serviceItems.forEach((svcItem) => {
          map[svcItem.id] = svcCategory.id;
        });
      });
      return map;
    })();
    bundles = bundles.map((bundle) => {
      let serviceCategoryId = bundle.serviceItems.length && serviceItemIdToCategoryId[bundle.serviceItems[0].id];
      return {
        ...bundle,
        bundleId: bundle.id,
        serviceCategoryId,
      };
    });

    const providerServiceItemIds = providerServiceItems.map((svc) => svc.serviceItemId);
    const providerBundleIds = providerServiceItems.map((svc) => svc.bundleId);
    console.log('providerServiceItemIds = ', providerServiceItemIds);

    serviceCategories = serviceCategories.map((svcCategory) => {
      return {
        ...svcCategory,
        serviceItems: svcCategory.serviceItems.filter((serviceItem) => providerServiceItemIds.includes(serviceItem.id)),
        bundles: bundles.filter(
          (bundle) => bundle.serviceCategoryId === svcCategory.id && providerBundleIds.includes(bundle.bundleId),
        ),
      };
    });

    console.log('[ServicesMenu] componentDidMount', {
      providerServiceItems,
      serviceCategories,
      bundles,
    });

    this.setState({
      providerServiceItems,
      serviceCategories,
      isLoading: false,
      isDisabled: false,
    });
  }

  selectTab(n: number) {
    this.setState({ selectedTab: n });
  }

  isBundle(serviceItemOrBundle): boolean {
    return typeof serviceItemOrBundle.bundleId === 'number';
  }
  // returns the corresponding provider's service item, if it exists
  isServiceItemChecked(serviceItemOrBundle): any {
    const specialDateServiceItems = this.state.selectedSpecialDate.specialDateServiceItems;
    if (!specialDateServiceItems) return false;
    return specialDateServiceItems.some((item) =>
      this.isBundle(serviceItemOrBundle)
        ? item.bundleId === serviceItemOrBundle.bundleId
        : item.serviceItemId === serviceItemOrBundle.id,
    );
  }

  async handleServiceItemChecked(event, checked, item) {
    this.setState({ isDisabled: true });

    const startDate = this.state.selectedSpecialDate.startDate;
    const endDate = this.state.selectedSpecialDate.endDate;

    if (checked) {
      // CREATE SPECIAL DATE SERVICE ITEM
      try {
        const isBundle = this.isBundle(item);

        const body: {
          startDate: string;
          endDate: string;
          locationId: number;
          serviceItemId?: number;
          bundleId?: number;
        } = {
          startDate,
          endDate,
          locationId: this.state.locationId,
        };

        if (isBundle) body.bundleId = item.bundleId;
        else body.serviceItemId = item.id;

        const specialDateId = this.state.selectedSpecialDate.id
        const { specialDateServiceItems: updatedServiceItems } = await apiClient.put(`/special-date/${specialDateId}`, body);
        toast.success(`Enabled Service Item "${item.name}" on period ${startDate} - ${endDate}`);

        this.setState((state) => {
          state.selectedSpecialDate.specialDateServiceItems = updatedServiceItems;
          return state;
        });
      } catch (err) {
        toast.error(err.message);
        console.error(err);
      }
    } else {
      // DELETE PROVIDER SERVICE ITEM
      const providerSvcItem = this.isServiceItemChecked(item);
      if (!providerSvcItem) {
        console.error(`could not find provider service item for ${item.name}`);
        toast.error(`could not find provider service item for ${item.name}`);
      } else {
        const specialDateServiceItems = this.state.selectedSpecialDate.specialDateServiceItems;
        const uncheckedItem = specialDateServiceItems.find((specialDateServiceItem) =>
          this.isBundle(item)
            ? specialDateServiceItem.bundleId === item.bundleId
            : specialDateServiceItem.serviceItemId === item.id,
        );

        try {
          const res = await apiClient.delete(`/special-date/svc-item/${uncheckedItem.id}`);
          if (res === 1) {
            toast.success(`Disabled Service Item "${item.name}" on period ${startDate} - ${endDate}`);

            const updatedServiceItems = specialDateServiceItems.filter(
              ({ id }: { id: number }) => id !== uncheckedItem.id,
            );

            this.setState((state) => {
              state.selectedSpecialDate.specialDateServiceItems = updatedServiceItems;
              return state;
            });
          }
        } catch (err) {
          toast.error(err.message);
          console.error(err);
        }
      }
    }

    this.setState({ isDisabled: false });
  }

  render() {
    return this.state.isLoading ? (
      <Grid
        container
        style={{ borderRadius: '10px', backgroundColor: 'rgba(248, 249, 255, 0.45)', padding: '1em 1em' }}>
        <CircularProgress />
      </Grid>
    ) : (
      <Grid container style={{ borderRadius: '10px', backgroundColor: 'rgba(248, 249, 255, 0.45)', padding: '0' }}>
        {/* START tab menu */}
        <Grid container>
          <GreenTabs value={this.state.selectedTab} variant="scrollable" indicatorColor="primary">
            {this.state.serviceCategories.map((i, x) => (
              <Tab key={i.id} label={i.name} onClick={(e) => this.selectTab(x)} />
            ))}
          </GreenTabs>
        </Grid>
        {/* END tab menu */}

        <Grid
          container
          style={{ borderRadius: '0 0 10px 10px', backgroundColor: 'rgba(50, 50, 93, 0.05)', padding: '2em' }}>
          {/* START selected tab */}
          {this.state.serviceCategories.map(
            (category, x) =>
              x === this.state.selectedTab &&
              [...category.serviceItems, ...category.bundles].map((serviceItemOrBundle, y) => (
                <Grid item key={y} xs={12}>
                  <FormControlLabel
                    control={<GreenCheckbox />}
                    label={serviceItemOrBundle.name}
                    onChange={(event, checked) => this.handleServiceItemChecked(event, checked, serviceItemOrBundle)}
                    checked={this.isServiceItemChecked(serviceItemOrBundle)}
                    disabled={this.state.isDisabled}
                  />
                </Grid>
              )),
          )}
        </Grid>
        {/* END selected tab */}
      </Grid>
    );
  }
}

import React from 'react';
import './index.css';

import Layout from '../../components/Layout';
import useDraft from '../../utils/useDraft';
import apiClient from '../../utils/apiClient';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import PriceField from '../../components/PriceField';
import ChipsArray from '../../components/ChipArray';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../components/Button';
import TextField from '../../components/TextField';

function InviteProvider() {

    const [
        invite,
        setInvite,
        residualInvite,
        updateInvite,
        handleInviteInput
    ] = useDraft({});

    const [providers, setProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);

    const history = useHistory();

    const save = ()=> {
        (async()=>{
            const _invite = {
                ...invite,
                providerIdArray: selectedProviders.map(({id})=>id)
            }
            await apiClient.post('/provider/invite',_invite);
            toast.success('Sent invite!');
            history.push('/providers');
        })();

    }

    useEffect(()=>{
        (async()=>{
            try{
                const _providers = await apiClient.get(`/providers`);
                setProviders(_providers);   
            } catch(err){
                console.error(err);
            }
        })();
    },[])
    
    return (
        <Layout>
            <h4>Invite provider</h4>
            <Breadcrumbs aria-label="breadcrumb" className="mt-4 mb-2">
                <Link color="inherit" href="#/providers" >
                    Providers
                </Link>
                <Typography color="textPrimary">Invite Provider</Typography>
            </Breadcrumbs>
            <div className="upk-card shadow mt-2 bg-card-secondarypb-3" style={{ backgroundColor: "white" }}>
                <div className="header">
                    Invite Provider
                </div>
                <div className="body">
                    <TextField
                        marginTop
                        label="Email Address to invite"
                        fullWidth={true}
                        onChange={handleInviteInput(`email`)}
                        value={residualInvite('email')} />

                    <ChipsArray 
                        className="mt-1"
                        label="Providers to give access to"
                        onChange={(e, selected) => setSelectedProviders(selected)}
                        options={providers}/>

                    <PrimaryButton label='Save' style={{ marginTop: '2rem' }} onClick={save}/>

                </div>
            </div>
        </Layout>
    )
}

export default InviteProvider;
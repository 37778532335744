import React from "react";
import './SidePanelImage.css';

export default (props: any) => {
  return (
    <div className="side-panel-image-container">
      <img src={props.imgSrc} className="image"/>
      <div className="caption">{props.caption}</div>
    </div>
  );
}

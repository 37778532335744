import moment from 'moment';
import { ExpandMore } from '@material-ui/icons';
import React, { ChangeEvent, Component } from "react";
import apiClient from "../utils/apiClient";
import { ManualServiceItemTimeSelect, Timeslots } from './ManualServiceItemTimeSelect';
import { Accordion, AccordionSummary } from '../components/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { toast } from 'react-toastify';
import { StrategyTypes } from '../enums/Strategies';

interface PropsType {
    provider: any
    providerId: number;
    locationId: number;
}

interface StateType {
    panelExpanded?: number
    isLoading: boolean
    serviceCategories?: Array<any>
    availabilities?: Array<any>
    timezone?: string
}

export class ManualServiceItemSelect extends Component<PropsType, StateType> {

    constructor(props) {
        super(props);

        this.state = { isLoading: true };
    }

    async componentDidMount() {
        const [
            serviceCategories,
            availabilities,
        ] = await Promise.all([
            apiClient.get('/svc-category'),
            apiClient.get('/availabilities', { locationId: this.props.locationId })
        ])

        this.setState({
            serviceCategories,
            availabilities,
            isLoading: false,
            timezone: this.props.provider.timezone || 'America/Los_Angeles'
        })
    }

    // availabilityToOpenTimes takes the max open and close times of the availability
    // and saves them as the location open hours
    //
    // this is now hardcoded to 8/5 since availability, not openTime, is not used to filter manual availability
    availabilityToOpenTimes(timeslots: Timeslots) {
        const openTimesUpdate = {}

        for (const day in timeslots) {
            if (Object.prototype.hasOwnProperty.call(timeslots, day)) {

                // [...array] copies the array by value so we don't mutate the original
                // we're not using start and end times but hardcoding to 8am/5pm
                // const times: Array<{ time: Moment, checked: boolean }> = [...timeslots[day]];
                // let start = times.sort((a, b) => a.time.hour() - b.time.hour()).find(el => el.checked)
                // let end = times.sort((a, b) => b.time.hour() - a.time.hour()).find(el => el.checked)

                const dayIndex = moment().isoWeekday(day).weekday() + 1
                openTimesUpdate[`isOpenDay${dayIndex}`] = true
                openTimesUpdate[`day${dayIndex}open`] = `07:00:00`
                openTimesUpdate[`day${dayIndex}close`] = `22:00:00`

            }
        }
        console.log(`[AvailabilityToOpenTimes] Update: `, openTimesUpdate)
        return openTimesUpdate
    }

    async onTimeSelectSubmit(e: ChangeEvent, availability: any, category: any) {
        let rsp: any
        try {
            if (availability?.id) {
                rsp = await apiClient.put(`/availabilities/${availability.id}`, availability)
            } else {
                rsp = await apiClient.post(`/availabilities`, {
                    serviceCategory: { id: category.id },
                    location: { id: this.props.locationId },
                    timeslots: availability.timeslots,
                })
            }

            // update open times -- this will always be 8-5
            if (this.props.provider.strategy === StrategyTypes.MANUAL) {
                const openTimesUpdate = this.availabilityToOpenTimes(availability.timeslots)
                await apiClient.put(`/locations/${this.props.locationId}`, openTimesUpdate)
            }
        } catch (err) {
            toast.error(err.message);
            console.error(err);
            return
        }

        // close the panel if save is successful
        this.setState({ panelExpanded: undefined })

        toast.success(`Successfully saved availability for ${category.name}`)
    }

    findAvailability(categoryId: number) {
        if (!this.state.availabilities) { return undefined }
        return this.state.availabilities.find(a => a.serviceCategoryId === categoryId && a.locationId === this.props.locationId)
    }

    handleChange(e: React.ChangeEvent<{}>, isExpanded: boolean, panel: number) {
        if (isExpanded) {
            console.log(`[ManualServiceItemSelect] setting expanded panel to ${panel}`)
            this.setState({ panelExpanded: panel})
        } else {
            console.log(`[ManualServiceItemSelect] setting expanded panel to undefined`)
            this.setState({ panelExpanded: undefined})
        }
    }

    render() {
        return (
            this.state.isLoading || (
                <div>
                    {this.state.serviceCategories.map((category, i) =>
                        <Accordion key={`service-item-${i}`}
                            style={{ borderRadius: i === 0 && '10px 10px 0 0', backgroundColor: 'rgba(248, 249, 255, 0.45)' }}
                            expanded={this.state.panelExpanded === i}
                            onChange={(event, isExpanded) => this.handleChange(event, isExpanded, i)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`panel_${i}_content`}
                                id={`panel_${i}_header`}
                            >
                                {category.name}
                            </AccordionSummary>

                            <AccordionDetails
                                style={{ backgroundColor: 'rgba(50, 50, 93, 0.05)' }}
                            >

                                <ManualServiceItemTimeSelect
                                    key={`timeselect-${category.id}`}
                                    category={category}
                                    onSubmit={(e, availability, category) => this.onTimeSelectSubmit(e, availability, category)}
                                    availability={this.findAvailability(category.id)}
                                    timezone={this.state.timezone}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )}

                </div>
            )
        )

    }



}

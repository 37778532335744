import './index.css';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box } from '@material-ui/core';

import { getRole, login } from '../../firebaseAuth';
import Snackbar from '../../components/Snackbar';

import { useHistory } from 'react-router-dom';
import UserRoles from '../../enums/UserRoles';

import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import mobileAppImage from '../../assets/mobileApp.png';
import TextInput from '../../components/ProviderOnboarding/TextInput';

function Login() {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    setSnackbarMsg('');

    try {
      const trimmedEmail = email.replace(/\s+/g, '');
      setEmail(trimmedEmail);
      await login(trimmedEmail, password);

      const role = getRole();

      const isProvider = role === UserRoles.PROVIDER;

      if (!isProvider) {
        history.push('/services');
      } else {
        history.push({ pathname: '/provider/overview', state: { fromProvider: true } });
      }
    } catch (e) {
      let msg;
      const { code, message } = e;
      switch (code) {
        case 'auth/unauthorized':
          msg = 'You must be an Admin to access dashboard';
          break;
        case 'auth/invalid-email':
          msg = 'Invalid email provided.';
          break;
        case 'auth/user-not-found':
          msg = 'User does not exist.';
          break;
        case 'auth/wrong-password':
          msg = 'Password does not match the one in our records.';
          break;
        default:
          msg = message;
          console.error(e);
          break;
      }
      setSnackbarMsg(msg);
    }
  };

  return (
    <Layout>
      <SidePanel>
        <SidePanelImage imgSrc={mobileAppImage} caption={'“Welcome back! Happy to have you with us.”'} />
      </SidePanel>
      <div className="content">
        <h1>Sign In</h1>
        <p> Fill out the following information to get you started </p>
        <form noValidate autoComplete="off" onSubmit={handleLogin}>
          <TextInput
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            id="password"
            name="password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button class="login-button" type="submit" color={ColorEnum.primary} fullWidth>
            Sign In
          </Button>
          <Box textAlign="center" mt={2}>
            <Link component={RouterLink} to="/reset-password">
              Reset password
            </Link>
          </Box>
        </form>
        <Snackbar
          message={snackbarMsg}
          autoHideDuration={2000}
          open={!!snackbarMsg}
          onClose={() => setSnackbarMsg('')}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        />
      </div>
    </Layout>
  );
}

export default Login;

import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import appointmentImage from '../../assets/appointment.png';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import OnboardingStepper from '../../components/ProviderOnboarding/Stepper';
import Button, {ColorEnum} from '../../components/ProviderOnboarding/Button';
import SQUARE_OAUTH_PERMISSIONS from '../../constants/SquareOAuthPermissions';
import { StrategyTypes } from '../../enums/Strategies';
import { setCredentials } from '../../store/onboarding/actions';
import apiClient from '../../utils/apiClient';
import { StateTransitionListener } from './StateTransitionListener';

const SquareConnect = ({
    handleNext,
    handlePrevious,
}) => {
    const dispatch = useDispatch();
    const [isConnecting, setConnecting] = useState(false);

    const handleRedirect = useCallback(() => {
        const redirectUri = new URL(
            '/provider/onboarding/credentials',
            `${window.location.protocol}//${window.location.host}`,
        );
        const domain = process.env.REACT_APP_NODE_ENV === 'production' ?
            'squareup' :
            'squareupsandbox';

        let authUrl = `https://connect.${domain}.com/oauth2/authorize`;
        authUrl += `?client_id=${encodeURIComponent(process.env.REACT_APP_SQUARE_APP_ID)}`;
        authUrl += `&scope=${encodeURIComponent(SQUARE_OAUTH_PERMISSIONS.toString())}`;
        authUrl += `&session=false`;
        authUrl += `&redirect_uri=${encodeURIComponent(redirectUri.toString())}`;

        window.location.href = authUrl;
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');

        if (code) {
            setConnecting(true);

            apiClient.post('/strategies/exchange-oauth-code', {
                code,
                strategy: StrategyTypes.SQUARE,
            }).then(credentials => {
                dispatch(setCredentials(credentials));
                handleNext();
            }).catch(e => {
                console.error(e);
                toast.error(e.message);
                setConnecting(false);
            });
        }
    }, [window.location.search]);

    return (
        <Layout>
            <StateTransitionListener />
            <SidePanel>
                <SidePanelImage imgSrc={appointmentImage} caption={'“Let\'s import some information about your business.”'} />
            </SidePanel>
            <OnboardingStepper totalSteps={3} activeStep={1} checkedSteps={1} />
            <div className="content-container">
                <div className="content">
                    {!isConnecting && (
                        <h1>Connect your <em>Square</em> Account</h1>
                    )}

                    <br/>

                    {!isConnecting ? (
                        <>
                            <p>By logging into Square and granting access, we'll connect your booking system to the Upkeep app.</p>
                            <p>Click the <strong>Connect</strong> button to be redirected to Square's website to securely connect your Square account.</p>
                        </>
                    ) : (
                        <div className="text-center">
                            <br /><br />
                            <CircularProgress size={44}/>
                            <br /><br />
                            <p>
                                <strong>Connecting your Square account...</strong>
                            </p>
                        </div>
                    )}

                    {!isConnecting && (
                        <div className="actions">
                            <Button
                                type="button"
                                color={ColorEnum.transparent}
                                textColor="#4E4B66"
                                onClick={handlePrevious}
                            >
                                Back
                            </Button>

                            <Button
                                type="button"
                                color={ColorEnum.secondary}
                                textColor="white"
                                onClick={handleRedirect}
                            >
                                Connect
                            </Button>
                        </div>
                    )}
                </div>

            </div>
        </Layout>
    );
}

export default SquareConnect;

import React, { memo, useEffect, useState } from 'react';
import { Box, TextField, Divider, Typography } from '@material-ui/core';
import Switch from '../../components/Switch';
import NumberFormatCustom from '../../components/NumberFormatInput';
import { ServiceItem, TierServiceItem } from './TierForm';
import useStyles from './TierForm.styles';

type ServiceItemFieldProps = {
  item: ServiceItem;
  // tierServiceItem: TierServiceItem;
  selectedServiceItems: any;
  onChangeServiceItem: (
    item: ServiceItem,
    updateBody: {
      isChecked: boolean;
      priceIncents: string;
      minUnits: string;
    },
  ) => void;
};

const ServiceItemField = ({ item, selectedServiceItems, onChangeServiceItem }: ServiceItemFieldProps) => {
  const classes = useStyles();


  const tierServiceItem = selectedServiceItems.find((x) => x.serviceItemId === item.id)

  const minUnits = `${tierServiceItem ? tierServiceItem.minUnits : 1}`;
  const priceInCents = `${tierServiceItem ? tierServiceItem.priceInCents / 100 : item.priceInCents / 100}`;
  const isChecked = !!tierServiceItem;


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeServiceItem(item, {
      isChecked: event.target.checked,
      priceIncents: priceInCents,
      minUnits,
    });
  };

  const onChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeServiceItem(item, {
      isChecked: isChecked,
      priceIncents: event.target.value,
      minUnits,
    });
  };

  const onChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeServiceItem(item, {
      isChecked: isChecked,
      priceIncents: priceInCents,
      minUnits: event.target.value,
    });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" py={3} className={classes.serviceCategoryField}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
          <Typography className={classes.categoryName}>
            {item.subCategory} {item.name}
          </Typography>
          <Switch checked={isChecked} onChange={handleChange} />
        </Box>

        {item.subCategory === 'Botox' && (
          <TextField label="Min Units" variant="outlined" value={minUnits} onChange={onChangeUnit} />
        )}

        <TextField
          label="Price"
          variant="outlined"
          value={priceInCents}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          onChange={onChangePrice}
        />
      </Box>

      <Divider />
    </Box>
  );
};

export default memo(ServiceItemField);

import moment from 'moment-timezone';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import CompletedAppointment from './CompleteAppointment';

import './index.css';
import { AppointmentStatus } from './ProviderOverview';

const upcomingDays = 30;
const pastDays = 30;

interface AppointmentsProps {
  appointments: any;
  timezone: string;
  setDrawerState: Dispatch<SetStateAction<{ open: boolean; context: any }>>;
  loadAppointments: () => void;
}

interface FormattedAppointments {
  upcoming: any[];
  past: any[];
}

const initialFormattedAppointments: FormattedAppointments = {
  upcoming: [],
  past: [],
};

export default function Appointments({
  appointments,
  timezone,
  setDrawerState,
  loadAppointments,
}: AppointmentsProps): JSX.Element {
  const [formattedAppointments, setFormattedAppointments] =
    useState<FormattedAppointments>(initialFormattedAppointments);

  useEffect(() => {
    let formatted = { ...initialFormattedAppointments };
    
    const tz =  timezone || 'America/Los_Angeles'
    appointments.forEach(appointment => appointment.startDate = moment(appointment.startDate).tz(tz).format())

    for (let appointment of appointments) {
      if(appointment.status === AppointmentStatus.CANCELLED){}
      else if (
        moment(appointment.startDate) < moment().add(upcomingDays, 'days') &&
        moment(appointment.startDate) >= moment()
      ) {
        formatted.upcoming = [...formatted.upcoming, appointment];
      } else if (
        moment(appointment.startDate) < moment() &&
        moment(appointment.startDate) > moment().subtract(pastDays, 'days')
      ) {
        formatted.past = [...formatted.past, appointment];
      }
    }
    formatted.upcoming.sort(function (a, b) {
      return new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf();
    });
    formatted.past.sort(function (a, b) {
      return new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf();
    });
    setFormattedAppointments(formatted);
  }, [appointments, setFormattedAppointments]);

  const renderAppointment = (appointment: any, type: string, index: number) => {
    return (
      <div key={index} className="appointment">
        <div className="schedule">
          <div className="date">{moment(appointment.startDate).tz(timezone).format('MMM DD, YYYY')}</div>
          <div className="time" style={{ marginTop: '0.3rem', color: type === 'upcoming' ? '#348D84' : '#A0A3BD' }}>
            {moment(appointment.startDate).tz(timezone).format('hh:mm A')}
          </div>
        </div>
        <div className="booker-treatment">
          <div className="content">
            <div>
              <div className="sub">Booker</div>
              <Tooltip title={appointment.user.displayName} placement="bottom-start">
                <div className="sub-content">{appointment.user.displayName}</div>
              </Tooltip>
            </div>
            <div className="treatment ml-1">
              <div className="sub">Treatment</div>
              <Tooltip title={appointment.serviceItemDisplayName} placement="bottom-start">
                <div className="sub-content">
                 {appointment.serviceItemDisplayName}
                </div>
              </Tooltip>
            </div>
            <div className="checkbox-col mr-2">
              <div className="sub">Status?</div>
              <CompletedAppointment
                appointment={appointment}
                loadAppointments={loadAppointments}
                showLabel={false}
                className="checkbox"
              />
            </div>
          </div>
          <div
            className="bullet"
            onClick={() =>
              setDrawerState({
                open: true,
                context: appointment,
              })
            }>
            &bull;&bull;&bull;
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="upk-card box mt-1 appointments">
      <div className="heading-medium">Appointments</div>
      <div className="appointments-content">
        <div className="heading-small">Upcoming</div>
        {formattedAppointments.upcoming.map((a, i) => renderAppointment(a, 'upcoming', i))}
        <div className="heading-small past">Past</div>
        {formattedAppointments.past.map((a, i) => renderAppointment(a, 'past', i))}
      </div>
    </div>
  );
}

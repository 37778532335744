export default interface UserStateI {
  authResolved: boolean;
  isAdmin: boolean;
  viewAsProvider: boolean;
}

const initialUserState: UserStateI = {
  authResolved: false,
  isAdmin: false,
  viewAsProvider: false,
};

export { initialUserState };

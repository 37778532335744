import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles';
import { useForm, SubmitHandler } from 'react-hook-form';
import _ from 'lodash';

import payoutImage from '../../../assets/payout.png';
import OnboardingStepper from '../../../components/ProviderOnboarding/Stepper';
import Layout from '../../../components/ProviderOnboarding/Layout';
import SidePanel from '../../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../../components/ProviderOnboarding/SidePanelImage';
import TextInput from '../../../components/ProviderOnboarding/TextInput';
import Button, { ColorEnum } from '../../../components/ProviderOnboarding/Button';

type Inputs = {
  routingNumber: string,
  accountNumber: string,
  confirmAccountNumber: string,
}

interface ConnectBankAccountProps {
  handleReset?: () => void,
  handleSubmit: SubmitHandler<Inputs>,
  loading: boolean,
}

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow : 1,
    justifyContent : 'center',
  },
  spinner: {
    color: 'inherit',
  },
}));

const SpinnerAdornment = props => (
  <CircularProgress
    className={props.classes.spinner}
    size={20}
  />
);

const ConnectBankAccount = (props: ConnectBankAccountProps) => {
  const { register, trigger, handleSubmit, formState: { errors, isSubmitted }, getValues } = useForm<Inputs>({shouldUnregister: true});
  const {
    handleSubmit: onSubmit,
    handleReset,
    loading,
  } = props;
  const classes = useStyles();
  return (
    <Layout>
      <SidePanel>
        <SidePanelImage imgSrc={payoutImage} caption={'“Money makes the world go \'round.”'} />
      </SidePanel>
      <OnboardingStepper totalSteps={2} activeStep={1} checkedSteps={1} />
      <div className="content">
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <h1>Connect your banking information</h1>
          <p>Connect your bank account to receive payouts.</p>
          <TextInput
            id="routing-number"
            name="routingNumber"
            type="number"
            placeholder="Routing Number"
            inputProps={{
              'aria-label': 'Routing Number',
            }}
            ref={register({
              required: true,
              minLength: 9,
              maxLength: 9,
            })}
            error={!!errors.routingNumber}
            helperText={errors.routingNumber && 'Routing number must be 9 digits long'}
          />
          <TextInput
            id="account-number"
            type="number"
            placeholder="Account Number"
            inputProps={{
              'aria-label': 'Account Number',
            }}
            ref={register({
              required: true,
              minLength: 9,
              maxLength: 12,
              validate: {
                accountNumberEqual: value => (value === getValues().confirmAccountNumber) || 'Account number does not match',
              }
            })}
            name="accountNumber"
            error={!!errors.accountNumber}
            helperText={errors.accountNumber && (errors.accountNumber.message || 'Account number must be 9-12 digits')}
            onChange={async () => {
              if (isSubmitted && getValues('confirmAccountNumber')) {
                await trigger(['accountNumber', 'confirmAccountNumber']);
              }
            }}
          />
          <TextInput 
            id="confirm-account-number"
            type="number"
            placeholder="Confirm Account Number"
            inputProps={{
              'aria-label': 'Confirm Account Number',
            }}
            ref={register({
              required: true,
              minLength: 9,
              maxLength: 12,
              validate: {
                accountNumberEqual: value => (value === getValues().accountNumber) || 'Account number does not match',
              }
            })}
            onChange={async () => {
              if (isSubmitted && getValues('accountNumber')) {
                await trigger(['accountNumber', 'confirmAccountNumber']);
              }
            }}
            name="confirmAccountNumber"
            error={!!errors.confirmAccountNumber}
            helperText={errors.confirmAccountNumber && (errors.confirmAccountNumber.message || 'Account number must be 9-12 digits')}
          />
          <Button
            color={ColorEnum.secondary}
            active={_.isEmpty(errors)}
            disabled={!_.isEmpty(errors)}
            type="submit"
            fullWidth
          >
            {loading ? (
              <div style={{position: 'relative', top: '6px'}}>
                <SpinnerAdornment classes={classes}/>
              </div>
             ) : 'Connect Bank Account'}
          </Button>
          {handleReset &&
            <Button 
              color={ColorEnum.black}
              active={true}
              type="button"
              onClick={handleReset}
              style={{marginTop: '1rem', color: 'white'}}
              fullWidth
            >
              Reset
            </Button>
          }
        </form>
      </div>
    </Layout>
  );
};

export default ConnectBankAccount;

import React from 'react';
import { ServicesMenu } from '../ServicesMenu';

interface ServiceSelectionsProps {
  providerId: number;
  locationId: number;
}

export default function ServiceSelections({ providerId, locationId }: ServiceSelectionsProps): JSX.Element {
  
  return (
    <div className="border mb-1">
    <h4 className="heading-small text-muted mb-1" style={{ marginLeft: 'auto' }}>
      {' '}
      Manage Service Selections
    </h4>
    <ServicesMenu providerId={providerId} locationId={locationId}></ServicesMenu>
  </div>
  );
}

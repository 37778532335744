import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core";

const Outline = withStyles({
  root: {
    borderRadius: "15px",
    "& > * > *": {
      borderRadius: "15px",
    },
    "& div": {
      fontFamily: "Poppins",
      fontSize: "12px",
      borderRadius: "15px",
      paddingTop: "20px",
      paddingBottom: "10px",
    },
  },
})((props: any) => (
  <Select variant="outlined" className={props.classes.root} {...props} />
));

export const ServiceMultiSelector = ({
  serviceItems,
  activeServiceItems,
  handleChange,
}: {
  serviceItems: any[];
  activeServiceItems: number[];
  handleChange: any;
}) => {
  const [focus, setFocus] = useState(false);

  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = (e) => {
    if (e.target.value?.length > 0) setFocus(true);
    else setFocus(false);
  };

  serviceItems = serviceItems || [];
  activeServiceItems = activeServiceItems || [];

  const selector = (
    <FormControl
      style={{ borderRadius: "15px" }}
      fullWidth={true}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <InputLabel
        shrink
        style={{
          display: focus ? "initial" : "none",
          fontFamily: "Poppins",
          fontSize: "14px",
          margin: ".5rem 0 .5rem .85rem",
        }}
      >
        Valid service items - Leave empty for no restriction
      </InputLabel>
      <InputLabel
        style={{
          display:
            !focus && activeServiceItems?.length === 0 ? "initial" : "none",
          fontFamily: "Poppins",
          fontSize: "14px",
          margin: "-.4rem 0 0rem 1rem",
        }}
      >
        Valid service items - Leave empty for no restriction
      </InputLabel>
      <InputLabel
        shrink
        style={{
          display:
            activeServiceItems?.length > 0 ? "initial" : "none",
          fontFamily: "Poppins",
          fontSize: "14px",
          margin: ".5rem 0 .5rem .85rem",
        }}
      >
        Valid service items - Leave empty for no restriction
      </InputLabel>
      <Outline
        variant="outlined"
        multiple
        onChange={handleChange}
        value={activeServiceItems}
      >
        {serviceItems.map((serviceItem) => (
          <MenuItem
            key={serviceItem.id}
            value={serviceItem.id}
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
            }}
          >
            {`${serviceItem.serviceCategory.name} | ${serviceItem.name}`}
          </MenuItem>
        ))}
      </Outline>
    </FormControl>
  );

  return serviceItems.length > 0 ? selector : null;
};

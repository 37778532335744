import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    padding: `${theme.spacing(4)}px 0`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(5)}px 0`,
    },
  },
}));

export default (props: any) => {
  const classes = useStyles();

  return (
    <Container>
      <Box className={classes.root}>{props.children}</Box>
    </Container>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { getSettlementAccountPayouts } from '../../utils/settlementAccountHelpers';

import './index.css';

interface SettlementAccount {
  id: number;
  stripeAccountId: string;
}

interface ActivityProps {
  appointments: any[];
  settlementAccount: SettlementAccount;
}

export default function Activity({ appointments, settlementAccount }: ActivityProps): JSX.Element {
  const [revenue, setRevenue] = useState('0');
  const [totalRevenue, setTotalRevenue] = useState('0');

  const calculateRevenue = useCallback(async () => {
    if (!settlementAccount) return;
    const payouts = await getSettlementAccountPayouts(settlementAccount.id);
    let last30days = 0;
    let lifetime = 0;
    for (const payout of payouts) {
      if (payout.arrival_date >= moment().subtract(30, 'days').unix()) {
        last30days += payout.amount;
      }
      lifetime += payout.amount;
    }
    setRevenue((last30days / 100).toFixed(2));
    setTotalRevenue((lifetime / 100).toFixed(2));
  }, [settlementAccount]);

  useEffect(() => {
    calculateRevenue();
  }, [calculateRevenue]);

  const calculateTotalBookings = () => {
    const filtered = appointments.filter(
      (appointment) =>
        moment(appointment.startDate) <= moment() && moment(appointment.startDate) >= moment().subtract(30, 'days'),
    );
    return filtered.length;
  };

  return (
    <div className="upk-card box mt-1 activity">
      <div className="heading-medium">Activity</div>
      <div className="stats">
        <div>
          <div className="stats-number">{calculateTotalBookings()}</div>
          <div>Total bookings (past 30 days)</div>
        </div>
        <div className="revenue">
          <div className="stats-number">${revenue}</div>
          <div>Total payouts (past 30 days)</div>
        </div>
        <div className="revenue lifetime">
          <div className="stats-number">${totalRevenue}</div>
          <div>Lifetime payouts</div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import './Layout.css';

export default (props: any) => {
  return (
    <div className="onboarding-container">
      {props.children}
    </div>
  );
}

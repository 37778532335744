import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toast } from 'react-toastify';
import _ from 'lodash';
import useAsyncEffect from 'use-async-effect';

import apiClient from '../../utils/apiClient';
import PriceField from '../../components/PriceField';
import { TextButton } from '../../components/Button';

interface UserCreditsProps {
  user: any;
}

const UserCredits = ({ user }: UserCreditsProps) => {
  const [credits, setCredits] = useState<any[]>([]);
  const [userCredits, setUserCredits] = useState<any[]>([]);
  const [selectedCredit, setSelectedCredit] = useState(null);

  useAsyncEffect(async () => {
    try {
      const credits = await apiClient.get('/credits');
      fetchUserCredits();
      setCredits(credits);
    } catch (err) {
      toast.error('Error getting credits.');
      console.error(err);
    }
  }, []);

  const createUserCredit = async () => {
    try {
      await apiClient.post(`/user/${user.firebaseUid}/credits`, {
        credit: selectedCredit.id,
      });
      fetchUserCredits();
    } catch (err) {
      toast.error('Could not create user credit.');
      console.error(err);
    }
  };

  const fetchUserCredits = async () => {
    try {
      const _userCredits = await apiClient.get(`/user/${user.firebaseUid}/credits`);
      setUserCredits(_userCredits);
    } catch (err) {
      toast.error('Error fetching user credits');
      console.error(err);
    }
  };

  return (
    <Box>
      {user?.id && (
        <Box>
          <Autocomplete
            id="credits-autocomplete"
            fullWidth
            onChange={(e: any, selectedCredit: any) => setSelectedCredit(selectedCredit)}
            getOptionSelected={(selectedCredit, value) => selectedCredit.id === value.id}
            getOptionLabel={(selectedCredit) => selectedCredit.title}
            options={credits}
            renderInput={(params) => (
              <TextField {...params} label="Search Credits To Give to User" variant="outlined" />
            )}
          />
          {(selectedCredit && (
            <Button variant="outlined" fullWidth color="default" className="mt-1" onClick={createUserCredit}>
              Give User Selected Credit
            </Button>
          )) ||
            null}
        </Box>
      )}
      {userCredits.map((userCredit: any, i: number) => {
        const handleUpdate = (attr) => (e) => {
          const value = e.target.value;
          const _userCredits = _.cloneDeep(userCredits);
          _.set(_userCredits, `${i}.${attr}`, value);
          setUserCredits(_userCredits);
        };

        const handleRemove = async () => {
          try {
            await apiClient.delete(`/user/${user.id}/credits/${userCredits[i].id}`);
            const _userCredits = [...userCredits];
            _userCredits.splice(i, 1);
            setUserCredits(_userCredits);
          } catch (err) {
            toast.error('There was an error removing user credit.');
            console.error(err);
          }
        };

        return (
          <ExpansionPanel key={`user-credit-${i}`}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>{userCredit.credit.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <PriceField
                  className="mt-1 mb-2"
                  label="Spent Amount (In Dollars)"
                  fullWidth={true}
                  disabled
                  value={userCredit['spentInCents']}
                />

                <TextButton style={{ color: '#EF5DA8' }} label="Remove Credit" onClick={handleRemove} />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Box>
  );
};

export default UserCredits;
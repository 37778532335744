import _ from 'lodash';
import { toast } from 'react-toastify';
import { Token, loadStripe, CreateTokenBankAccountData } from '@stripe/stripe-js';
import { actions } from '../store';
import apiClient from './apiClient';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

interface CreateSettlementAccountPayload {
  accountId: number;
  url: string;
}

interface GetSettlementAccountPayload {
  details_submitted: boolean;
  requirements: {
    currently_due: Array<string>;
  };
  charges_enabled: boolean;
  payouts_enabled: boolean;
}

interface SettlementOnboardingLinkPayload {
  url: string;
}

const createSettlementAccount = async (
  locationId: number,
  submitting: (bool) => void,
): Promise<CreateSettlementAccountPayload> => {
  try {
    const settlementAccountConfig = await apiClient.post('/settlement-accounts/onboarding-link', {
      locationId,
    });
    return settlementAccountConfig;
  } catch (err) {
    toast.error('Failed to create settlement account, contact your developer for assistance.');
    console.error(err);
    submitting(false);
    return;
  }
};

const getSettlementAccount = async (settlementAccountId: number): Promise<GetSettlementAccountPayload> => {
  try {
    const settlementAccount = await apiClient.get(`/settlement-accounts/${settlementAccountId}`);
    return _.pick(settlementAccount, 'details_submitted', 'requirements', 'charges_enabled', 'payouts_enabled');
  } catch (err) {
    toast.error('Failed to retrieve Stripe onboarding status, contact your developer for assistance.');
    console.error(err);
    return;
  }
};

const getSettlementAccountOnboardingLink = async (
  settlementAccountId: number,
): Promise<SettlementOnboardingLinkPayload> => {
  try {
    const onboardingLinkConfig = await apiClient.get(`/settlement-accounts/onboarding-link/${settlementAccountId}`);
    return onboardingLinkConfig;
  } catch (err) {
    toast.error('Failed to get settlement account onboarding link, contact your developer for assistance.');
    console.error(err);
    return;
  }
};

const attachSettlementAccount = async (
  locationId: number,
  settlementAccountId: number,
  submitting?: (bool) => void,
): Promise<any> => {
  try {
    const updatedLocation = await apiClient.put(`/locations/${locationId}`, {
      settlementAccount: settlementAccountId,
    });
    return updatedLocation;
  } catch (err) {
    await deleteSettlementAccount(settlementAccountId, submitting);
    toast.error('Failed to attach settlement account to provider location, contact your developer for assistance.');
    console.error(err);
    return;
  }
};

const deleteSettlementAccount = async (settlementAccountId: number, submitting?: (bool) => void) => {
  try {
    const onboardingLinkConfig = await apiClient.delete(`/settlement-accounts/${settlementAccountId}`);
    return onboardingLinkConfig;
  } catch (err) {
    console.error(err);
    submitting?.(false);
    return;
  }
};

const initiateStripeOnboarding = async (locationId: number, submitting: (bool) => void) => {
  submitting(true);
  const settlementAccountConfig = await createSettlementAccount(locationId, submitting);
  if (settlementAccountConfig?.accountId) {
    const updatedLocation = await attachSettlementAccount(locationId, settlementAccountConfig.accountId, submitting);
    if (updatedLocation) {
      actions.setSettlementAccount(settlementAccountConfig.accountId);
      // redirect to Stripe onboarding
      window.location.href = settlementAccountConfig.url;
    }
  }
  submitting(false);
};

const retryStripeOnboarding = async (settlementAccountId: number, submitting: (bool) => void) => {
  submitting(true);
  const onboardingLinkConfig = await getSettlementAccountOnboardingLink(settlementAccountId);
  // redirect to Stripe onboarding
  if (onboardingLinkConfig?.url) {
    window.location.href = onboardingLinkConfig.url;
  }
  submitting(false);
};

const createToken = async (routingNumber: string, accountNumber: string): Promise<Token | void> => {
  const stripe = await stripePromise;
  const bankAccountParams: Partial<CreateTokenBankAccountData> = {
    country: 'US',
    currency: 'USD',
    routing_number: routingNumber,
    account_number: accountNumber,
  };
  // @ts-ignore somehow ts only recognizes the first type definition for this function
  const result = await stripe.createToken('bank_account', bankAccountParams);
  if (result.error) {
    toast.error(result.error.message);
    return;
  }
  return result.token;
};

const addBankAccountToSettlementAccount = async (settlementAccountId: number, token: string): Promise<any> => {
  try {
    const bankAccountConfig = await apiClient.post(`/settlement-accounts/bank-account/${settlementAccountId}`, {
      bankAccountToken: token,
    });
    return bankAccountConfig;
  } catch (err) {
    toast.error('Failed to connect bank account, contact your developer for assistance.');
    console.error(err);
    return;
  }
};

const connectBankAccount = async (settlementAccountId: number, data, submitting: (bool) => void) => {
  const { routingNumber, accountNumber } = data;
  submitting(true);
  const token = await createToken(routingNumber, accountNumber);
  if (token) {
    const bankAccountConfig = await addBankAccountToSettlementAccount(settlementAccountId, token.id);
    return bankAccountConfig?.id;
  }
  submitting(false);
};

const attachStripeAccount = async (locationId: number, stripeAccountId: string, submitting?: (bool) => void) => {
  // always creates a new settlement account for now
  try {
    const newSettlementAccount = await apiClient.post(`/settlement-accounts/`, {
      stripeAccountId,
    });
    const updatedLocation = await attachSettlementAccount(locationId, newSettlementAccount.id, submitting);
    updatedLocation.settlementAccount = newSettlementAccount;
    toast.success('Successfully attached Stripe account to provider.');
    return updatedLocation;
  } catch (err) {
    toast.error('Failed to connect stripe account, contact your developer for assistance.');
    console.error(err);
    return;
  }
};

const getSettlementAccountPayouts = async (settlementAccountId: number): Promise<any> => {
  try {
    const transfers = await apiClient.get(`/settlement-accounts/payouts/${settlementAccountId}`);
    return transfers;
  } catch (err) {
    toast.error('Failed to retrieve payouts, contact your developer for assistance.');
    console.error(err);
    return;
  }
};

export {
  createSettlementAccount,
  getSettlementAccount,
  attachSettlementAccount,
  attachStripeAccount,
  initiateStripeOnboarding,
  retryStripeOnboarding,
  connectBankAccount,
  getSettlementAccountPayouts,
};

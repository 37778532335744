import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    background: '#F7F7FC',
    padding: '52px 42px',
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  detailBlock: {
    background: '#fff',
    borderRadius: '12px',
    padding: '42px',
  },
  headerBox: {
    padding: '54px 36px',
    borderRadius: '12px',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    '& .image-wrapper': {
      width: 89,
      height: 89,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      border: `1px solid ${colors.grey[300]}`,
      '& .category-image': {
        width: 52,
        height: 52,
      },
    },
    '& .summary': {
      flex: 1,
      '& h3': {
        fontSize: '22px',
        lineHeight: '24px',
        marginBottom: theme.spacing(2),
        color: colors.grey[600],
        fontWeight: 600,
      },
      '& p': {
        color: colors.grey[500],
        lineHeight: '20px',
      },
    },
  },
  btnEdit: {
    width: 35,
    height: 35,
    background: colors.grey[50],
    borderRadius: '50%',
    color: colors.grey[500],
  },
  serviceItem: {
    '& .item-title': {
      color: colors.grey[500],
      fontWeight: 600,
    },
    '& .item-price': {
      color: colors.grey[500],
      fontWeight: 600,
    },
    '& .item-mins': {
      fontWeight: 600,
      color: colors.grey[400],
    },
  },
  editCategoryWrapper: {
    background: '#fff',
    padding: '42px 42px 64px',
    borderRadius: 12,
  },
  backBtn: {
    background: colors.grey[200],
  },
  tabContaniner:{
    height : '60px',
    width : '100%',
    display : 'flex',
    justifyContent : 'between',
    '&:hover': {
     cursor: "pointer",
    }
  },
  botoxSection : {
    width : '50%',
    fontSize : '18px',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    color: colors.grey[500],
    fontWeight: 'bold',
  }
}));

export default useStyles;

import React, { useEffect, useState } from 'react';

import Layout from '../../components/Layout';

import apiClient from '../../utils/apiClient';
import { PrimaryButton } from './../../components/Button';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import TextField from '../../components/TextField';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

function ServiceCatProvisioning() {
  const [serviceCat, setServiceCat] = useState({});
  const history = useHistory();

  const create = async () => {
    await apiClient.post('/svc-category', serviceCat);
    history.push('/services');
  };

  const handleKeyUp = (attr: any) => (e: any) => {
    const value = e.target.value;
    const _serviceCat: any = _.cloneDeep(serviceCat);
    _.set(_serviceCat, attr, value);
    setServiceCat(_serviceCat);
  };

  return (
    <Layout>
      <h4>Service Menu</h4>
      <Breadcrumbs aria-label="breadcrumb" className="mt-4 mb-2">
        <Link color="inherit" href="#/services">
          Service Menu
        </Link>
        <Typography color="textPrimary">Create Service Category</Typography>
      </Breadcrumbs>
      <div className="upk-form">
        <div className="header">
          Create Service Category
          <PrimaryButton label="Save" style={{ marginLeft: 'auto' }} onClick={() => create()} />
        </div>
        <div className="body">
          {/*<h6 className="heading-small text-muted">Services</h6>*/}
          <div className="container pb-2">
            <TextField id="standard-basic" label="Name" fullWidth={true} onChange={handleKeyUp('name')} />

            <TextField
              id="standard-basic"
              style={{ marginTop: '1rem' }}
              label="Icon URL"
              fullWidth={true}
              onChange={handleKeyUp('iconURL')}
            />

            {/*<Button variant="contained" color="primary" className="mt-2" fullWidth={true}>
                            Primary
                        </Button>*/}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ServiceCatProvisioning;

import axios from 'axios';

class APIClient {

    public host: URL;

    constructor(host: string){
        this.host = new URL(host);
    }

    static get Methods(){
        return { 
            'GET': 'GET', 
            'POST': 'POST', 
            'PUT': 'PUT', 
            'DELETE': 'DELETE' 
        }
    }

    static clientFactory(){
        return new APIClient(process.env.REACT_APP_API_HOST as string);
    }

    upload(endpoint: string, formData: object){
        
        const url = new URL(endpoint,this.host);

        return axios({
            method: 'post',
            url: url.toString(),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('idToken')}`
            }
        })
        .then(({data})=>data);
    }

    request(endpoint: string, params?: object, method?: string){

        const url = new URL(endpoint,this.host);
        const { searchParams } = url;

        let options: any;
        
        method = method || APIClient.Methods.GET;

        options = {
            method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('idToken')}`
            }
        };

        if(method === APIClient.Methods.GET && params){
            Object.entries(params).forEach(([key,value])=>{
                searchParams.append(key, value);
            })
        }
        else if(method === APIClient.Methods.POST || method === APIClient.Methods.PUT){
            options.data = params;
        }

        options.url = url.toString();
        
        return axios(options);
    }

    get = async (endpoint: string, params?: object) => {
        return (await this.request(endpoint, params)).data;
    }

    delete = async (endpoint: string, params?: object) => {
        return (await this.request(endpoint, params, APIClient.Methods.DELETE)).data
    }

    put = async (endpoint: string, params?: object) => {
        return (await (this.request(endpoint, params, APIClient.Methods.PUT))).data;
    }

    post = async (endpoint: string, params?: object) => {
        return (await this.request(endpoint, params, APIClient.Methods.POST)).data;
    }
    
}

const apiClient = APIClient.clientFactory();

export default apiClient;
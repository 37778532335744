import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  formBlock: {
    borderRadius: '12px',
    padding: '42px 36px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& h5': {
      color: colors.grey[600],
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '600',
    },
    '& p': {
      color: colors.grey[500],
    },
  },
  serviceCategoryField: {
    gap: '36px',
  },
  tabsScroller: {
    '& .MuiTabs-flexContainer': {
      gap: '36px',
    },
    '& .MuiButtonBase-root': {
      minWidth: 'initial',
      padding: 0,
    },
    '& .MuiTab-textColorPrimary': {
      fontSize: '16px',
      textTransform: 'initial',
    },
    '& .MuiTab-textColorPrimary[aria-selected="false"]': {
      color: colors.grey[400],
    },
  },
  categoryName: {
    fontSize: '14px',
    color: colors.grey[500],
    fontWeight: 600,
  },
  selectedCities: {
    gap: '10px',
  },
  chip: {
    height: '28px',
    borderRadius: '4px',
    backgroundColor: 'rgba(203, 238, 234, 0.3)',
    color: '#348D84',
    fontWeight: 600,
    '& .MuiChip-deleteIcon': {
      color: '#348D84',
      width: '20px',
      height: '20px',
    },
  },
  inputName: {
    '& .MuiInput-root': {
      fontSize: '22px',
      '&::after': {
        content: 'none',
      },
      '&::before': {
        content: 'none',
      },
    },
  },
  inputDescription: {
    '& .MuiInput-root': {
      '&::after': {
        content: 'none',
      },
      '&::before': {
        content: 'none',
      },
    },
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 10,
  },
});

export default useStyles;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const Primary = withStyles({
  root: {
    backgroundColor: '#348D84',
    color: 'white',
    borderRadius: '48px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#348D84',
    }
  },
  label: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '12px',
    textTransform: 'capitalize',
    letterSpacing: '0.75px',
  }
})(props => <MuiButton variant="contained" {...props} />);

export function PrimaryButton({label, ...props}) {
  return <Primary {...props}>{label}</Primary>
};

const Dark = withStyles({
  root: {
    backgroundColor: '#4E4B66',
    color: 'white',
    borderRadius: '48px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#4E4B66',
    }
  },
  label: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '12px',
    textTransform: 'capitalize',
    letterSpacing: '0.75px',
  }
})(props => <MuiButton variant="contained" {...props} />);

export function DarkButton({label, ...props}) {
  return <Dark {...props}>{label}</Dark>
};

const Text = withStyles({
  root: {
    color: '#6E7191',
    margin: 0,
    padding: 0,
    marginRight: "0.75rem"
  },
  label: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'capitalize',
    letterSpacing: '0.75px',
  }
})(props => <MuiButton variant="text" {...props} />);

export function TextButton({label, ...props}) {
  return <Text {...props}>{label}</Text>
};


const Add = withStyles({
  root: {
    backgroundColor: '#F5F6F7',
    color: '#6E7191',
    borderRadius: '48px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  label: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'capitalize',
    letterSpacing: '0.75px',
  }
})(props => <MuiButton variant="contained" {...props} />);

export function AddButton({label, ...props}) {
  return <Add {...props}>{label}</Add>
};

const Outlined = withStyles({
  root: {
    backgroundColor: 'transparent',
    color: '#EF5DA8',
    borderRadius: '48px',
    boxShadow: 'none',
    border: '1px solid #EF5DA8',
    '&:hover': {
      backgroundColor: '#EF5DA8',
      color: 'white'
    }
  },
  label: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '12px',
    textTransform: 'capitalize',
    letterSpacing: '0.75px',
  }
})(props => <MuiButton variant="contained" {...props} />);

export function OutlinedButton({label, ...props}) {
  return <Outlined {...props}>{label}</Outlined>
};

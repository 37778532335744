import React from 'react';
import { Box, DialogContent } from '@material-ui/core';

import Modal from '../../components/Modal';
import { SpecialDateModalState } from '.';

import { SpecialDateServicesMenu } from '../../components/SpecialDateServicesMenu';

type SpecialDateModalProps = {
  modalState: SpecialDateModalState;
  setModalState: React.Dispatch<React.SetStateAction<SpecialDateModalState>>;
};

const SpecialDateModal = ({ modalState, setModalState }: SpecialDateModalProps) => {
  return (
    <>
      <Modal
        title={modalState.title}
        open={modalState.open}
        onChange={(isOpen) => setModalState({ ...modalState, open: isOpen })}>
        <DialogContent dividers>
          <Box p={2}>
            <SpecialDateServicesMenu
              providerId={modalState.providerId}
              locationId={modalState.locationId}
              selectedSpecialDate={modalState.selectedSpecialDate}></SpecialDateServicesMenu>
          </Box>
        </DialogContent>
      </Modal>
    </>
  );
};

export default SpecialDateModal;

import React, { useState, useEffect } from 'react';
import './index.css';

import AppPreview from '../../components/ProviderOnboarding/AppPreview';
import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import OnboardingStepper from '../../components/ProviderOnboarding/Stepper';
import TextInput from '../../components/ProviderOnboarding/TextInput';

import _ from 'lodash';
import useDraft from '../../utils/useDraft';
import store, { actions } from '../../store';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { StateTransitionListener } from './StateTransitionListener';
import useAsyncEffect from 'use-async-effect';
import apiClient from '../../utils/apiClient';
import { StrategyTypes } from '../../enums/Strategies';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImageUploader, { PathInputEventI } from './../../components/ProviderOnboarding/ImageUploader';
import TextField from '../../components/TextField';
import MapsAutocomplete from '../../components/MapsAutocomplete';
import { FormControlLabel, Switch } from '@material-ui/core';


interface StrategyLocationI {
  externalId: number;
  name: string;
  description: string;
  fullAddress: string;
  streetAddress: string;
  city?: string;
  country?: string;
  zip?: string;
}

export default function OnboardingCreateLocation() {
  const globalLocation = useSelector((state: store) => state.onboarding.location);
  const globalCredentials = useSelector((state: store) => state.onboarding.credentials);
  const globalProvider = useSelector((state: store) => state.onboarding.provider);
  const selectedStrategy = useSelector((state: store) => _.get(state, 'onboarding.selectedStrategy'));

  const [providerLocations, setProviderLocations] = useState([]);
  const [isSubmitting, submitting] = useState(false);

  const [provider, setProvider, residualProvider, updateProvider, handleProviderInput] = useDraft(globalProvider);

  useAsyncEffect(async () => {
    const credentials: any = {
      strategy: selectedStrategy,
    };
    switch (selectedStrategy) {
      case StrategyTypes.MINDBODY:
        credentials.username = globalCredentials.username;
        credentials.password = globalCredentials.password;
        credentials.siteId = globalProvider.externalId;
      break;
      case StrategyTypes.BOULEVARD:
        credentials.businessId = globalProvider.externalId;
      break;
      case StrategyTypes.SQUARE:
        credentials.accessToken = globalCredentials.accessToken;
    }
    try {
      const _locations = await apiClient.post(`/strategies/fetch/locations`, credentials);
      setProviderLocations(_locations);
    } catch (err) {
      console.error(err);
      toast.error('Unable to get provider locations, check that provided credentials are correct.');
    }
  }, []);


  const [location, setLocation] = useState(globalLocation || {});
  const [isValid, setIsValid] = useState(false);

  const updateLocation = (attr: string, value: any) => {
    setLocation({
      ...location,
      [attr]: value,
    });
  };

  const handleLocationInput = (attr: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateLocation(attr, event.target.value);
  };

  const handleProviderLocationSelect = (location: StrategyLocationI) => {
    if (!location) return;

    const geocoder = new (window as any).google.maps.Geocoder();

    const failCase = function () {
      alert('Address components were unable to be extracted, please enter address, city, zip, ect. manually below.');
    };

    const { fullAddress, name, description, streetAddress, externalId } = location;

    name && updateProvider('name', name);
    description && updateProvider('description', description);

    if(externalId){
      updateLocation('externalLocationId', externalId)
    }

    geocoder.geocode({ address: fullAddress }, function (results, status) {
      if (status == 'OK') {
        console.log(`Geocode results`, results);
        if (results.length) {
          try {
            const result = results[0];
            console.log(results)
            const { address_components, geometry } = result;
            const coordinates = `${geometry.location.lat()},${geometry.location.lng()}`;
            const address = address_components.reduce(
              (residue, curr) => {
                const { long_name } = curr;
                if (curr.types.includes('administrative_area_level_1')) {
                  residue['state'] = long_name;
                }
                if (curr.types.includes('country')) {
                  residue['country'] = long_name;
                }
                if (curr.types.includes('locality')) {
                  residue['city'] = long_name;
                }
                if (curr.types.includes('postal_code')) {
                  residue['zip'] = long_name;
                }
                if (curr.types.includes('street_address')) {
                  residue['streetAddress'] = long_name;
                }
                return residue;
              },
              {
                coordinates,
                streetAddress,
              },
            );

            // edge case where city comes in as a sublocality
            if(!address?.city){
              const sublocality = address_components.find(component => component.types.includes("sublocality"));
              if(sublocality){
                address["city"] = sublocality["long_name"]
              }
            }

            setLocation({
              ...address,
              externalLocationId: externalId,
            });

          } catch (err) {
            console.error(err);
            failCase();
          }
        } else {
          failCase();
        }
      } else {
        failCase();
      }
    });
  };

  const handleLogoSelect = (event: PathInputEventI) => {
    handleProviderInput('logoURL')(event);
    updateProvider('logoURLPreview', event.target.preview);
  };

  const handleCoverPhotoSelect = (event: PathInputEventI) => {
    handleProviderInput('coverImageURL')(event);
    updateProvider('coverImageURLPreview', event.target.preview);
  };

  const residualLocation = (attr: string) => _.get(location, attr, '');

  const handlePrevious = () => {
    if ([StrategyTypes.BOOKER, StrategyTypes.MANUAL].includes(selectedStrategy)) {
      actions.setStatePointer(0);
    } else {
      actions.prevState();
    }
  };

  const handleSwitchInput = (attr: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    updateLocation(attr, value);
  };

  const handleProviderSave = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    let createdCredentials, createdLocation, createdProvider;

    if (isSubmitting) {
      return;
    } else {
      submitting(true);
    }

    let _location = _.cloneDeep(location);
    let _credentials = { ...globalCredentials };
    let _provider = {
      ...provider,
      strategy: selectedStrategy,
    };

    if (!_.isObject(_location.coordinates)) {
      _location.coordinates = ((_.isString(_location?.coordinates) && _location.coordinates) || '')
        .split(',')
        .reduce((acc, curr, index) => {
          const coord = parseFloat(curr.trim());
          acc[index === 0 ? 'lat' : 'lng'] = coord;
          return acc;
        }, {});
    }

    if (selectedStrategy === StrategyTypes.SQUARE) {
      _credentials = {
        username: _credentials.tokenType,
        password: _credentials.accessToken,
        secret: _credentials.refreshToken,
      };
    }

    const isCreate = !globalProvider?.id;
    if ([StrategyTypes.MINDBODY, StrategyTypes.SQUARE].includes(selectedStrategy)) {
      try {
        createdCredentials = isCreate
          ? await apiClient.post('/credentials', _credentials)
          : await apiClient.put('/credentials/' + _credentials.id, _credentials);
      } catch (err) {
        toast.error(`Failed to ${isCreate ? 'create' : 'update'} credentials, ensure fields are valid.`);
        console.error(err);
        submitting(false);
        return;
      }
      _provider.credentials = createdCredentials.id;
    }

    try {
      createdLocation = isCreate
        ? await apiClient.post('/locations', _location)
        : await apiClient.put('/locations/' + _location.id, _location);
    } catch (err) {
      toast.error(`Failed to ${isCreate ? 'create' : 'update'} location, ensure fields are valid.`);
      console.error(err);
      // clean up dependencies
      if (isCreate) {
        if (_.has(createdCredentials, 'id')) {
          await apiClient.delete(`/credentials/${createdCredentials.id}`);
        }
      }
      submitting(false);
      return;
    }

    _provider.location = createdLocation.id;

    if (selectedStrategy === StrategyTypes.SQUARE) {
      _provider.externalId = globalCredentials.merchantId;
    }

    try {
      createdProvider = isCreate
        ? await apiClient.post('/providers', _provider)
        : await apiClient.put('/providers/' + _provider.id, _provider);
    } catch (err) {
      toast.error(`Failed to ${isCreate ? 'create' : 'update'} provider, ensure fields are valid.`);
      console.error(err);
      // clean up dependencies
      if (isCreate) {
        if (_.has(createdCredentials, 'id')) {
          await apiClient.delete(`/credentials/${createdCredentials.id}`);
        }
        if (_.has(createdLocation, 'id')) {
          await apiClient.delete(`/locations/${createdLocation.id}`);
        }
      }
      submitting(false);
      return;
    }

    actions.setProvider(createdProvider);
    if ([StrategyTypes.MINDBODY, StrategyTypes.SQUARE].includes(selectedStrategy)) {
      actions.setCredentials(createdCredentials);
    }
    actions.setLocation(createdLocation);

    actions.nextState();

    submitting(false);
  };

  const assertExistance = function (obj: object, attributes: Array<string>) {
    return attributes.reduce((conjuction: boolean, attr: string) => {
      const fieldExists = !!_.get(obj, attr);
      if(!fieldExists){
        console.debug(`Form validation warning: field ${attr} is missing from object`, obj)
      }
      return conjuction && fieldExists;
    }, true);
  };

  useEffect(() => {
    console.debug('Validating Form...');

    const additionalRequiredProps = {
      location: [],
      provider: []
    }

    if(selectedStrategy === StrategyTypes.BOULEVARD){
      additionalRequiredProps.location.push('externalLocationId');
      additionalRequiredProps.provider.push('externalId');
    }

    const validity =
      assertExistance(location, ['streetAddress', 'city', 'country', 'zip', 'state', 'coordinates', ...additionalRequiredProps.location]) &&
      assertExistance(provider, ['name', 'coverImageURL', 'logoURL', 'description', ...additionalRequiredProps.provider]);

      if(!location.coordinates && location.streetAddress && location.city && location.state && location.country){
        const { streetAddress, addressLine2, city, state, country } = location;

        const geocoder = new (window as any).google.maps.Geocoder();
        const fullAddress = [streetAddress, addressLine2, city, state, country].join(', ');
        geocoder.geocode({ address: fullAddress }, function (results, status) {
          if (status == 'OK') {
            console.debug(`Geocode results`, results);
            if (results.length) {
              try {
                const result = results[0];
                const { geometry } = result;
                const coordinates = `${geometry.location.lat()},${geometry.location.lng()}`;
                updateLocation('coordinates',coordinates)
              } catch(err){
                console.error(err)
              }
            }
          }
        });
      }
    setIsValid(validity);
  }, [provider, location]);

  const caseInsensitiveMatch = (text = '', searchValue = '') => {
    const textToLower = text.toLowerCase();
    const searchValueToLower = searchValue.toLowerCase();
    return textToLower.includes(searchValueToLower);
  };

  const isManual = selectedStrategy === StrategyTypes.MANUAL;
  return (
    <Layout>
      <StateTransitionListener />
      <SidePanel>
        <AppPreview
          src={residualProvider('coverImageURLPreview')}
          description={residualProvider('description')}
          name={residualProvider('name')}
        />
      </SidePanel>
      <OnboardingStepper totalSteps={3} activeStep={1} checkedSteps={1} />
      <div className="content-container">
        <div className="content less-top-margin">
          <h1>Creating your Business Profile</h1>
          <p>Tell us about your buisness! This profile is what users will see when they start booking appointments.</p>
          <form noValidate autoComplete="off" onSubmit={handleProviderSave}>
            <h3>Business name</h3>
            <TextInput
              id="business-name"
              name="business-name"
              type="text"
              placeholder="ex. boutique spa"
              onChange={handleProviderInput('name')}
              value={residualProvider('name')}
            />
            {!isManual && (
              <>
                <h3>Select from Addresses</h3>
                <Autocomplete
                  id="location-autocomplete"
                  options={providerLocations}
                  fullWidth
                  getOptionLabel={(l: any) => `${l.fullAddress} | ${l.name}`}
                  onChange={(e, s) => handleProviderLocationSelect(s)}
                  placeholder="Find Provider Location"
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        inputProps={params.inputProps}
                        label="Enter your desired location"
                        variant="outlined"
                      />
                    );
                  }}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => {
                      const { fullAddress, name } = option;
                      return caseInsensitiveMatch(fullAddress, inputValue) || caseInsensitiveMatch(name, inputValue);
                    });
                  }}
                />
                <br />
              </>
            )}
            {isManual && <MapsAutocomplete onChange={(s) => handleProviderLocationSelect(s)} />}

            <h3>Business Address</h3>
            <div className="address">
              <TextInput
                id="street-address"
                name="street-address"
                type="text"
                placeholder="Street Address"
                value={residualLocation('streetAddress')}
                onChange={handleLocationInput('streetAddress')}
              />
              <TextInput
                id="address-line-2"
                name="address-line-2"
                type="text"
                placeholder="Address Line 2"
                value={residualLocation('addressLine2')}
                onChange={handleLocationInput('addressLine2')}
              />
              <div className="inline">
                <TextInput
                  id="city"
                  name="city"
                  type="text"
                  placeholder="City"
                  value={residualLocation('city')}
                  onChange={handleLocationInput('city')}
                />
                <TextInput
                  id="state"
                  name="state"
                  type="text"
                  placeholder="State"
                  value={residualLocation('state')}
                  onChange={handleLocationInput('state')}
                />
                <TextInput
                  id="zip-code"
                  name="zip-code"
                  type="text"
                  placeholder="Zip Code"
                  value={residualLocation('zip')}
                  onChange={handleLocationInput('zip')}
                />
              </div>
              <TextInput
                id="country"
                name="country"
                type="text"
                placeholder="Country"
                value={residualLocation('country')}
                onChange={handleLocationInput('country')}
              />
            </div>
            <h3>Upload Logo</h3>
            <ImageUploader onChange={handleLogoSelect} value={residualProvider('logoURLPreview')} isLogo />
            <h3>Upload Cover Image</h3>
            <ImageUploader onChange={handleCoverPhotoSelect} value={residualProvider('coverImageURLPreview')} />
            <h3>Tell others about your business</h3>
            <textarea
              className="multiline-input"
              id="description"
              name="description"
              placeholder="Share a description about your business"
              value={residualProvider('description')}
              onChange={handleProviderInput('description')}></textarea>
            <div style={{ float: 'right' }}>
              <FormControlLabel
                  label="Availability visible in mobile app?"
                  control={
                    <Switch checked={residualLocation('isPublic') || false} onChange={handleSwitchInput('isPublic')} />
                  }
                />
            </div>
            <div className="actions">
              <Button type="button" color={ColorEnum.transparent} textColor="#4E4B66" onClick={handlePrevious}>
                Back
              </Button>
              <Button type="submit" class="next" color={ColorEnum.secondary} textColor="white" active={isValid}>
                Next
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

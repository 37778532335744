import React from 'react';
import { ReactComponent as ImageIcon } from '../../assets/Icons/image.svg';

import './AppPreview.css';

interface AppPreviewPropsI {
  src: string
  description: string
  name: string
}

function AppPreview (props: AppPreviewPropsI) {
  const {
    src,
    description,
    name,
  } = props;
  return (
    <div className="app-preview-container">
      <div className="grey-background">
        <div style={{margin: '2rem'}}>
          <h2>App Preview</h2>
        </div>
        <div className="app-preview">
          {src
            ? <img className="cover-photo-preview" src={src} />
            : (
              <div className="cover-photo-preview">
                <ImageIcon width="50" height="50"/>
              </div>
            )
          }
          <h4>{name || 'Lavender Boutique'}</h4>
          <hr />
          <div style={{ marginBottom: '4rem', width: '100%', padding: '0 10%' }}>
            <h6>About</h6>
            <p>{description || 'Tell us about your business'}</p>
          </div>
          <hr />
          <div style={{ width: '100%', padding: '0 10%' }}>
            <div className="mockh6" />
            <div className="mockp" />
          </div>
        </div>
      </div>

    </div>
  );
}

export default AppPreview;

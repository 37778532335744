import { withStyles } from '@material-ui/core/styles';
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary } from '@material-ui/core';
import { colors } from '../../theme';

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    borderBottom: `1px solid ${colors.grey[200]}`,
    marginTop: '1px',
    fontFamily: 'Poppins',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: 56,
    padding: '12px 16px',
  },
  content: {
    justifyContent: 'space-between',
    height: '50px',
    alignItems: 'center',
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
    '& .accordion-right-content': {
      gap: theme.spacing(5),
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

import React, { ReactElement, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export type ComponentInputProps = React.ComponentProps<'input'>;

interface FormCheckboxProps extends ComponentInputProps {
  type?: string;
  label: string | ReactElement;
  fixed?: boolean;
  showLabel?: boolean;
  checked?: boolean;
  clickHandler?: () => void;
}
export default function FormCheckbox({
  type = 'primary',
  label,
  fixed = false,
  showLabel = true,
  checked = false,
  className,
  clickHandler,
}: FormCheckboxProps): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange = () => {
    if (fixed) return;
    if (clickHandler) {
      clickHandler();
    }
    setIsChecked(!isChecked);
  };

  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          style={{ color: type === 'warning' ? '#EF5DA8' : '#00AF9B' }}
          checked={isChecked}
          onChange={handleChange}
        />
      }
      label={showLabel ? <div style={{ color: type === 'warning' ? '#EF5DA8' : '#00AF9B' }}>{label}</div> : ''}
    />
  );
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';

import store, { actions } from '../../store';

import Layout from '../../components/Layout';
import { PrimaryButton } from '../../components/Button';
import useDraft from '../../utils/useDraft';
import apiClient from '../../utils/apiClient';
import { Availability, CategoryTiming, ManageProvider, ServiceSelections } from '../../components/Provider';
import { StrategyTypes } from '../../enums/Strategies';

import './index.css';

export default function ProviderProfile(): JSX.Element {
  const globalProvider = useSelector((state: store) => state.onboarding.provider);

  const [location, setLocation, residualLocation, updateLocation, handleLocationInput] = useDraft({});

  const [provider, setProvider, residualProvider, updateProvider, handleProviderInput] = useDraft(globalProvider);

  useEffect(() => {
    if (_.has(provider, 'id')) {
      let { location } = provider;
      let updatedLocation = _.cloneDeep(location);

      if (location && location.coordinates) {
        const { lat, lng } = location.coordinates;
        updatedLocation.coordinates = `${lat}, ${lng}`;
      }
      setProvider(provider);
      setLocation(updatedLocation);
    } else {
      setLocation({});
    }
  }, [provider, setLocation, setProvider]);

  const save = async () => {
    let errors = [];
    try {
      if (location && location.id) {
        const coordinates = (location.coordinates || '').split(',').reduce((acc, curr, index) => {
          const coord = parseFloat(curr.trim());
          acc[index === 0 ? 'lat' : 'lng'] = coord;
          return acc;
        }, {});
        await apiClient.put(`/locations/${location.id}`, {
          ...location,
          coordinates,
        });
        actions.setProvider({ ...provider, location });
      }
    } catch (err) {
      errors.push(err);
    }
    try {
      await apiClient.put(`/providers/${provider.id}`, provider);
    } catch (err) {
      errors.push(err);
    }
    if (!errors.length) {
      toast.success('Saved Provider');
    } else {
      toast.error('Failed to update provider');
      errors.forEach(console.error);
    }
  };

  return (
    <Layout>
      <div className="profile">
        <div className="header">
          <div className="title">Manage Provider</div>
          {_.has(provider, 'id') && (
            <div style={{ marginLeft: 'auto' }}>
              <PrimaryButton label="Save" onClick={() => save()} />
            </div>
          )}
        </div>
        <ManageProvider
          provider={provider}
          handleProviderInput={handleProviderInput}
          residualProvider={residualProvider}
          residualLocation={residualLocation}
          updateLocation={updateLocation}
          updateProvider={updateProvider}
        />
        {provider.strategy === StrategyTypes.MANUAL ? (
          <>
            <ServiceSelections providerId={provider.id} locationId={provider.location.id} />{' '}
            <div className="border mb-1">
              <CategoryTiming provider={provider} />
            </div>
          </>
        ) : (
          <Availability
            residualLocation={residualLocation}
            updateLocation={updateLocation}
            handleLocationInput={handleLocationInput}
          />
        )}
      </div>
    </Layout>
  );
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import { DarkButton } from '../../components/Button';
import { Progress } from './types';

import './index.css';

interface ProviderCoverProps {
  name: string;
  coverImageURL: string | null | undefined;
  progress: Progress;
}

export default function ProviderCover({ name, coverImageURL, progress }: ProviderCoverProps): JSX.Element {
  const history = useHistory();

  const redirectFinishProfile = () => {
    for (const step in progress.steps) {
      if (!progress.steps[step]) {
        if (step === 'availability' || step === 'cover') {
          history.push('/provider/profile');
        } else if (step === 'stripe') {
          history.push('/provider/onboarding/stripe')
        }
        break;
      }
    }
  }

  return (
    <div className="upk-card provider-cover">
      <div className="provider-header mb-1">
        <div
          className="cover-image"
          style={{
            backgroundImage: `url(${coverImageURL})`,
            backgroundColor: coverImageURL && coverImageURL.includes('a.mock.path') ? '#F0F2F5' : 'transparent',
          }}
        />
        <div className="title-banner" />
        <div className="title-container">
          <div className="title">{name}</div>
          {!progress.completed && <DarkButton label="Finish your profile" onClick={redirectFinishProfile}/>}
        </div>
      </div>
    </div>
  );
}

import { FormControlLabel, Fab, FormLabel, Checkbox, Grid } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import moment, { Moment } from 'moment-timezone';
import React, { ChangeEvent, Component } from "react";
import { AddButtonSet } from './AddButtonSet';
import { AddButton } from './Button';

interface PropsType {
    category: any
    availability?: any
    onSubmit: (event: ChangeEvent, availability: any, category: any, timeslots?: Timeslots) => void
    timezone: string
}

export interface Timeslots {
    Sunday: Array<{ time: Moment, checked: boolean }>,
    Monday: Array<{ time: Moment, checked: boolean }>,
    Tuesday: Array<{ time: Moment, checked: boolean }>,
    Wednesday: Array<{ time: Moment, checked: boolean }>,
    Thursday: Array<{ time: Moment, checked: boolean }>,
    Friday: Array<{ time: Moment, checked: boolean }>,
    Saturday: Array<{ time: Moment, checked: boolean }>,
}


interface StateType {
    category: any
    availability: { id: string, timeslots: Timeslots }
}

export class ManualServiceItemTimeSelect extends Component<PropsType, StateType> {

    style = {
        "lightGreen": "#FAFFFC",
        "darkGreen":"#348D84",
    }

    toMomentTimeslots(ts) {
        const tz = this.props.timezone
    
        let momentTimeslots: Timeslots = {
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: []
        }
    
        Object.keys(ts).forEach((day) =>
            momentTimeslots[day] = ts[day].map(t => { return { checked: t.checked, time: moment(t.time).tz(tz) } })
        )
        return momentTimeslots
    }

    // takes a string like "17:00:00"
    toMoment(s: string): Moment {
        const arr = s.split(":")
        const tz = this.props.timezone
        return moment().tz(tz).hour(Number(arr[0])).minute(Number(arr[1]))
    }
    
    defaultTimeslots = {
        Monday: this.timeArray(this.toMoment("07:00:00"), this.toMoment("22:00:00")),
        Tuesday: this.timeArray(this.toMoment("07:00:00"), this.toMoment("22:00:00")),
        Wednesday: this.timeArray(this.toMoment("07:00:00"), this.toMoment("22:00:00")),
        Thursday: this.timeArray(this.toMoment("07:00:00"), this.toMoment("22:00:00")),
        Friday: this.timeArray(this.toMoment("07:00:00"), this.toMoment("22:00:00")),
        Saturday: this.timeArray(this.toMoment("07:00:00"), this.toMoment("22:00:00")),
        Sunday: this.timeArray(this.toMoment("07:00:00"), this.toMoment("20:00:00")),
    }

    constructor(props) {
        super(props);

        let timeslots: Timeslots;

        if (this.props.availability) {
            timeslots = this.toMomentTimeslots(this.props.availability.timeslots)
        } else {
            timeslots = this.defaultTimeslots
        }
        const availability = this.props.availability || {}
        availability.timeslots = timeslots

        this.state = {
            category: this.props.category,
            availability
        };
    }

    timeArray(startTime: Moment, endTime: Moment): Array<{ time: Moment, checked: boolean }> {
        let timeArray: Array<{ time: Moment, checked: boolean }> = []
        let t = startTime.clone();

        while (t < endTime) {
            timeArray.push({ time: t.clone(), checked: false })
            t.add(15, 'm')
        }

        return timeArray
    }

    handleOnCheck(e: any, day: string, t: any) {
        const checked = e.target.checked
        const currentTimeArray: Array<{ time: Moment, checked: boolean }> = this.state.availability.timeslots[day]

        currentTimeArray.map(el => {
            if (el.time.format("HH:mm") === t.time.format("HH:mm")) {
                el.checked = checked
                return el
            }
            return el
        })

        let ts = this.state.availability.timeslots
        ts[day] = currentTimeArray

        const availability = this.state.availability
        availability.timeslots = ts

        this.setState({ availability: availability })
    }

    onSubmit(e) {
        this.props.onSubmit(e, this.state.availability, this.state.category, this.state.availability.timeslots)
    }

    render() {
        return this.state.availability?.timeslots && (
            <div>
                {Object.keys(this.state.availability.timeslots).map((day, i) =>
                    <Grid container item key={day}>
                        <FormLabel component="legend">{day}</FormLabel>

                        {this.state.availability.timeslots[day].length ? (
                            <Grid item style={{ paddingLeft: '1rem', paddingBottom: '2rem', overflow: 'scroll' }}>

                                {this.state.availability.timeslots[day].map(t =>
                                    <FormControlLabel
                                        key={`${this.props.category.name}-${this.props.category.id}-${t.time.format("HH:mm")}`}
                                        control={
                                            <AddButtonSet
                                                checked={t.checked}
                                                onChange={(e) => { this.handleOnCheck(e, day, t) }}
                                                value={t.time.format("hh:mm a")}
                                            />
                                        }
                                        label=""
                                    />
                                )}
                            </Grid>
                        ) :
                            // empty space
                            <Grid item style={{ padding: '2rem' }} >
                                <i>Closed...</i>
                            </Grid>
                        }
                    </Grid>
                )}
                <AddButton
                    label="Save"
                    style={{ color: this.style.darkGreen, backgroundColor: this.style.lightGreen}}
                        size='large'
                    onClick={(e) => this.onSubmit(e)}
                />

            </div>
        )
    }

}

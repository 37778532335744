export const concatString = (components: Array<string>, concatString: string = ' > ') => {
    return components
        .reduce((acc, component) => {
            if (component) acc.push(component);
            return acc;
        }, [])
        .join(concatString)
}

export const getQueryString = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export enum StepEnum  {
    CREDENTIALS = "CREDENTIALS",
    STRATEGY = "STRATEGY",
    LOCATION = "LOCATION",
    PROVIDER = "PROVIDER",
    PROVIDER_SERVICE_ITEMS = "PROVIDER_SERVICE_ITEMS",
    PROVIDER_LOCATION = "PROVIDER_LOCATION",
    FINISHED = "FINISHED",
}

const stepTitleMap = {
    [StepEnum.STRATEGY]: 'Connect with API',
    [StepEnum.CREDENTIALS]: 'Add Credentials',
    [StepEnum.LOCATION]: 'Create Provider',
    [StepEnum.PROVIDER_SERVICE_ITEMS]: 'Associate Products',
    [StepEnum.PROVIDER_LOCATION]: 'Associate Location',
    [StepEnum.FINISHED]: 'Complete'
} 

export const stateTransitions = {
    0: StepEnum.STRATEGY,
    1: StepEnum.CREDENTIALS,
    2: StepEnum.LOCATION,
    3: StepEnum.PROVIDER_SERVICE_ITEMS,
    4: StepEnum.FINISHED
}

export default Object.values(stateTransitions).map((step: StepEnum)=>stepTitleMap[step]);
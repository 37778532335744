import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Popover,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as DeleteIcon } from '../../assets/Icons/delete.svg';
import useStyles from './DeleteTierButton.styles';
import { withStyles } from '@material-ui/styles';

type DeleteTierButtonProps = {
  onDelete: () => void;
};

const ModalButton = withStyles(() => ({
  root: {
    borderRadius: '50px',
    boxShadow: 'none',
  },
}))(Button);

const DeleteTierButton = (props: DeleteTierButtonProps) => {
  const classes = useStyles();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickOpenModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
    handleClosePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onConfirmDelete = () => {
    props.onDelete();
  };

  return (
    <>
      <Button className="btn-delete-tier" onClick={handleClickDelete}>
        <MoreHorizIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box className={classes.deletePopover} onClick={handleClickOpenModal}>
          <DeleteIcon />
          <Typography>Delete Tier</Typography>
        </Box>
      </Popover>
      <Dialog
        open={openConfirmModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.confirmModal }}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="alert-dialog-title">Delete Tier?</DialogTitle>
        <DialogContent>
          <Box mb={5}>
            <IconButton className={classes.closeBtn} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
            <Typography>Are you sure you would like to Delete this Tier?</Typography>
            <Typography gutterBottom>This action cannot be undone.</Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <ModalButton onClick={handleCloseModal} color="default" variant="outlined">
              Cancel
            </ModalButton>
            <ModalButton onClick={onConfirmDelete} color="secondary" autoFocus variant="contained">
              Confirm Delete
            </ModalButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteTierButton;

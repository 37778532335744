import React, { useState } from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import Layout from '../../components/ProviderOnboarding/Layout';
import mobileAppImage from '../../assets/mobileApp.png';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import TextInput from '../../components/ProviderOnboarding/TextInput';
import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Snackbar, { Alert } from '../../components/Snackbar';
import { resetPassword } from '../../firebaseAuth';

type SnackBarState = {
  style: string;
  message: string;
};

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [snackbarMsg, setSnackbarMsg] = useState<SnackBarState | null>();

  const handleReset = async (e) => {
    e.preventDefault();
    const result = await resetPassword(email);
    if (result.success) {
      setSnackbarMsg({
        style: 'success',
        message: 'Reset password email is sent!',
      });
      setEmail('');
    } else {
      setSnackbarMsg({
        style: 'error',
        message: result.message,
      });
    }
  };

  return (
    <Layout>
      <SidePanel>
        <SidePanelImage imgSrc={mobileAppImage} caption={'“Welcome back! Happy to have you with us.”'} />
      </SidePanel>
      <Box className="content">
        <Typography variant="h4" gutterBottom>
          Reset password
        </Typography>
        <form noValidate onSubmit={handleReset}>
          <TextInput
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button class="reset-button" type="submit" color={ColorEnum.primary} fullWidth disabled={email === ''}>
            Reset
          </Button>
          <Box textAlign="center" mt={2}>
            <Link component={RouterLink} to="/login">
              Login
            </Link>
          </Box>
        </form>
        <Snackbar
          autoHideDuration={5000}
          open={!!snackbarMsg}
          onClose={() => setSnackbarMsg(null)}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          {snackbarMsg && (
            <Alert onClose={() => setSnackbarMsg(null)} severity={snackbarMsg.style}>
              {snackbarMsg.message}
            </Alert>
          )}
        </Snackbar>
      </Box>
    </Layout>
  );
};

export default ResetPassword;

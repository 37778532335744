import React from 'react';
import './Stepper.css';
import _ from 'lodash';

import { ReactComponent as ProgressComponent } from '../../assets/Icons/progress-component.svg';

interface StepperPropsI {
  totalSteps: number
  checkedSteps: number
  activeStep: number
}

export default function Stepper(props: StepperPropsI) {
  const {
    totalSteps,
    checkedSteps,
    activeStep,
  } = props;
  return (
    <div className="stepper">
      {
        _.range(totalSteps).map(index => {
          if (index < checkedSteps) {
            return (
              <ProgressComponent key={index} className="svg-circle" />
            )
          }
          return (
            <div key={`step-${index}`} className={`circle`
              + `${index === activeStep ? ' active' : ''}`
            } />
          )
        })
      }
    </div>
  )
};

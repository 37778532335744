import React, { useEffect, useState, useCallback } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import useSWR, { Fetcher } from 'swr';
import grey from '@material-ui/core/colors/grey';

import Layout from '../../components/Layout';
import TierForm from './TierForm';
import apiClient from '../../utils/apiClient';
import useStyles from './index.styles';
import DeleteTierButton from './DeleteTierButton';

export type PriceTier = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  cityIds: any;
  serviceItems: any;
};

type PriceTiers = PriceTier[];

const PricingTiers = () => {
  const classes = useStyles();

  const [priceTiers, setPriceTiers] = useState<PriceTier[] | null>();
  const [isFirstTiersLoaded, setIsFirstTiersLoaded] = useState(false);
  const [selectedPriceTierId, setSelectedPriceTierId] = useState<number | null>();
  const [selectedPriceTier, setSelectedPriceTier] = useState<PriceTier | null>();
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchPriceTiers = async () => {
    setFetching(true);
    try {
      const res = await apiClient.get('/price-tier');
      setPriceTiers(res);
    } catch (err) {
      console.log(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchPriceTiers();
  }, []);

  const onCreatePriceTier = async () => {
    try {
      const res = await apiClient.post('/price-tier', {
        name: 'Untitled',
      });
      setSelectedPriceTierId(res.id);
      fetchPriceTiers();
    } catch (err) {
      console.log(err);
    }
  };

  const onClickPriceTier = (priceTierId: number) => {
    setSelectedPriceTierId(priceTierId);
  };

  useEffect(() => {
    if (selectedPriceTierId && priceTiers) {
      const priceTier = priceTiers.find((x) => x.id === selectedPriceTierId);
      if (priceTier) {
        setSelectedPriceTier(priceTier);
      } else {
        setSelectedPriceTier(priceTiers.length > 0 ? priceTiers[0] : null);
      }
    } else if (selectedPriceTierId === null && priceTiers.length > 0) {
      setSelectedPriceTierId(priceTiers[0].id);
    }
  }, [selectedPriceTierId, priceTiers]);

  useEffect(() => {
    if (priceTiers && !isFirstTiersLoaded) {
      setIsFirstTiersLoaded(true);
      setSelectedPriceTierId(priceTiers[0].id);
      setSelectedPriceTier(priceTiers[0]);
    }
  }, [priceTiers, isFirstTiersLoaded]);

  const onDeleteTier = useCallback(
    async (priceTierId: number) => {
      try {
        await apiClient.delete(`/price-tier/${priceTierId}`);
        setSelectedPriceTierId(null);
        fetchPriceTiers();
      } catch (err) {
        console.log(err);
      }
    },
    [priceTiers],
  );

  return (
    <Layout hasContentSideBar>
      <Box display="flex" height="100%">
        <Box className={classes.sidebar} display="flex" flexDirection="column">
          <Box px={3} py={5}>
            <Typography variant="h5" className="header-title">
              Pricing Tiers
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: grey[200] }} />
          <Box p={5} flex={1} style={{ overflow: 'auto' }}>
            <Typography gutterBottom className="title">
              TIERS
            </Typography>
            <Box className={classes.priceTiers}>
              {priceTiers &&
                priceTiers.map((tier) => (
                  <Box
                    key={tier.id}
                    className={`${classes.priceTier} ${selectedPriceTierId === tier.id ? 'active' : ''}`}
                    onClick={() => onClickPriceTier(tier.id)}>
                    <span>{tier.name}</span>
                    {selectedPriceTierId === tier.id && priceTiers.length > 1 && (
                      <DeleteTierButton onDelete={() => onDeleteTier(tier.id)} />
                    )}
                  </Box>
                ))}
            </Box>
          </Box>
          <Divider style={{ backgroundColor: grey[200] }} />
          <Box
            className={classes.addTier}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onCreatePriceTier}>
            <Typography>+ Add Tier</Typography>
          </Box>
        </Box>
        <Box className={classes.content} flex={1}>
          {selectedPriceTier && (
            <TierForm
              loading={fetching}
              selectedPriceTier={selectedPriceTier}
              onUpdateSuccess={() => {
                fetchPriceTiers();
              }}
            />
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default PricingTiers;

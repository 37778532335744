import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../theme';

const primaryColor = '#00AF9B';

const useStyles = makeStyles((theme) => ({
  listItemWrapperOpen: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  listItem: { display: 'flex' },
  listItemClosed: { display: 'flex', justifyContent: 'center' },
  selectedTab: {
    background: '#F2FBFA',
    borderRadius: '8px',
  },
  tab: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    width: '90%',
    marginRight: '1rem',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
  },
  selectedTabText: {
    color: primaryColor,
  },
  tabText: {
    color: colors.grey['500'],
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    marginLeft: '-20px',
    boxShadow: 'none',
    paddingLeft: '10px',
    background: 'transparent',
  },
  popoverContent: {
    padding: '4px 10px',
    borderRadius: '100px',
    background: colors.grey['600'],
    color: colors.grey['50'],
    fontSize: '14px',
    lineHeight: '18px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '5px',
      left: '-15px',
      zIndex: 1,
      border: 'solid 10px transparent',
      borderRightColor: colors.grey['600'],
    },
  },
}));

export default useStyles;

import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, Grid, MenuItem } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify';

import apiClient from '../../utils/apiClient';
import { ModalState } from '.';
import Modal from "../../components/Modal";
import { PrimaryButton } from '../../components/Button';
import { Backdrop, CircularProgress } from '@mui/material';

const CreateUserModal = ({ open, setOpen, selecCreatedUser }) => {
    const [user, setUser] = useState({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        isSubscribedToNotifications: true,
    });
    const [date, setDate] = useState(null);
    const [backdrop, setBackdrop] = useState(false);
    const changeData = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
    }

    const createUser = (event) => {
        setBackdrop(true);
        apiClient.post(`/user/register`, {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            password: user.password,
            phone: user.phone,
            birthdate: date,
            isSubscribedToNotifications: true
        }).then(res => {
            setBackdrop(false);
            selecCreatedUser({id: res.id, name: user.firstName + " " + user.lastName, email: res.email});
            setOpen(false);
            toast.success('Created User');
        }).catch(err => { setBackdrop(false); toast.error('Created User Failed'); });
    }

    return (
        <>
            <Modal
                title="Create User"
                open={open}
                onChange={() => {
                    setOpen(false);
                }}>
                <DialogContent>
                    <Box sx={{ p: 2 }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '1rem' }}>Email: </div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    fullWidth
                                    value={user.email}
                                    onChange={changeData}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '1rem' }}>First Name: </div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    label="First Name"
                                    name="firstName"
                                    variant="outlined"
                                    fullWidth
                                    value={user.firstName}
                                    onChange={changeData}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '1rem' }}>Last Name: </div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    variant="outlined"
                                    fullWidth
                                    value={user.lastName}
                                    onChange={changeData}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '1rem' }}>Password: </div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    label="Password"
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    placeholder='(at least 8 characters)'
                                    value={user.password}
                                    onChange={changeData}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '1rem' }}>Birthdate: </div>
                            </Grid>
                            <Grid item xs={8}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(new Date(newValue));
                                        }}
                                        renderInput={(params) => <TextField style={{ width: "100%" }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '1rem' }}>Phone Number: </div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    label="Phone Number"
                                    name="phone"
                                    variant="outlined"
                                    fullWidth
                                    value={user.phone}
                                    onChange={changeData}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={backdrop}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
                </DialogContent>
                <DialogActions>
                    <PrimaryButton sx={{ p: 3 }} label="Save" onClick={createUser} />
                </DialogActions>
            </Modal>
        </>
    )
}
export default CreateUserModal;
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { DialogActions, makeStyles } from '@material-ui/core';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorder from '@mui/icons-material/StarBorder';
import { Badge, Avatar, Box, Grid, Checkbox } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { OutlinedButton, PrimaryButton } from '../../components/Button';
import apiClient from '../../utils/apiClient';
import profile from '../../assets/Icons/profile.svg';
import TextField from '../../components/TextField';
import Modal from '../../components/Modal';
import UserCredits from './UserCredits';
import UserRoles from '../../enums/UserRoles';

const useStyles = makeStyles(() => ({
  title: {
    color: '#4E4B66',
    padding: '0.7rem 0',
  },
  divider: {
    background: '#F7F7FC',
  },
  name: {
    color: '#4E4B66',
    fontSize: '16px',
    marginTop: '1rem',
  },
  expanded: {
    padding: '0 1.5rem',
  },
}));

interface Expanded {
  accountInformation: boolean;
  associatedProviders: boolean;
  bookingHistory: boolean;
  promoCreditUse: boolean;
}

interface DrawerContentProps {
  closeDrawer: () => void;
  user: any;
  appointments: any[];
  handleUserInput: (input: string) => void;
  residualUser: (input: string) => void;
  setUser: (input: any) => void;
  expanded: Expanded;
  setExpanded: (expanded: Expanded) => void;
  fetchUsers: () => void;
  providers: any[];
}

export default function DrawerContent({
  closeDrawer,
  user,
  appointments,
  handleUserInput,
  residualUser,
  setUser,
  expanded,
  setExpanded,
  fetchUsers,
  providers
}: DrawerContentProps): JSX.Element {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [role, setRole] = useState<UserRoles>(user.role);
  const [selectedProviders, setSelectedProviders] = useState([]);

  const handleClick = (name: string) => () => {
    const updated = { ...expanded };
    updated[name] = !updated[name];
    setExpanded(updated);
  };

  useEffect(() => {
    if (user) {
      setSelectedProviders(user.providers || []);
    }
  }, [user]);

  const deleteUser = async () => {
    try {
      closeDrawer();
      await apiClient.delete(`/user/${user.firebaseUid}`);
      toast.success('Deleted user');
      setUser({});
      fetchUsers();
    } catch (err) {
      toast.error('Error deleting user');
    }
  };

  const save = async () => {
    try {
      closeDrawer();
      let { displayName, password, photoURL, email, firebaseUid, inviteCode, availablePoints, birthdate, phone } = user;
      const name = displayName.split(' ')
      const firstName = name[0].trim();
      const lastName = name.length > 1 ? name[1].trim() : "";
      const providers = selectedProviders.map((p) => p.id);
      inviteCode = inviteCode.toUpperCase();
      const data = {
        email,
        firebaseUid,
        inviteCode,
        displayName,
        password,
        photoURL,
        firstName,
        lastName,
        availablePoints : Number(availablePoints),
        birthdate,
        phone,
        providers,
        role
      };
      for (const key in data) {
        const value = data[key];
        if ((value === '' && value.trim() === '') || value === null || value === undefined) {
          delete data[key];
        } else if (key === 'birthdate') {
          data[key] = moment(value);
        }
      }
      await apiClient.put(`/user/${user.firebaseUid}`, data);
      toast.success('Saved user');
      fetchUsers();
    } catch (err) {
      toast.error('Error saving user');
    }
  };

  const renderBookingHistory = (appointment: any, index: number) => {
    return (
      <Box
        key={index}
        sx={{
          ml: 1,
          mb: 0.5,
          px: 1,
          py: 2,
          bgcolor: '#F7F8FB',
        }}>
        <div className="item">
          <div>
            <b>{moment(appointment.startDate).format('MMM DD, YYYY')}</b>
            <span>
              <b style={{ color: '#6E7191', marginLeft: '1rem' }}>{moment(appointment.startDate).format('h:mm A')}</b>
            </span>
          </div>
          <div>{appointment.serviceItems.length} procedures</div>
        </div>
        <div className="item">
          <div>{appointment.name}</div>
          <b>prepaid</b>
        </div>
      </Box>
    );
  };

  const renderAssociatedProvider = (provider: any, index: number) => {
    return (
      <Box
        key={index}
        sx={{
          ml: 1,
          mb: 0.5,
          px: 1,
          py: 2,
          bgcolor: '#F7F8FB',
        }}>
        <div className="item">
          <div><b>{provider.name}</b></div>
          <div>{provider.strategy}</div>
        </div>
        <div className="item">
          <div>{provider.description}</div>
        </div>
      </Box>
    );
  };

  const handleRemoveUser = () => {
    closeDrawer();
    setOpenModal(true);
  };

  return (
    <div className="drawer-content">
      <div style={{ textAlign: 'right', marginBottom: '2rem', cursor: 'pointer' }}>
        <ArrowForwardIcon onClick={closeDrawer} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<PhotoCameraIcon />}>
          <Avatar alt="My Profile" src={profile} sx={{ height: '80px', width: '80px' }} />
        </Badge>
        {_.has(user, 'id') && !!user.displayName && <div className={classes.name}>{user.displayName}</div>}
      </div>
      <List sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '2rem' }} component="nav">
        {/* Account Information */}
        <Divider className={classes.divider} />
        <ListItemButton onClick={handleClick('accountInformation')}>
          <ListItemText primary="Account Information" className={classes.title} />
          {expanded.accountInformation ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded.accountInformation} timeout="auto" unmountOnExit className={classes.expanded}>
          <>
            {_.has(user, 'id') && (
              <>
                <Box mb={2} mt={2}>
                  <label>User Role</label>
                  <div>
                    <ToggleButtonGroup
                      color="primary"
                      value={role}
                      exclusive
                      onChange={(event, role)=>setRole(role)}
                      aria-label="Platform"
                      >
                      <ToggleButton value={UserRoles.USER}>{UserRoles.USER}</ToggleButton>
                      <ToggleButton value={UserRoles.PROVIDER}>{UserRoles.PROVIDER}</ToggleButton>
                      <ToggleButton value={UserRoles.ADMIN}>{UserRoles.ADMIN}</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Box>

                {(_.has(user, 'role') && user.role===UserRoles.PROVIDER) && (
                  <Box mb={2} mt={2}>
                    <label>Providers</label>
                    <Autocomplete
                      value={selectedProviders}
                      multiple
                      id="multi-provider"
                      options={providers}
                      onChange={(event, newValue) => {
                        setSelectedProviders(newValue);
                      }}
                      getOptionLabel={({ name }) => name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderOption={({ name }, { selected }) => {
                        return <div>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                        {name}
                      </div>;
                      }}
                      renderInput={(params) => <TextField {...params} label="Provider" />}
                    />
                  </Box>
                )}
                
                <Box mb={2} mt={2}>
                  <label>Full Name</label>
                  <TextField
                    fullWidth={true}
                    onChange={handleUserInput('displayName')}
                    value={residualUser('displayName')}
                  />
                </Box>

                <Box mb={2}>
                  <label>Email</label>
                  <TextField fullWidth={true} onChange={handleUserInput('email')} value={residualUser('email')} />
                </Box>

                <Box mb={2}>
                  <label>Birthday</label>
                  <TextField
                    fullWidth={true}
                    onChange={handleUserInput('birthdate')}
                    value={residualUser('birthdate')}
                  />
                </Box>

                <Box mb={2}>
                  <label>Phone</label>
                  <TextField fullWidth={true} onChange={handleUserInput('phone')} value={residualUser('phone')} />
                </Box>

                <Box mb={4}>
                  <label>Firebase Id</label>
                  <TextField
                    fullWidth={true}
                    onChange={handleUserInput('firebaseUid')}
                    value={residualUser('firebaseUid')}
                  />
                </Box>
                <Box mb={4}>
                  <label>Invite Code</label>
                  <TextField
                    fullWidth={true}
                    onChange={handleUserInput('inviteCode')}
                    value={residualUser('inviteCode')}
                    inputProps={{
                      style:{
                        textTransform:"uppercase",
                      }
                    }}
                  />
                </Box>
                <Box mb={4}>
                  <label>Photo url</label>
                  <TextField fullWidth={true} onChange={handleUserInput(`photoURL`)} value={residualUser('photoURL')} />
                </Box>
                <Box mb={4}>
                  <label>Rewards Points</label>
                  <TextField
                    fullWidth={true}
                    onChange={handleUserInput(`availablePoints`)}
                    value={residualUser('availablePoints')}
                  />
                </Box>
                <Box mb={4}>
                  <label>Change Password</label>
                  <TextField fullWidth={true} onChange={handleUserInput(`password`)} value={residualUser('password')} />
                </Box>
              </>
            )}
          </>
        </Collapse>
        {/* Associated Providers */}
        {(_.has(user, 'role') && user.role===UserRoles.PROVIDER) && (
          <>
            <Divider className={classes.divider} />
            <ListItemButton onClick={handleClick('associatedProviders')}>
              <ListItemText primary="Associated Providers" className={classes.title} />
              {expanded.associatedProviders ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expanded.associatedProviders} timeout="auto" unmountOnExit>
              <Box mb={4} mt={1}>
                <List component="div" disablePadding>
                  {!user.providers?.length && <div className={classes.expanded}>No Associated Providers</div>}
                  {user.providers?.map((a, i) => renderAssociatedProvider(a, i))}
                </List>
              </Box>
            </Collapse>
          </>
        )}
        {/* Booking History */}
        <Divider className={classes.divider} />
        <ListItemButton onClick={handleClick('bookingHistory')}>
          <ListItemText primary="Booking History" className={classes.title} />
          {expanded.bookingHistory ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded.bookingHistory} timeout="auto" unmountOnExit>
          <Box mb={4} mt={1}>
            <List component="div" disablePadding>
              {!appointments.length && <div className={classes.expanded}>No appointments</div>}
              {appointments.map((a, i) => renderBookingHistory(a, i))}
            </List>
          </Box>
        </Collapse>
        {/* Promo & Credit use */}
        <Divider className={classes.divider} />
        <ListItemButton onClick={handleClick('promoCreditUse')}>
          <ListItemText primary="Promo & Credit use" className={classes.title} />
          {expanded.promoCreditUse ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded.promoCreditUse} timeout="auto" unmountOnExit className={classes.expanded}>
          {/* <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItemButton>
          </List> */}
          <UserCredits user={user} />
        </Collapse>
      </List>
      {_.has(user, 'id') && (
        <Grid container spacing={2} mt={5}>
          <Grid item xs={8}>
            <div style={{ textAlign: 'left' }}>
              <PrimaryButton label="Save" fullWidth onClick={() => save()} />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: 'right' }}>
              <OutlinedButton label="Remove" fullWidth onClick={() => setOpenModal(true)} />
            </div>
          </Grid>
        </Grid>
      )}
      <Modal title="Are you sure you want to remove user?" open={openModal} onChange={handleRemoveUser}>
        <DialogActions>
          <PrimaryButton label="Confirm" onClick={deleteUser} />
        </DialogActions>
      </Modal>
    </div>
  );
}

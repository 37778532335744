import { createStore, compose, applyMiddleware, bindActionCreators } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import * as actions from './actions';
import * as _ from 'lodash';

type AppState = ReturnType<typeof rootReducer>

const ENV: string = process.env.REACT_APP_NODE_ENV || '';

//----Development
const composeEnhancers = ((ENV !== 'production' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: false) || compose);
//---------

const STATE_LS_KEY = 'state';

const store = (function(){
  const peristedState = localStorage.getItem(STATE_LS_KEY);
  let args = [];
  if(peristedState &&  peristedState !== JSON.stringify({})){
    args.push(JSON.parse(peristedState));
  }
  args.push(composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
    )
  ));
  return createStore(rootReducer,...args);
})();

// Persist state to local storage
store.subscribe(()=>{
  const state = store.getState();
  const _state = _.cloneDeep(state);
  if(_.has(_state,'onboarding.credentials.password')){
    _.set(_state,'onboarding.credentials.password','');
  }
  localStorage.setItem(STATE_LS_KEY, JSON.stringify(state));
})

export default AppState;

const bindedActions = bindActionCreators(actions, store.dispatch);

export {
  store,
  bindedActions as actions
};




import React from 'react';
import './Button.css';

export enum ColorEnum {
  primary = "primary",
  white = "white",
  secondary = "secondary",
  transparent = "transparent",
  black = "black",
}

interface ButtonPropsI {
  type: "button" | "submit" | "reset"
  onClick?: React.UIEventHandler
  color: ColorEnum,
  textColor?: string,
  active?: boolean,
  outlined?: boolean,
  fullWidth?: boolean,
  class?: string,
  children: string | React.ReactChild,
}

export default function Button(props: ButtonPropsI & React.HTMLProps<HTMLButtonElement>) {
  const {
    type,
    children,
    onClick,
    color,
    active,
    outlined,
    fullWidth,
    textColor = 'white',
    class: containerClass = '',
    ...buttonProps
  } = props;
  const cssProperties = {
    '--main-button-color': textColor,
  } as React.CSSProperties;

  return (
    <div className={`button-container ${containerClass} ${fullWidth ? ' fullWidth' : ''}`}>
      <button
        type={type}
        onClick={onClick}
        className={`${color}`
          + `${active ? ' active' : ''}`
          + `${outlined ? ' outlined' : ''}`
        }
        style={cssProperties}
        {...buttonProps}
      >
        {children}
      </button>
    </div>
  )
};

import { UserActionTypes } from './types';
import UserStateI, { initialUserState } from './state';

export const userReducer = (state = initialUserState, action: any): UserStateI => {
  switch (action.type) {
    case UserActionTypes.LOGIN:
      return {
        isAdmin: false,
        authResolved: true,
        viewAsProvider: false,
      };
    case UserActionTypes.LOGOUT:
      return {
        isAdmin: false,
        authResolved: true,
        viewAsProvider: false,
      };
    case UserActionTypes.SET_ADMIN:
      return {
        ...state,
        isAdmin: true,
      };
    case UserActionTypes.SET_VIEW_AS_PROVIDER:
      return {
        ...state,
        viewAsProvider: true,
      };
    case UserActionTypes.SET_VIEW_AS_ADMIN:
      return {
        ...state,
        viewAsProvider: false,
      };
    default:
      return { ...state };
  }
};

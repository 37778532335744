import 'bootstrap/dist/css/bootstrap.min.css';
import './styling/index.css';
import './styling/utility.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts/sf-pro-display-cufonfonts-webfont/style.css';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core';
import ProtectedRoute, { ProviderProtectedRoute } from './components/ProtectedRoute';

import Login from './views/Login/';
import ResetPassword from './views/ResetPassword';
import ProviderAccountCreation from './views/ProviderAccountCreation';
import Credits from './views/Credits';
import CreditsCreate from './views/Credits/create';
import ServiceMenu from './views/ServiceMenu/';
import ServiceCatProvisioning from './views/ServiceMenu/create';
import {
  ProviderOnboardingChooseStrategy,
  ProviderOnboardingLocation,
  ProviderOnboardingCreate,
  ProviderOnboardingSvcItems,
  ProviderOnboardingAppointments,
} from './views/ProviderOnboarding';
import { ProviderOverview } from './views/ProviderOverview';
import ProviderProfile from './views/Provider/Profile';
import { StripeOnboardingController } from './controllers/Onboarding';

import Users from './views/Users';
import ProviderInvite from './views/Provider/InviteProvider';

import { store } from './store/';
import ManageProviders from './views/Provider';
import Promocodes from './views/Promo';
import CreatePromocode from './views/Promo/CreatePromocode';
import CreateCredentials from './views/ProviderOnboarding/CreateCredentials';
import CreateProviderLocation from './views/ProviderOnboarding/CreateProviderLocation';
import Overview from './views/Overview/Overview';
import Notifications from './views/Notifications/Notifications';
import { theme } from './theme';
import PricingTiers from './views/PricingTiers';
import { LicenseInfo } from '@mui/x-license-pro';

import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';


LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY);

const googleMapAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const App = () => {
  const [googleMapLoaded, setGoogleMapLoaded] = useState<boolean>(false);

  useEffect(() => {
    const googleMapsLoader = new GoogleMapsLoader({
      apiKey: googleMapAPIKey,
      version: "weekly",
      libraries: ["places"]
    });
    googleMapsLoader.load().then(()=>{
      setGoogleMapLoaded(true);
    })
  }, []);

  return (
    googleMapLoaded && (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/provider/invite/:code" component={ProviderAccountCreation} />
              <ProtectedRoute path="/providers/provider/invite" component={ProviderInvite} />
              <ProtectedRoute path="/users" component={Users} />
              <ProtectedRoute path="/credits/create" component={CreditsCreate} />
              <ProtectedRoute path="/credits" component={Credits} />
              <ProtectedRoute path="/promocodes/create" component={CreatePromocode} />
              <ProtectedRoute path="/promocodes" component={Promocodes} />
              <ProtectedRoute path="/overview" component={Overview} />
              <ProtectedRoute path="/notifications" component={Notifications} />
              <ProviderProtectedRoute path="/providers" component={ManageProviders} />
              <ProviderProtectedRoute path="/provider/onboarding/credentials" component={CreateCredentials} />
              <ProviderProtectedRoute
                path="/provider/onboarding/appointments"
                component={ProviderOnboardingAppointments}
              />
              <ProviderProtectedRoute path="/provider/onboarding/serviceitems" component={ProviderOnboardingSvcItems} />
              <ProviderProtectedRoute path="/provider/onboarding/create" component={ProviderOnboardingCreate} />
              <ProviderProtectedRoute path="/provider/onboarding/location" component={ProviderOnboardingLocation} />
              <ProviderProtectedRoute path="/provider/onboarding/apilocation" component={CreateProviderLocation} />
              <ProviderProtectedRoute path="/provider/onboarding/stripe" component={StripeOnboardingController} />
              <ProviderProtectedRoute path="/provider/onboarding" component={ProviderOnboardingChooseStrategy} />
              <ProviderProtectedRoute path="/provider/overview" component={ProviderOverview} />
              <ProviderProtectedRoute path="/provider/profile" component={ProviderProfile} />
              <ProtectedRoute path="/services/create" component={ServiceCatProvisioning} />
              <ProtectedRoute path="/services" component={ServiceMenu} />
              <ProtectedRoute path="/pricing-tiers" component={PricingTiers} />
              <ProtectedRoute path="/" component={ServiceMenu} />
            </Switch>
          </Router>
        </Provider>
      </ThemeProvider>
    )
  );
};

ReactDOM.render(
  <div>
    <App />
    <ToastContainer />
  </div>,
  document.getElementById('root'),
);

import React, { useState } from 'react';
import './index.css';

import Layout from '../../components/Layout';
import Stepper from '../../components/Stepper';
import _ from 'lodash';
import useDraft from '../../utils/useDraft';
import { Button, FormControlLabel } from '@material-ui/core';
import store, { actions } from '../../store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import steps from '../../store/onboarding/steps';
import apiClient from '../../utils/apiClient';
import { toast } from 'react-toastify';
import { StateTransitionListener } from './StateTransitionListener';
import { StrategyTypes } from '../../enums/Strategies';
import { PrimaryButton, TextButton } from '../../components/Button';
import TextField from '../../components/TextField';

export default function CreateProvider() {

    const [isSubmitting,submitting] = useState(false);
    const globalProvider = useSelector((state:store)=>state.onboarding.provider);
    const globalLocation = useSelector((state:store)=>state.onboarding.location);
    const globalCredentials = useSelector((state:store)=>state.onboarding.credentials);
    const selectedStrategy = useSelector((state:store)=>state.onboarding.selectedStrategy);

    const [
        provider,
        setProvider, 
        residualProvider,
        updateProvider, 
        handleProviderInput 
    ] = useDraft(globalProvider);

    const history = useHistory();

    const handlePrevious = () => {
        actions.prevState();
    }

    const handleSave = async () => {

        let createdCredentials, createdLocation, createdProvider;

        if(isSubmitting){ return; } else {
            submitting(true);
        }

        if(!globalProvider || !globalProvider.id){

            let _location = _.cloneDeep(globalLocation);
            let _credentials = {...globalCredentials};
            let _provider = {
                ...provider,
                strategy: selectedStrategy
            };

            _location.coordinates = ((_location && _location.coordinates) || '').split(',').reduce((acc,curr,index)=>{
                const coord = parseFloat(curr.trim());
                acc[index === 0 ? 'lat' : 'lng'] = coord;
                return acc;
            },{});

            if(selectedStrategy === StrategyTypes.MINDBODY){
                try {
                    createdCredentials = await apiClient.post('/credentials',_credentials)
                } catch(err){
                    toast.error("Failed to create credentials, ensure fields are valid.");
                    console.error(err);
                    submitting(false);
                    return;
                }
                _provider.credentials = createdCredentials.id;
            }

            try {
                createdLocation = await apiClient.post('/locations',_location)
            } catch(err){
                toast.error("Failed to create location, ensure fields are valid.");
                console.error(err);
                // clean up dependencies 
                await apiClient.delete(`/credentials/${createdCredentials.id}`);
                submitting(false);
                return;
            }
            _provider.location = createdLocation.id;

            try {
                createdProvider = await apiClient.post('/providers',_provider)
            } catch(err){
                toast.error("Failed to create provider, ensure fields are valid.");
                console.error(err);
                // clean up dependencies 
                if(_.has(createdCredentials,'id')){
                    await apiClient.delete(`/credentials/${createdCredentials.id}`);
                }
                if(_.has(createdLocation,'id')){
                    await apiClient.delete(`/locations/${createdLocation.id}`);
                }
                submitting(false);
                return;
            }

            actions.setProvider(createdProvider);
            selectedStrategy === StrategyTypes.MINDBODY && actions.setCredentials(createdCredentials);
            actions.setLocation(createdLocation);

            actions.nextState();

        }

        submitting(false);
    }


    return (
        <Layout>
            <h4>Provider Onboarding</h4>
            <div className="mt-6">
                <Stepper/>
                <StateTransitionListener/>
            </div>
            <div className="upk-form mt-2 mb-3" style={{ backgroundColor: "white" }}>
                <div className="header">
                    Add Provider
               </div>
               <div className="body">
                    

                    <TextField 
                        id="name" 
                        marginTop
                        label="Name" 
                        fullWidth={true} 
                        onChange={handleProviderInput(`name`)} defaultValue={residualProvider('name')} 
                    />

                    <TextField 
                        id="logo-url" 
                        marginTop
                        label="Logo URL" 
                        fullWidth={true} 
                        onChange={handleProviderInput(`logoURL`)} defaultValue={residualProvider('logoURL')} 
                    />  

                    <TextField 
                        id="cover-image-url" 
                        marginTop
                        label="Cover Image URL" 
                        fullWidth={true} 
                        onChange={handleProviderInput(`coverImageURL`)} defaultValue={residualProvider('coverImageURL')} 
                    />

                    <TextField 
                        id="description" 
                        marginTop
                        label="Description" 
                        fullWidth={true} 
                        onChange={handleProviderInput(`description`)} defaultValue={residualProvider('description')} 
                    />  

                    <TextField
                        id="available-after-days"
                        marginTop
                        label="Available after days (0: Allow appts on same day, 1: allow appts on next day, 2: allow appts 2 days after)"
                        fullWidth={true}
                        onChange={handleProviderInput(`availableAfterDays`)} defaultValue={residualProvider('availableAfterDays')}
                    />
                
                    <div className="mt-3">
                        <h6 className="heading-small text-muted" style={{ marginLeft: 'auto', color: 'red'}}>The Following step will create the provider, ensure location, credential, and provider fields are correct.</h6>
                        <TextButton label='Previous' onClick={handlePrevious} style={{ marginRight: '1rem' }}/>
                        <PrimaryButton label='Next' onClick={handleSave} disabled={isSubmitting}/>
                    </div>
               </div>
            </div>
        </Layout>
    )
}

import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme';

const useStyles = makeStyles({
  confirmModal: {
      position: 'relative'
  },
  deletePopover: {
    border: '1px solid #EFF0F6',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    height: '47px',
    width: '183px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 18px',
    cursor: 'pointer',
    '& p': {
      color: colors.fuschia['100'],
      marginLeft: '7px',
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  closeBtn: {
    position: 'absolute',
    right: '.5rem',
    top: '.5rem'
  },
});

export default useStyles;

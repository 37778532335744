import { OnboardActions, OnboardActionTypes } from './types'
import OnboardingStateI, { initialOnboardingState } from './state';


export const onboardReducer = (state = initialOnboardingState, action: any): OnboardingStateI => {
  switch (action.type) {
    case OnboardActionTypes.PREV_STATE:
      return {
        ...state,
        statePointer: state.statePointer - 1,
      }
    case OnboardActionTypes.NEXT_STATE:
      return {
        ...state,
        statePointer: state.statePointer + 1,
      }
    case OnboardActionTypes.SET_STATE_POINTER:
      return {
        ...state,
        statePointer: action.statePointer,
      }
    case OnboardActionTypes.SET_STRATEGY:
      return {
        ...state,
        selectedStrategy: action.payload,
      }
    case OnboardActionTypes.SET_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      }
    case OnboardActionTypes.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      }
    case OnboardActionTypes.SET_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      }
    case OnboardActionTypes.SET_SETTLEMENT_ACCOUNT:
      return {
        ...state,
        location: {
          ...state.location,
          settlementAccount: action.payload,
        }
      }
    case OnboardActionTypes.SET_ROOM_IMPORT_STATUS:
      return {
        ...state,
        roomImportStatus: {
          imported: !!action.payload
        }
      }
    case OnboardActionTypes.SET_PROVIDER_SVC_ITEMS_STATUS:
      return {
        ...state,
        providerServiceItems: {
          created: !!action.payload
        }
      }
    case OnboardActionTypes.ABORT_ONBOARDING:
      return {
        ...state,
        statePointer: 0,
        selectedStrategy: '',
        credentials: {},
        location: {},
        provider: {},
        roomImportStatus: {
          imported: false
        }
      }
    default:
      return { ...state };
  }
};

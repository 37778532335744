import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MuiMenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Poppins',
    padding: '1rem',
    fontSize: '1rem',
    '&:focus': {
      borderRadius: '15px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  outerLabel: {
    fontFamily: 'Poppins',
    padding: '1rem',
    '&:focus': {
      borderRadius: '15px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  shrink: {
    display: 'none',
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: '1rem',
    margin: '-.5rem 0 0 1.5rem',
  },
}));

export default function SimpleSelect({
  label = '',
  outerLabel = false,
  helperText = '',
  style = {},
  listItems = [],
  ...props
}) {

  const classes = useStyles();
  const { value } = props;

  return (
    <FormControl style={{ width: '100%', borderRadius: '15px', backgroundColor: 'white', ...style }}>
      {outerLabel ? (
        <FormLabel style={{ fontFamily: 'Poppins', fontSize: '12px', margin: '-14px 0 3px 6px' }}>{label}</FormLabel>
      ) : (
        <InputLabel
          classes={{ root: classes.label, shrink: classes.shrink }}
          style={{ display: value && 'none', fontSize: '14px' }}>
          {label}
        </InputLabel>
      )}
      <Select
        classes={{ root: outerLabel ? classes.outerLabel : classes.root }}
        variant="outlined"
        id="demo-simple-select"
        style={{ borderRadius: '15px', marginTop: !label && '11px' }}
        value={value ?? 0}
        {...props}>
        {props.children}
      </Select>
      {helperText && (
        <FormHelperText style={{ fontFamily: 'Poppins', margin: helperText !== '' && '0 0 -12px 6px' }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export function MenuItem({ props }) {
  return (
    <MuiMenuItem style={{ fontFamily: 'Poppins' }} {...props}>
      {props.children}
    </MuiMenuItem>
  );
}

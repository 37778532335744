import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  formBlock: {
    borderRadius: '12px',
    padding: '11px 9px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& h5': {
      color: colors.grey[600],
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '600',
    },
    '& p': {
      color: colors.grey[500],
    },
  },
  selectedDates: {
    gap: '10px',
  },
  chip: {
    height: '28px',
    borderRadius: '4px',
    backgroundColor: 'rgba(203, 238, 234, 0.3)',
    color: '#348D84',
    fontWeight: 600,
    '& .MuiChip-deleteIcon': {
      color: '#348D84',
      width: '20px',
      height: '20px',
    },
  },
  chipDisabled: {
    height: '28px',
    borderRadius: '4px',
    backgroundColor: 'rgba(238, 203, 203, 0.3)',
    color: '#8f3333',
    fontWeight: 600,
    '& .MuiChip-deleteIcon': {
      color: '#8f3333',
      width: '20px',
      height: '20px',
    },
  }
});

export default useStyles;

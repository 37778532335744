import React, { useEffect, useState } from 'react';
import './index.css';

import Layout from '../../components/Layout';
import Stepper from '../../components/Stepper';
import _ from 'lodash';
import useDraft from '../../utils/useDraft';
import { TextField, Button } from '@material-ui/core';
import store, { actions } from '../../store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import steps from '../../store/onboarding/steps';
import { toast } from 'react-toastify';
import { StateTransitionListener } from './StateTransitionListener';
import { StrategyTypes } from '../../enums/Strategies';
import AbortOnboardingButton from '../../components/AbortOnboardingButton';
import { PrimaryButton, TextButton } from '../../components/Button';
import CreateMindbodyCredentials from './MindbodyCredentials';
import BoulevardConnect from './BoulevardConnect';
import SquareConnect from './SquareConnect';

function CreateCredentials() {

    const globalCredentials = useSelector((state: store) => state.onboarding.credentials);
    const selectedStrategy = useSelector((state: store) => state.onboarding.selectedStrategy);
    const globalProvider = useSelector((state: store) => state.onboarding.provider);

    const [
        credentials,
        setCredentials,
        residualCredentials,
        updateCredentials,
        handleCredentialsInput
    ] = useDraft({
        ...globalCredentials,
        providerExternalId: globalProvider.externalId
    });

    const [
        boulevardSettings,
        setBoulevardSettings,
        residualBoulevardSettings,
        updatedBoulevardSettigs,
        handleBoulevardSettingsInput
    ] = useDraft({
        businessId: ''
    });

    useEffect(()=>{
        if(selectedStrategy === StrategyTypes.BOULEVARD){
            actions.setProvider({ externalId: boulevardSettings.businessId, timezone: boulevardSettings.timezone });
        }
    },[boulevardSettings])

    const handleSave = () => {
        if(selectedStrategy === StrategyTypes.MINDBODY){
            const { providerExternalId } = credentials;

            if (providerExternalId && globalProvider.externalId !== providerExternalId) {
                actions.setProvider({ externalId: providerExternalId });
                delete credentials['providerExternalId'];
            }
            actions.setCredentials(credentials);
            actions.nextState();
        }
        else if (
            selectedStrategy === StrategyTypes.BOULEVARD
                ||
            selectedStrategy === StrategyTypes.SQUARE
        ) {
            actions.nextState();
        }
    }

    const handlePrevious = () => actions.prevState();

    switch (selectedStrategy) {
        case StrategyTypes.MINDBODY:
            return (
                <CreateMindbodyCredentials
                    getInitialValue={residualCredentials}
                    onValueUpdate={handleCredentialsInput}
                    handleAccountImport={handleSave}
                    handlePrevious={handlePrevious}
                />
            )
        case StrategyTypes.BOULEVARD:
                return (
                   <BoulevardConnect
                        getInitialValue={residualBoulevardSettings}
                        onValueUpdate={handleBoulevardSettingsInput}
                        handleNext={handleSave}
                        handlePrevious={handlePrevious}
                    />
                )
        case StrategyTypes.SQUARE:
            return (
                <SquareConnect
                    handleNext={handleSave}
                    handlePrevious={handlePrevious}
                />
            );
        default:
            return null;
    }

}

export default CreateCredentials;

import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Typography as MuiTypography,
  ButtonBase,
} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import _, { inRange, isNumber } from 'lodash';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditIcon from '@material-ui/icons/Edit';
import Layout from '../../components/Layout';
import Select from '../../components/Select';
import apiClient from '../../utils/apiClient';
import { Accordion, AccordionSummary } from './Accordion';
import useStyles from './index.styles';
import Radio from '../../components/Radio';
import TextField from '../../components/TextField';
import Snackbar from './../../components/Snackbar';
import ImageUploader from '../../components/ImageUploader';
import PriceField from '../../components/PriceField';
import BundleItemManagement from './BundleItemManagement';
import { PrimaryButton, TextButton, AddButton } from './../../components/Button';
import Typography from '../../components/Typography';
import PageSidebar from '../../components/PageSidebar';
import ServiceCategoryHeader from './ServiceCategoryHeader';
import EditServiceCategory from './EditServiceCategory';
import Switch from '../../components/Switch';
import { Grid } from '@mui/material';

const NEW_SVC_ITEM_NAME = 'New Service Item';
type ServiceItem = {
  description: string;
  descriptionTitle: string;
  id?: number;
  minUnits?: number;
  name: string;
  prepInfo?: string;
  priceInCents: number;
  priceTiers?: {
    createdAt: string;
    id: number;
    minUnits: number;
    priceInCents: number;
    priceTierId: number;
    serviceItemId: number;
    updatedAt: string;
  }[];
  redemptionPoints?: number;
  subCategory: string;
  termsInfo?: string;
  timeInMins: number;
  unitsDescriptor: string;
  unitsDescriptorIconURL: string;
  isNew?: boolean;
  isPricePerUnit?: boolean;
  display_order: number;
};
export type ServiceCategory = {
  iconURL: string;
  id: number;
  name: string;
  serviceItems: ServiceItem[];
};
function ServiceMenu() {
  const classes = useStyles();
  const [svcCategoryList, setSvcCategoryList] = useState<ServiceCategory[]>([]);
  const [svcCategory, setSvcCategory] = useState<ServiceCategory | null>(null);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [isEditCategory, setIsEditCategory] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('Botox');
  const [injectablestab, setInjectablesTab] = useState('Injectables');
  const [getsvccategory, setGetsvccategory] = useState<ServiceCategory | null>(null);  
  const [state, setState] = useState({
    isLoading: false,
  });
  const history = useHistory();
  useEffect(() => {
    (async () => {
      setState({
        ...state,
        isLoading: true,
      });
      const allPriceTiers = await apiClient.get('/price-tier');
      const allPriceTierIds = allPriceTiers.map(priceTier => priceTier.id)
      const [_svcCategoryList] = await Promise.all([apiClient.get(`/svc-category?priceTiersIds=${allPriceTierIds}`)]);
      setSvcCategoryList(_svcCategoryList);
      if (_svcCategoryList.length > 0) {
        setSvcCategory(_svcCategoryList[0]);
        setGetsvccategory(_svcCategoryList[0]);
      }
      setState({
        ...state,
        isLoading: false,
      });
    })();
  }, []);

  useEffect(() => {
    if(injectablestab === 'Injectables'){
      setSvcCategory({...getsvccategory,serviceItems : _.get(getsvccategory, 'serviceItems', []).filter((item) => item.subCategory === activeTab)});
    }
  },[getsvccategory,activeTab,injectablestab])

  const updateSvcCat = (attr, val) => {
    if (svcCategory) {
      const _svcCategory: any = _.cloneDeep(svcCategory);
      _.set(_svcCategory, attr, val);
      setSvcCategory(_svcCategory);
    }
  };
  const addSvcItem = () => {
    if (svcCategory) {
      let x = 0;
      svcCategory.serviceItems.map((val) => {
        if (val.display_order === null) {
          x++;
        }
      })
      if (x === svcCategory.serviceItems.length || svcCategory.serviceItems.length > x && x !== 0) {
        const svcItems = svcCategory.serviceItems || [];
        svcItems.push({
          isNew: true,
          name: NEW_SVC_ITEM_NAME,
          subCategory: injectablestab === 'Injectables' ? activeTab === 'Botox' ? 'Botox' : 'Filler' : '',
          unitsDescriptor: '',
          unitsDescriptorIconURL: '',
          priceInCents: 10000,
          isPricePerUnit: false,
          descriptionTitle: '',
          description: '',
          timeInMins: 10,
          display_order: null
        });
        updateSvcCat('serviceItems', svcItems);
        x = 0;
      } else {
        const svcItems = svcCategory.serviceItems || [];
        svcItems.push({
          isNew: true,
          name: NEW_SVC_ITEM_NAME,
          subCategory: injectablestab === 'Injectables' ? activeTab === 'Botox' ? 'Botox' : 'Filler' : '',
          unitsDescriptor: '',
          unitsDescriptorIconURL: '',
          priceInCents: 10000,
          isPricePerUnit: false,
          descriptionTitle: '',
          description: '',
          timeInMins: 10,
          display_order: svcItems.length + 1
        });
        updateSvcCat('serviceItems', svcItems);
        x = 0;
      }
    }
  };
  const handleSvcCatChange = (attr: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = e.target.value;
    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }
    updateSvcCat(attr, value);
  };
  const saveSvcItem = async (index: number) => {
    const svcItem = _.get(svcCategory, `serviceItems[${index}]`, null);
    if (svcItem) {
      svcItem.serviceCategoryId = svcCategory.id;
      if (svcItem.isNew) {
        try {
          const newSvcItem = await apiClient.post('/svc-item', svcItem);
          updateSvcCat(`serviceItems[${index}]`, newSvcItem);
          toast.success('Created service item');
        } catch (err) {
          toast.error(`Could not create service item: ${err.message}`);
        }
      } else {
        try {
          const model = _.cloneDeep(svcItem);
          if(model.redemptionPoints === ''){
            model.redemptionPoints = 0;
          }
          if (!model.termsInfo) {
            model.termsInfo = '';
          }
          if (!model.prepInfo) {
            model.prepInfo = '';
          }
          if (!model.subCategory) {
            model.subCategory = '';
          }
          const newSvcItem = await apiClient.put(`/svc-item/${svcItem.id}`, model);
          updateSvcCat(`serviceItems[${index}]`, newSvcItem);
          const _getsvcCategory: any = _.cloneDeep(getsvccategory);
          _.get(_getsvcCategory, 'serviceItems', []).map((item,index) => {
            if(item.id === newSvcItem.id){
              _.set(_getsvcCategory, `serviceItems[${index}]`, newSvcItem);
              setGetsvccategory(_getsvcCategory);
            }
          })
          toast.success('Saved service item');
        } catch (err) {
          toast.error(`Could not save service item: ${err.message}`);
        }
      }
    }
  };
  const removeSvcItem = async (index: number) => {
    const svcItem = _.get(svcCategory, `serviceItems[${index}]`, null);
    if (svcItem) {
      if (svcItem.id) {
        const newSvcItem = await apiClient.delete(`/svc-item/${svcItem.id}`, svcItem);
        setSnackbarMsg('Deleted Service Item');
      }
      const _svcCategory: any = _.cloneDeep(svcCategory);
      _svcCategory.serviceItems.splice(index, 1);
      let updateData = [];
      _svcCategory.serviceItems.map((val) => {  
        if(val.display_order > svcItem.display_order){  
          updateData.push( {...val,display_order : val.display_order - 1})
        }
        else{
          updateData.push(val)  
        } 
      })
      setSvcCategory({..._svcCategory,serviceItems : updateData});
      const _getsvcCategory: any = _.cloneDeep(getsvccategory);
      _.get(_getsvcCategory, 'serviceItems', []).map((item,index) => {
        if(item.id === svcItem.id){
          _getsvcCategory?.serviceItems?.splice(index,1);
        }
      })
      setGetsvccategory(_getsvcCategory)
    }
  };
  const selectCategory = (svcCategoryId: any) => {
    const _svcCategory = svcCategoryList.find((svcCat: any) => svcCat.id == svcCategoryId);
    setActiveTab('Botox') 
    setInjectablesTab(_svcCategory.name);
    setGetsvccategory(_svcCategory);
    setIsEditCategory(false);
    if(_svcCategory.name === 'Injectables'){
      setSvcCategory({..._svcCategory,serviceItems : _.get(_svcCategory, 'serviceItems', []).filter((item) => item.subCategory === activeTab)});
    }else{
      _svcCategory && setSvcCategory(_svcCategory);
    }
  };

  const changeDisplayOrder = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    event.preventDefault();
    const value: number = isNumber(event.target.value) ? event.target.value : null;
    const _svcCategory: any = _.cloneDeep(svcCategory);
    const handleDisplayOrderChange = (i, value) => _.set(_svcCategory, `serviceItems[${i}].display_order`, value);
    const existingValueIndex = svcCategory.serviceItems.findIndex(item => item.display_order === value);
    const selectedServiceItem = svcCategory.serviceItems[index];
    if (existingValueIndex !== -1) {
      svcCategory.serviceItems.map((item, i) => {
        const order = item.display_order;
        const isMinPrev = selectedServiceItem.display_order < value;
        const min = Math.min(selectedServiceItem.display_order, value);
        const max = Math.max(selectedServiceItem.display_order, value) + 1;
        if (order && selectedServiceItem.subCategory === item.subCategory && inRange(order, min, max)) {
          handleDisplayOrderChange(i, isMinPrev ? order - 1 : order + 1);
        }
      })
    }
    handleDisplayOrderChange(index, value);
    setSvcCategory(_svcCategory);
  }

  const onDisplayOrderSave = async () => {
    try {
      const model = _.cloneDeep(svcCategory);
      const svcItems = model.serviceItems
      const isAnyNew = svcItems.findIndex((svcItem) => svcItem.isNew);
      if (isAnyNew === -1) {
        await apiClient.post(`/svc-item/display-order/`, { newOrder: model.serviceItems });
        setSvcCategory(model);
        toast.success('Saved Display Order');
      } else {
        toast.error(`Please Save or Remove New Service Items`);
      }
    } catch (err) {
      toast.error(`Could not save display order: ${err.message}`);
    }
  }

  const tabServiceItem = (value) => {
    if (value === "Botox") {
      setActiveTab('Botox')
      setSvcCategory({...getsvccategory , serviceItems :  _.get(getsvccategory, 'serviceItems', []).filter((item) => item.subCategory === 'Botox')});
    } else {
      setActiveTab('Filler')
      setSvcCategory({...getsvccategory , serviceItems :  _.get(getsvccategory, 'serviceItems', []).filter((item) => item.subCategory === 'Filler')});
    }
  }
  const EditSvcCategoryForm = (
    <>
      <Snackbar
        onClose={() => setSnackbarMsg('')}
        message={snackbarMsg}
        autoHideDuration={1000}
        open={!!snackbarMsg}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      />
      <div className={classes.detailBlock}>
        <h6 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
          Service Items
        </h6>
        {
          injectablestab === 'Injectables' && (<div className={classes.tabContaniner} >
          <div className={classes.botoxSection} onClick={() => tabServiceItem('Botox')} style={{borderRight:'2px solid lightgrey', borderBottom: activeTab === "Botox" ? '2px solid black' : null }} >Botox</div>
          <div className={classes.botoxSection} onClick={() => tabServiceItem('Filler')} style={{borderBottom: activeTab === "Filler" ? '2px solid black' : null }} >Fillers</div>
         </div> )
        }
        {  _.get(svcCategory, 'serviceItems', []).map((svcItem: any, i: number) => {
              const path = `serviceItems[${i}]`;    
              const handleSvcItemChange = (attr) => handleSvcCatChange(`${path}.${attr}`);
              const getSvcItemAttr = (attr) => _.get(svcCategory, `${path}.${attr}`, '');
              const handleSwitchInput = (attr: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.checked;
                updateSvcCat(`${path}.${attr}`, value);
              };
          return ( 
            <>
            <Accordion  key={`service-item-${i}`}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header"  className={classes.serviceItem} >
                <MuiTypography className="item-title" variant="body2">
                  {svcItem.subCategory ? ` (${svcItem.subCategory}) ` : ''}
                  {svcItem.name}
                </MuiTypography>
                <Box display="flex" className="accordion-right-content" alignItems="center">
                  <MuiTypography className="item-mins" variant="body2">
                    {svcItem.timeInMins} min
                  </MuiTypography>
                 <Box onClick={(e) => e.stopPropagation()}>
                  <Select 
                    labelId="Display Order"
                    onChange={(event) => changeDisplayOrder(event, i)}
                    value={getSvcItemAttr('display_order')}>
                    {_.get(svcCategory, 'serviceItems', []).filter((item: any) => item.subCategory == svcItem.subCategory)
                      .map((item: any, itemInedx: number) => {
                        return <MenuItem style={{ fontFamily: 'Poppins' }} key={itemInedx + 1} value={itemInedx + 1}>
                          {itemInedx + 1}
                        </MenuItem>;
                      })}
                  </Select>
                 </Box>
                  <ButtonBase className={classes.btnEdit}>
                    <EditIcon fontSize="small" />
                  </ButtonBase>
                </Box>
              </AccordionSummary>
            <AccordionDetails >
                <div style={{ width: '100%' }}>
                  <TextField
                    marginTop
                    label="Name"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`name`)}
                    value={getSvcItemAttr('name')}
                  />
                  {
                    injectablestab === 'Injectables' ? activeTab === 'Botox' ? <TextField
                    marginTop
                    label="Name"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`subCategory`)}
                    value={getSvcItemAttr('subCategory')}
                    disabled
                  /> : <TextField
                  marginTop
                  label="Name"
                  fullWidth={true}
                  onChange={handleSvcItemChange(`subCategory`)}
                  value={getSvcItemAttr('subCategory')}
                  disabled
                />  : <Select
                labelId="service-subcategory-label"
                label="Sub Category"
                id="service-subcategory"
                value={getSvcItemAttr('subCategory') || ''}
                onChange={handleSvcItemChange(`subCategory`)}
                key="service-subcat-selection"
                style={{ marginTop: '1rem' }}>
                <MenuItem value="" key={'subcat-null'}>
                  None
                </MenuItem>
                <MenuItem value="Botox" key={'subcat-null'}>
                  Botox
                </MenuItem>
                <MenuItem value="Fillers" key={'subcat-null'}>
                  Fillers
                </MenuItem>
              </Select>
                  }
                  
                  {(['Botox'].includes(getSvcItemAttr('subCategory') || '') && (
                    <React.Fragment>
                      <TextField
                        marginTop
                        label="Units Descriptor"
                        fullWidth={true}
                        onChange={handleSvcItemChange(`unitsDescriptor`)}
                        value={getSvcItemAttr('unitsDescriptor')}
                      />
                      <ImageUploader
                        label="Units Descriptor Icon URL"
                        onChange={handleSvcItemChange(`unitsDescriptorIconURL`)}
                        value={getSvcItemAttr('unitsDescriptorIconURL')}
                      />
                    </React.Fragment>
                  )) ||
                    null}
                  <TextField
                    marginTop
                    label="Description Title"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`descriptionTitle`)}
                    value={getSvcItemAttr('descriptionTitle')}
                  />
                  <TextField
                    marginTop
                    label="Description"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`description`)}
                    value={getSvcItemAttr('description')}
                  />
                  <TextField
                    marginTop
                    label="Points Required to Redeem Item"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`redemptionPoints`)}
                    value={getSvcItemAttr('redemptionPoints') || ''}
                  />
                  <TextField
                    marginTop
                    label="Time in Minutes"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`timeInMins`)}
                    value={getSvcItemAttr('timeInMins')}
                  />
                  <TextField
                    marginTop
                    label="Terms Info"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`termsInfo`)}
                    value={getSvcItemAttr('termsInfo')}
                  />
                  <TextField
                    marginTop
                    label="Prep Info"
                    fullWidth={true}
                    onChange={handleSvcItemChange(`prepInfo`)}
                    value={getSvcItemAttr('prepInfo')}
                  />

                  <FormControlLabel
                    style={{ marginTop: '1rem'}}
                    control={
                      <Switch checked={getSvcItemAttr('isFinancingEnabled')} onChange={handleSwitchInput('isFinancingEnabled')} />
                    }
                    labelPlacement="start"
                    label={<Typography>Is Financing Enabled</Typography>}
                  />
               

                  <div style={{ marginTop: '2rem' }}>
                    <PrimaryButton label="Save" onClick={() => saveSvcItem(i)} />
                    <TextButton style={{ marginLeft: '1rem' }} label="Remove" onClick={() => removeSvcItem(i)} />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            </>
          );
        })}
        <Grid container direction="row" className="mt-2" justifyContent="space-between" alignItems="center">
          <Grid item>
            <AddButton label="+ Add New Service Item" onClick={addSvcItem} />
          </Grid>
          <Grid item>
            <PrimaryButton label="Change Display Order" onClick={onDisplayOrderSave} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.detailBlock}>
        <h6 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
          Bundles
        </h6>
        <BundleItemManagement serviceCategory={svcCategoryList} />
      </div>
    </>
  );
  const onToggleEditServiceItem = () => {
    setIsEditCategory(!isEditCategory);
  };
  return (
    <Layout hasContentSideBar>
      <Box display="flex" height="100%">
        <PageSidebar
          title="Service Menu"
          listTitle="Categories"
          addBtnLabel="+ Add Category"
          onClickAddBtn={() => history.push('/services/create')}>
          {svcCategoryList &&
            svcCategoryList.map((category) => (
              <Box
                key={category.id}
                className={`list-item ${category.id === svcCategory?.id ? 'active' : ''}`}
                onClick={() => selectCategory(category.id)}>
                <span>{category.name}</span>
              </Box>
            ))}
        </PageSidebar>
        <Box className={classes.content} flex={1}>
          {!state.isLoading && !svcCategoryList.length && (
            <h6 className="heading-small text-muted">No Service Categories Created</h6>
          )}
          {state.isLoading && <h6 className="heading-small text-muted">Loading Pricing Tiers...</h6>}
          <br />
          {svcCategory && (
            <>
              {isEditCategory ? (
                <EditServiceCategory category={svcCategory} onBack={onToggleEditServiceItem} />
              ) : (
                <>
                  <ServiceCategoryHeader category={svcCategory} categorylength={getsvccategory && getsvccategory.serviceItems.length} injectablestab={injectablestab} onEdit={onToggleEditServiceItem} />
                  {EditSvcCategoryForm}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
export default ServiceMenu;

import React from 'react';
import { Box, Typography, ButtonBase } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useStyles from './index.styles';
import { ServiceCategory } from '.';

type ServiceCategoryHeaderProps = {
  category: ServiceCategory;
  categorylength
  injectablestab
  onEdit: () => void;
};

const ServiceCategoryHeader = ({ category, onEdit ,injectablestab, categorylength}: ServiceCategoryHeaderProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerBox}>
      <Box className="image-wrapper">
        <img src={category.iconURL} alt={category.name} className="category-image" />
      </Box>
      <Box className="summary">
        <Typography variant="h3">{category.name}</Typography>
        <Typography>{ injectablestab === 'Injectables' ? categorylength : category.serviceItems.length } service items</Typography>
      </Box>
      <Box>
        <ButtonBase className={classes.btnEdit} onClick={onEdit} disabled>
          <EditIcon fontSize="small" />
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default ServiceCategoryHeader;

import { FormControlLabel, Fab, FormLabel, Checkbox, Grid } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import React, { ChangeEvent, Component } from "react";


interface PropsType {
    checked: boolean
    onChange: (e: ChangeEvent) => void
    value: any
}

interface StateType { }

// AddButtonSet uses a set of checkboxes styled to look like "add" buttons
export class AddButtonSet extends Component<PropsType, StateType> {

    style = {
        "lightGreen": "#FAFFFC",
        "darkGreen":"#348D84",
    }

    constructor(props: PropsType) {
        super(props);
    }

    render() {
        return (
            <Checkbox onChange={e => this.props.onChange(e)} checked={this.props.checked}
                icon={
                    <Fab
                        style={{ color: "gray", backgroundColor: "white", borderColor: "gray", padding: "15px", boxShadow: "none" }}
                        color="default" aria-label="add" variant="extended" size="small"
                    >
                        <Add fontSize="small" style={{ paddingRight: "5px" }} />
                        {this.props.value}
                    </Fab>
                }
                checkedIcon={
                    <Fab
                        style={{ color: this.style.darkGreen, backgroundColor: this.style.lightGreen, padding: "15px" }}
                        color="primary" aria-label="remove" variant="extended" size="small"
                    >

                        <Check fontSize="small" style={{ paddingRight: "5px" }} />
                        {this.props.value}
                    </Fab>
                }
            />
        )
    }
}

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Layout from '../../components/ProviderOnboarding/Layout';

import {
  getSettlementAccount,
  attachStripeAccount,
  initiateStripeOnboarding,
  retryStripeOnboarding,
  connectBankAccount,
} from '../../utils/settlementAccountHelpers';
import store, { actions } from '../../store';
import {
  ConnectBankAccount,
  StripeAccountCreation,
  OnboardingComplete,
} from '../../views/Onboarding';

const isDev = process.env.REACT_APP_NODE_ENV === 'development';

enum STEPS {
  STRIPE_ACCOUNT_ONBOARDING = 0,
  CONNECT_BANK_ACCOUNT = 1,
  ONBOARDING_COMPLETE = 2,
}

const defaultSteps = [{
  label: 'Create Stripe Account',
  completed: false,
}, {
  label: 'Connect Bank Account',
  completed: false,
}, {
  label: 'Complete',
  completed: false,
}];

const StripeOnboardingController = () => {
  const globalLocation = useSelector((state: store) => state.onboarding.location, _.isEqual);
  const isAdmin = useSelector((state:store)=>state.user.isAdmin);
  const settlementAccountConnected = !!globalLocation?.settlementAccount;
  const history = useHistory();
  const [stripeOnboardingComplete, setStripeOnboardingComplete] = useState(false);
  const [bankOnboardingComplete, setBankOnboardingComplete] = useState(false);
  const [steps, setSteps] = useState(defaultSteps);
  const [activeStep, setActiveStep] = useState(STEPS.STRIPE_ACCOUNT_ONBOARDING);
  const [isSubmitting, submitting] = useState(false);
  const shouldRetryBankAccOnboarding = () => settlementAccountConnected && !stripeOnboardingComplete;

  useEffect(() => {
    if (stripeOnboardingComplete && !steps[STEPS.STRIPE_ACCOUNT_ONBOARDING].completed) {
      markStepCompleted(STEPS.STRIPE_ACCOUNT_ONBOARDING);
    } else if (bankOnboardingComplete && !steps[STEPS.CONNECT_BANK_ACCOUNT].completed) {
      markStepCompleted(STEPS.CONNECT_BANK_ACCOUNT);
    }
  }, [globalLocation, stripeOnboardingComplete, bankOnboardingComplete]);

  useEffect(() => {
    const setStripeOnboardingStatus = async () => {
      if (!settlementAccountConnected && !isSubmitting) {
        return;
      }
      submitting(true);
      const settlementAccountDetails = await getSettlementAccount(globalLocation.settlementAccount);
      const remainingRequirements = settlementAccountDetails?.requirements?.currently_due;
      if (!_.isUndefined(remainingRequirements) && _.isEmpty(_.without(remainingRequirements, "external_account"))) {
        setStripeOnboardingComplete(true);
        setBankOnboardingComplete(!!settlementAccountDetails?.details_submitted);
      }
      submitting(false);
    };

    setStripeOnboardingStatus();
  }, [settlementAccountConnected]);

  const markStepCompleted = (index) => {
    setSteps(steps.map((step, i) => (i === index ? { ...step, completed: true } : step)));
    setActiveStep(index + 1);
  };

  const handleRetryStripeOnboarding = async () => {
    return await retryStripeOnboarding(globalLocation.settlementAccount, submitting);
  };

  const handleInitiateStripeOnboarding = async () => {
    return await initiateStripeOnboarding(globalLocation.id, submitting);
  }

  const handleAttachStripeAccount = async (settlementAccountId: string) => {
    const result = await attachStripeAccount(globalLocation.id, settlementAccountId, submitting);
    if (result) {
      if (isAdmin) {
        markStepCompleted(STEPS.STRIPE_ACCOUNT_ONBOARDING + 1); // skipping connect bank account for now
      } else {
        history.push('/provider/overview');
      }
    }
  }

  const handleConnectBankAccount = async (data) => {
    const result = await connectBankAccount(globalLocation.settlementAccount, data, submitting);
    if (result) {
      markStepCompleted(STEPS.CONNECT_BANK_ACCOUNT);
    }
  }

  const resetProviderOnboarding = () => {
    setSteps(defaultSteps);
    setActiveStep(0);
    actions.abortOnboarding();
    window.location.href = `${process.env.PUBLIC_URL}/providers`;
  }

  const gotoWebsite = () => {
    window.location.href = "https://www.upkeepbeauty.com/for-providers";
  };

  return (
    <Layout>
      {
        {
          [STEPS.STRIPE_ACCOUNT_ONBOARDING]:
            <StripeAccountCreation
              handleReset={(isAdmin || isDev) ? resetProviderOnboarding : undefined}
              handleClick={shouldRetryBankAccOnboarding() ? handleRetryStripeOnboarding : handleInitiateStripeOnboarding}
              handleAttachStripeAccount={handleAttachStripeAccount}
              loading={isSubmitting}
              retry={shouldRetryBankAccOnboarding()}
            />,
          [STEPS.CONNECT_BANK_ACCOUNT]:
            <ConnectBankAccount
              handleReset={(isAdmin || isDev) ? resetProviderOnboarding : undefined}
              handleSubmit={handleConnectBankAccount}
              loading={isSubmitting}
            />,
          [STEPS.ONBOARDING_COMPLETE]:
            <OnboardingComplete
              handleReset={(isAdmin || isDev) ? resetProviderOnboarding : undefined}
              gotoWebsite={gotoWebsite}
            />
        }[activeStep]
      }
    </Layout>
  );
}

export default StripeOnboardingController;

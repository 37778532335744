import { makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { colors } from '../../theme';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: 334,
    borderRight: `1px solid ${grey[200]}`,
    height: '100vh',
    '& .header-title': {
      color: colors.grey[600],
    },
    '& .title': {
      color: colors.grey[400],
    },
  },
  addButton: {
    height: 73,
    cursor: 'pointer',
    color: colors.grey[400],
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    '& .list-item': {
      padding: '21px 16px',
      cursor: 'pointer',
      borderRadius: 8,
      color: colors.grey[600],
      fontWeight: 600,
      position: 'relative',
      paddingRight: 40,
      '&:hover': {
        background: grey[100],
      },
      '&.active': {
        background: grey[100],
      },
    },
  },
}));

export default useStyles;

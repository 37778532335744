import Content from './Content';
import Sidebar from './Sidebar/Sidebar';
import React from 'react';

type LayoutProps = {
  children: React.ReactNode;
  hasContentSideBar?: boolean;
};

export default (props: LayoutProps) => {
  return (
    <div className="layout">
      <Sidebar hasContentSideBar={props.hasContentSideBar}>
        {props.hasContentSideBar ? props.children : <Content>{props.children}</Content>}
      </Sidebar>
    </div>
  );
};

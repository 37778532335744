import { Box, TextField, Tabs, Tab, Divider, Typography, Chip, CircularProgress, Button } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import React, { useCallback, useEffect, useState } from 'react';
import useSWR, { Fetcher } from 'swr';
import { useDebounce } from 'use-debounce';

import apiClient from '../../utils/apiClient';
import { PriceTier } from '.';
import useStyles from './TierForm.styles';
import ServiceItemField from './ServiceItemField';
import ServiceMapping from '../../components/PricingTier/ServiceMapping';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box pt={4}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export type ServiceItem = {
  description: string;
  descriptionTitle: string;
  id: number;
  name: string;
  prepInfo: string;
  redemptionPoints: number;
  serviceCategory: { id: number; name: string; iconURL: string };
  subCategory: string;
  termsInfo: string;
  timeInMins: number;
  unitsDescriptor: string;
  unitsDescriptorIconURL: string;
  priceInCents: number;
};

export type TierServiceItem = {
  id: number;
  serviceItemId?: number;
  priceInCents: number;
  isPricePerUnit: boolean;
  minUnits: number;
};

type TierFormProps = {
  loading: boolean;
  selectedPriceTier: PriceTier;
  onUpdateSuccess: () => void;
};

const TierForm = ({ loading, selectedPriceTier, onUpdateSuccess }: TierFormProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [serviceItems, setServiceItems] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [formData, setFormData] = useState<PriceTier>(selectedPriceTier);
  const [selectedPriceTierCities, setSelectedPriceTierCities] = useState([]);
  const [selectedServiceItems, setSelectedServiceItems] = useState<TierServiceItem[]>([]);
  const [tierTabValue, setTierTabValue] = useState(0);

  useEffect(() => {
    // Make service items for selected price tier unique array
    let uniqueServiceItems = [];
    selectedPriceTier.serviceItems.map((item) =>
      uniqueServiceItems.filter((x) => x.serviceItemId === item.serviceItemId).length > 0
        ? null
        : uniqueServiceItems.push(item),
    );

    setSelectedServiceItems(uniqueServiceItems);
  }, [selectedPriceTier]);

  useEffect(() => {
    const fetchServiceItems = async () => {
      try {
        const res = await apiClient.get('/svc-item');
        setServiceItems(res);
        let categories = [];

        res.map((item) => {
          if (!categories.find((category) => category === item.serviceCategory.name)) {
            categories = [...categories, item.serviceCategory.name];
          }
        });

        setServiceCategories(categories.sort());
      } catch (err) {
        console.log(err);
      }
    };

    fetchServiceItems();
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const onTierTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTierTabValue(newValue);
  };

  const updatePriceTier = async (body) => {
    try {
      await apiClient.put(`/price-tier/${selectedPriceTier.id}`, body);
      onUpdateSuccess();
    } catch (err) {
      console.log(err);
    }
  };

  const getAddressesWithPlaceIds = async (ids: string[] | null) => {
    let citiesArr = [];
    if (ids) {
      await Promise.all(
        ids.map(async (id) => {
          try {
            const res = await apiClient.get(`/price-tier/city-details?placeId=${id}`);
            citiesArr = [
              ...citiesArr,
              {
                description: res.result.formatted_address,
                place_id: id,
              },
            ];
          } catch (err) {
            console.log(err);
          }
        }),
      );
    }

    setSelectedPriceTierCities(citiesArr);
  };

  useEffect(() => {
    setSelectedPriceTierCities([]);
    setFormData(selectedPriceTier);
    getAddressesWithPlaceIds(selectedPriceTier.cityIds);
  }, [selectedPriceTier.id]);


  const onPriceTierNameChange = (e) => {
    const newTitle = e.target.value;
    setFormData({
      ...formData,
      "name": newTitle
    });
    updatePriceTier({
      name: newTitle
    });
  }

  const onChangeServiceItem = useCallback(
    (
      item: ServiceItem,
      updateBody: {
        isChecked: boolean;
        priceIncents: string;
        minUnits: string;
      },
    ) => {
      let arr = selectedServiceItems.map((item) => ({
        ...item,
        id: item.serviceItemId || item.id,
      }));

      const itemToUpdate: TierServiceItem = {
        id: item.id,
        serviceItemId: item.id,
        priceInCents: parseFloat(updateBody.priceIncents) * 100,
        isPricePerUnit: true,
        minUnits: parseInt(updateBody.minUnits, 10),
      };

      if (updateBody.isChecked) {
        const indexToUpdate = arr.findIndex((x) => x.id === itemToUpdate.id);
        if (indexToUpdate > -1) {
          arr[indexToUpdate] = {
            ...arr[indexToUpdate],
            priceInCents: itemToUpdate.priceInCents,
            minUnits: itemToUpdate.minUnits,
          };
        } else {
          arr = [...arr, itemToUpdate];
        }
      } else {
        arr = arr.filter((x) => x.id !== item.id);
      }

      setSelectedServiceItems(arr);
    },
    [selectedPriceTier, selectedServiceItems],
  );

  const onUpdateServiceItems = () => {
    updatePriceTier({
      serviceItems: selectedServiceItems,
    });
  };

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formBlock}>
        <TextField
          placeholder="Untitled"
          fullWidth
          error={formData.name === ''}
          value={formData.name}
          onBlur={onPriceTierNameChange}
          helperText={formData.name === '' ? 'Incorrect entry.' : ''}
          name="name"
          label="Name"
          classes={{ root: classes.inputName }}></TextField>
        <TextField
          label="Description"
          placeholder="Entter your description here"
          fullWidth
          classes={{ root: classes.inputDescription }}></TextField>
      </Box>

      <Box className={classes.formBlock}>
        <Tabs value={tierTabValue} indicatorColor="primary" textColor="primary" onChange={onTierTabChange}>
          <Tab label="Menu" />
          <Tab label="Map" />
        </Tabs>
        <TabPanel value={tierTabValue} index={0}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h5" gutterBottom>
                Menu Pricing
              </Typography>
              <Typography>Set up your pricing models for each of the menu categories. </Typography>
            </Box>

            <Button variant="contained" color="primary" onClick={onUpdateServiceItems} style={{ color: '#fff' }}>
              Update
            </Button>
          </Box>

          {serviceCategories.length > 0 && (
            <Box style={{ position: 'relative' }}>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="simple tabs example"
                classes={{ scroller: classes.tabsScroller }}>
                {serviceCategories.map((category, index) => (
                  <Tab label={category} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
              {serviceCategories.map((category, index) => (
                <TabPanel value={value} index={index} key={index}>
                  <Box>
                    {serviceItems.map(
                      (item) =>
                        item.serviceCategory.name === category && (
                          <ServiceItemField
                            item={item}
                            selectedServiceItems={selectedServiceItems}
                            key={item.id}
                            onChangeServiceItem={onChangeServiceItem}
                          />
                        ),
                    )}
                  </Box>
                </TabPanel>
              ))}
              {loading && (
                <Box className={classes.loader}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          )}
        </TabPanel>
        <TabPanel value={tierTabValue} index={1}>
          <ServiceMapping tierId={selectedPriceTier.id} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default TierForm;

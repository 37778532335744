import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { FormControlLabel, FormControl, InputLabel, MenuItem, withStyles, FormLabel, FormGroup, Checkbox, Grid } from '@material-ui/core';
import TextField from './TextField';
import Select from './Select';
import moment from 'moment';
import apiClient from '../utils/apiClient';
import { toast } from 'react-toastify';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { concatString } from '../utils';
import Modal from './Modal';
import { PrimaryButton, AddButton } from './Button';
import { Accordion, AccordionSummary } from './Accordion';

function intersection(a: number[], b: number[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

enum ItemType {
    BUNDLE = "BUNDLE",
    SERVICE_ITEM = "SERVICE_ITEM"
}

interface ProviderServiceItemI {
    externalId: number | null;
    serviceItemId: number | null;
    bundleId?: number | null;
    id: number | null;
    locationId?: number | null;
    category?: string | null;
    key?: string | null;
}

interface ProductI {
    id: number;
    category:string;
    name: string;
    type: ItemType;
}

export default function ProviderServiceItemMapping(
    { providerId, locationId, onChange = () => { } }:
        { providerId: number, locationId: number, onChange?: any }) {

    const [state, setState] = useState<{ isLoading: boolean }>({
        isLoading: true
    })
    const [externalServiceItems, setExternalServiceItems] = useState([]);

    // service items + bundles
    const [products, setProducts] = useState<Array<ProductI>>([]);

    const [providerServiceItems, setProviderServiceItems] = useState<Array<ProviderServiceItemI>>([]);

    // bulk edit mappings
    const [openModal, setOpenModal] = useState(false);

    // bulk edit mappings - selected upk service items
    const [checkedItems, setCheckedItems] = useState([]);

    // bulk edit mappings - selected provider service item
    const [selectedProviderItem, setSelectedProviderItem] = useState();

    useEffect(() => {
        (async () => {

            if (!providerId || !locationId) return;

            let upkServiceItems = [];
            let upkProducts = [];

            const [
                providerServiceItems,
                externalServiceItems,
                serviceCategories,
                bundles
            ] = await Promise.all([
                apiClient.get(`/providers/svc-items`),
                apiClient.get(`/strategies/fetch/svc-items`, { provider: providerId }),
                apiClient.get('/svc-category', { locationId }),
                apiClient.get(`/bundle`)
            ])

            serviceCategories.forEach((svcCat) => {
                if (svcCat.serviceItems) {
                    svcCat.serviceItems.forEach((svcItem) => {
                        svcItem.displayName = concatString([svcCat.name,svcItem.subCategory,svcItem.name]);
                        upkServiceItems.push(svcItem);
                        upkProducts.push({
                            name: svcItem.displayName,
                            category: svcCat.name,
                            id: svcItem.id,
                            type: ItemType.SERVICE_ITEM
                        })
                    })
                }
            });

            bundles.forEach(bundle => {
                upkProducts.push({
                    name: bundle.name,
                    id: bundle.id,
                    type: ItemType.BUNDLE
                })
            });

            setExternalServiceItems(externalServiceItems);
            console.log('Setting products', upkProducts);
            setProducts(upkProducts);
            setProviderServiceItems(providerServiceItems.filter((pSI) => pSI.locationId === locationId && (pSI.serviceItemId || pSI.bundleId)));
            setState({
                ...state,
                isLoading: false
            })
        })();

    }, [locationId, providerId]);

    useEffect(() => {
        onChange(providerServiceItems);
    }, [providerServiceItems])

    const handleAddProviderSvcItem = (category, index) => {
        setProviderServiceItems([
            ...providerServiceItems,
            {
                id: null,
                externalId: null,
                serviceItemId: null,
                category,
                key: `${index}-${category}`
            }
        ])
    }

    const removeProviderSvcItem = (item) => () => {
        let index = 0;
        providerServiceItems.forEach((svcItem, i) => {
            const identifier = svcItem?.id ? svcItem.id : svcItem.key
            if (identifier === item?.key || identifier === item?.id) index = i;
        })

        setProviderServiceItems(providerServiceItems.filter((providerSvcItem, i) => i !== index));
    }

    const updateProviderServiceItem = (index, updatedProviderServiceItem) => {
        const indexWithinBounds = typeof index === 'number' && index >= 0 && index < providerServiceItems.length;

        if (indexWithinBounds && typeof updatedProviderServiceItem === 'object' && updatedProviderServiceItem) {
            setProviderServiceItems(providerServiceItems.map((pSvcItem, i) => {
                return {
                    ...pSvcItem,
                    ...(index === i ? updatedProviderServiceItem : {}),
                };
            }))
        }
    }


    const handleProductSelection = (item) => (e,product: { id: number, name: string, type: ItemType}) => {
        let index = 0;
        providerServiceItems.forEach((svcItem, i) => {
            const identifier = svcItem?.id ? svcItem.id : svcItem.key
            if (identifier === item?.key || identifier === item?.id) index = i;
        })

        if(!product?.type){
            return;
        }
        else if(product?.type === ItemType.BUNDLE){

            updateProviderServiceItem(index, {
                serviceItemId: null,
                bundleId: product.id
            })
       }
       else {
        updateProviderServiceItem(index, {
            serviceItemId: product.id,
            bundleId: null
        })
       }
    }

    const handleExternalServiceItemSelection = (item: ProviderServiceItemI) => (e) => {

        const selectedExternalIds = providerServiceItems.map(({ externalId }) => Number(externalId));
        const externalId = e.target.value;

        let index = 0;
        providerServiceItems.forEach((svcItem, i) => {
            const identifier = svcItem?.id ? svcItem.id : svcItem.key
            if (identifier === item?.key || identifier === item?.id) index = i;
        })

        updateProviderServiceItem(index, {
            externalId
        })
    }

    const handleProviderServiceItemSave = (item) => async (e) => {

        let index = 0;
        providerServiceItems.forEach((svcItem, i) => {
            const identifier = svcItem?.id ? svcItem.id : svcItem.key
            if (identifier === item?.key || identifier === item?.id) index = i;
        })

        const providerSvcItem = providerServiceItems[index];
        const previousProviderServiceItems = _.cloneDeep(providerServiceItems);
        previousProviderServiceItems.splice(index, 1);
        const duplicateProviderSvcItemIndex = previousProviderServiceItems.findIndex(svcItem => {
            if(svcItem.serviceItemId){
                return svcItem.serviceItemId === providerSvcItem.serviceItemId
            } else if(svcItem.bundleId){
                return svcItem.bundleId === providerSvcItem.bundleId
            }
            return false;
        });
        if(duplicateProviderSvcItemIndex !== -1){
            toast.error("Failed to save: Mapping for service_item already exists");
            return
        }

        if (providerSvcItem.id === null) {
            try {
                const updatedProviderSvcItem = await apiClient.post(`/providers/svc-items`, {
                    externalId: providerSvcItem.externalId,
                    location: locationId,
                    locationId,
                    serviceItem: providerSvcItem.serviceItemId,
                    serviceItemId: providerSvcItem.serviceItemId,
                    bundleId: providerSvcItem.bundleId
                })
                updateProviderServiceItem(index, updatedProviderSvcItem);
                toast.success('Created Mapping');
            } catch(err){
                toast.error(err.message);
                console.error(err);
            }
        }
        else {
            try {
                const updatedProviderSvcItem = await apiClient.put(`/providers/svc-items/${providerSvcItem.id}`, {
                    externalId: providerSvcItem.externalId,
                    locationId,
                    serviceItemId: providerSvcItem.serviceItemId,
                    bundleId: providerSvcItem.bundleId
                });
                updateProviderServiceItem(index, updatedProviderSvcItem);
                toast.success('Updated Mapping');
            } catch(err){
                toast.error(err.message);
                console.error(err);
            }
        }
    }

    const handleProviderServiceitemDelete = (item) => async (e) => {
        let index = 0;
        providerServiceItems.forEach((svcItem, i) => {
            const identifier = svcItem?.id ? svcItem.id : svcItem.key
            if (identifier === item?.key || identifier === item?.id) index = i;
        })

        const remove = removeProviderSvcItem(item);
        const providerSvcItem = providerServiceItems[index];
        if (typeof providerSvcItem.id === 'number') {
            await apiClient.delete(`/providers/svc-items/${providerSvcItem.id}`);
            remove();
        }
    }

    const getProductFromProviderServiceItem = (providerSvcItem: ProviderServiceItemI) => {
        const type = providerSvcItem.bundleId ? ItemType.BUNDLE : ItemType.SERVICE_ITEM;
        const id = type === ItemType.BUNDLE ? providerSvcItem.bundleId : providerSvcItem.serviceItemId;
        const product = products.find(p => {
            return p.type === type && p.id === id
        })
        return product;
    }

    const withCategory = providerServiceItems.map(item => {
        return {...item, category: !item.category ? (item.bundleId ? ItemType.BUNDLE : getProductFromProviderServiceItem(item)?.category) : item.category }
    });

    const handleUpdateCheckedItems = (event) => {
        products.forEach(product => {
            if (product.name === event.target.name && event.target.checked === true) setCheckedItems([ ...checkedItems, product ]);
            else if (product.name === event.target.name && event.target.checked === false) setCheckedItems(checkedItems.filter(item => item !== product));
        })
    };

    const handleChangeProviderItem = (event) => {
        setSelectedProviderItem(event.target.value);
    };

    const addSingleBulkItem = (product) => {
        let tempProviderServiceItems = providerServiceItems;

        if (product.type === ItemType.BUNDLE){
            tempProviderServiceItems.push({
                id: null,
                externalId: selectedProviderItem,
                serviceItemId: null,
                bundleId: product.id
            });
        }
        else {
            tempProviderServiceItems.push({
                id: null,
                externalId: selectedProviderItem,
                serviceItemId: product.id,
                bundleId: null
            });
        }
        setProviderServiceItems(tempProviderServiceItems);
    };

    const handleSaveBulkEdit = () => {
        const checkedSvcIds = checkedItems.filter(p=>p.type===ItemType.SERVICE_ITEM).map(p=>p.id);
        const checkedBundleIds = checkedItems.filter(p=>p.type===ItemType.BUNDLE).map(p=>p.id);
        const duplicateProviderSvcItemIndex = providerServiceItems.findIndex(svcItem => {
            if(svcItem.serviceItemId){
                return checkedSvcIds.includes(svcItem.serviceItemId)
            } else if(svcItem.bundleId){
                return checkedBundleIds.includes(svcItem.bundleId)
            }
            return false;
        });
        if(duplicateProviderSvcItemIndex !== -1){
            toast.error("Failed to save: Mapping for service_item already exists");
            return
        }
        checkedItems.forEach(async product => {
            addSingleBulkItem(product);
            const index = providerServiceItems.length - 1;
            const providerSvcItem = providerServiceItems[index];
            try {
                const updatedProviderSvcItem = await apiClient.post(`/providers/svc-items`, {
                    externalId: String(providerSvcItem.externalId),
                    location: locationId,
                    locationId,
                    serviceItem: providerSvcItem.serviceItemId,
                    serviceItemId: providerSvcItem.serviceItemId,
                    bundleId: providerSvcItem.bundleId
                });

                updateProviderServiceItem(index, updatedProviderSvcItem);
            } catch(err){
                toast.error(err.message);
                console.error(err);
            }
        })
        toast.success('Created Mapping');
        setOpenModal(false);
    };

    useEffect(() => {
        if (openModal === false) {
            setCheckedItems([]);
            setSelectedProviderItem(null);
        }
    }, [openModal])

    return (
        <div className="provider-service-item-mapping">
            { !state.isLoading && (
                <>
                    <div style={{textAlign: 'right', margin: '-3.5rem 0 1.5rem 0'}}>
                        <PrimaryButton label='Bulk Edit Mappings' onClick={() => setOpenModal(true)}/>
                    </div>
                    <Modal title="Associate Service Items" open={openModal} onChange={() => setOpenModal(!openModal)}>
                        <Grid container style={{padding: '0 1.5rem'}}>
                            <Grid item xs={6} style={{padding: '1rem', height: '400px', overflow: 'scroll'}}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Assign Upkeep Service Items to Provider Service Item</FormLabel>
                                    <FormGroup>
                                    {
                                        products.map(item =>
                                            <FormControlLabel
                                                key={`${item.type}-${item.id}`}
                                                control={<Checkbox onChange={handleUpdateCheckedItems} name={item.name} />}
                                                label={item.name}
                                            />
                                        )
                                    }
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{}}>
                                <Select
                                    labelId="provider-service-item"
                                    id="provider-service-item"
                                    label="Provider Service Item"
                                    value={selectedProviderItem}
                                    onChange={handleChangeProviderItem}
                                    style={{ minWidth: '150px' }}
                                >
                                    {
                                        externalServiceItems.map((externalSvcItem, i) => <MenuItem style={{fontFamily: 'Poppins'}} key={`item-${i}`} value={externalSvcItem.externalId}>{externalSvcItem.name}</MenuItem>)
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                        <PrimaryButton label='Apply' style={{margin: '1rem 0 2rem', alignSelf: 'center', width: '250px'}} onClick={handleSaveBulkEdit}/>
                    </Modal>

                    {
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Facials
                            </AccordionSummary>

                            {
                                withCategory.length && (
                                    withCategory
                                        .filter(item => item.category === "Facials")
                                        .map((providerSvcItem, index) => {
                                            return (
                                                <div className="svc-mapping-row mb-1" key={`provider-service-item-${index}`} >
                                                    <Autocomplete
                                                        style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}
                                                        options={products.filter(item => item.category === "Facials" || item.type === ItemType.BUNDLE)}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={handleProductSelection(providerSvcItem)}
                                                        value={getProductFromProviderServiceItem(providerSvcItem)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Upkeep Service Item" />
                                                        )}
                                                    />
                                                    <TrendingFlatIcon color="action" style={{ width: '100px', fontSize: 40 }} />
                                                    <div style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}>
                                                        <Select
                                                            labelId="provider-service-item"
                                                            id="provider-service-item"
                                                            label='Provider Service Item'
                                                            value={providerSvcItem.externalId}
                                                            onChange={handleExternalServiceItemSelection(providerSvcItem)}
                                                            style={{ minWidth: '150px' }}
                                                        >
                                                            {
                                                                externalServiceItems.map((externalSvcItem, i) => <MenuItem style={{fontFamily: 'Poppins'}} key={`item-${i}`} value={externalSvcItem.externalId}>{externalSvcItem.name}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <IconButton aria-label="save" style={{ marginLeft: '1rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceItemSave(providerSvcItem)}>
                                                            <CheckCircleIcon />
                                                        </IconButton>
                                                        {
                                                            providerSvcItem.id === null ? (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={removeProviderSvcItem(providerSvcItem)}>
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceitemDelete(providerSvcItem)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        }
                                    )
                                ) || null
                            }
                            <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                                <AddButton label='+ Add Mapping' onClick={() => handleAddProviderSvcItem("Facials", (withCategory.filter(item => item.category === "Facials")).length)}/>
                            </div>
                        </Accordion>

                    }

                    {
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            Injectables
                            </AccordionSummary>

                            {
                                withCategory.length && (
                                    withCategory
                                        .filter(item => item.category === "Injectables")
                                        .map((providerSvcItem, index) => {
                                            return (
                                                <div className="svc-mapping-row mb-1" key={`provider-service-item-${index}`}>
                                                    <Autocomplete
                                                        style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}
                                                        options={products.filter(item => item.category === "Injectables" || item.type === ItemType.BUNDLE)}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={handleProductSelection(providerSvcItem)}
                                                        value={getProductFromProviderServiceItem(providerSvcItem)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Upkeep Service Item"
                                                            />
                                                        )}
                                                    />
                                                    <TrendingFlatIcon color="action" style={{ position: 'relative', width: '100px', fontSize: 40 }} />
                                                    <div style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}>
                                                        <Select
                                                            labelId="provider-service-item"
                                                            label="Provider Service Item"
                                                            id="provider-service-item"
                                                            value={providerSvcItem.externalId}
                                                            onChange={handleExternalServiceItemSelection(providerSvcItem)}
                                                        >
                                                            {
                                                                externalServiceItems.map((externalSvcItem, i) => <MenuItem style={{fontFamily: 'Poppins'}} key={`item-${i}`} value={externalSvcItem.externalId}>{externalSvcItem.name}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <IconButton aria-label="save" style={{ marginLeft: '1rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceItemSave(providerSvcItem)}>
                                                            <CheckCircleIcon />
                                                        </IconButton>
                                                        {
                                                            providerSvcItem.id === null ? (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={removeProviderSvcItem(providerSvcItem)}>
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceitemDelete(providerSvcItem)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                ) || null
                            }
                            <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                                <AddButton label='+ Add Mapping' onClick={() => handleAddProviderSvcItem("Injectables", (withCategory.filter(item => item.category === "Facials")).length + (withCategory.filter(item => item.category === "Injectables")).length)} />
                            </div>
                        </Accordion>
                    }
                    {
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Laser
                            </AccordionSummary>

                            {
                                withCategory.length && (
                                    withCategory
                                        .filter(item => item.category === "Hair Removal")
                                        .map((providerSvcItem, index) => {
                                            return (
                                                <div className="svc-mapping-row mb-1" key={`provider-service-item-${index}`} >
                                                    <Autocomplete
                                                        style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}
                                                        options={products.filter(item => item.category === "Hair Removal" || item.type === ItemType.BUNDLE)}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={handleProductSelection(providerSvcItem)}
                                                        value={getProductFromProviderServiceItem(providerSvcItem)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Upkeep Service Item"
                                                            />
                                                        )}
                                                    />
                                                    <TrendingFlatIcon color="action" style={{ position: 'relative', width: '100px', fontSize: 40 }} />
                                                    <div style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}>
                                                        <Select
                                                            labelId="provider-service-item"
                                                            label="Provider Service Item"
                                                            id="provider-service-item"
                                                            value={providerSvcItem.externalId}
                                                            onChange={handleExternalServiceItemSelection(providerSvcItem)}
                                                            style={{ minWidth: '150px' }}
                                                        >
                                                            {
                                                                externalServiceItems.map((externalSvcItem, i) => <MenuItem style={{fontFamily: 'Poppins'}} key={`item-${i}`} value={externalSvcItem.externalId}>{externalSvcItem.name}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <IconButton aria-label="save" style={{ marginLeft: '1rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceItemSave(providerSvcItem)}>
                                                            <CheckCircleIcon />
                                                        </IconButton>
                                                        {
                                                            providerSvcItem.id === null ? (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={removeProviderSvcItem(providerSvcItem)}>
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceitemDelete(providerSvcItem)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                ) || null
                            }
                            <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                                <AddButton label='+ Add Mapping' onClick={() => handleAddProviderSvcItem("Hair Removal", (withCategory.filter(item => item.category === "Facials")).length + (withCategory.filter(item => item.category === "Injectables")).length + (withCategory.filter(item => item.category === "Hair Removal")).length)} />
                            </div>
                        </Accordion>
                    }

                    {
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Body Contour
                            </AccordionSummary>
                            {
                                withCategory.length && (
                                    withCategory
                                        .filter(item => item.category === "Body Contour")
                                        .map((providerSvcItem, index) => {
                                            return (
                                                <div className="svc-mapping-row mb-1" key={`provider-service-item-${index}`} >
                                                    <Autocomplete
                                                        style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}
                                                        options={products.filter(item => item.category === "Body Contour" || item.type === ItemType.BUNDLE)}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={handleProductSelection(providerSvcItem)}
                                                        value={getProductFromProviderServiceItem(providerSvcItem)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Upkeep Service Item"
                                                            />
                                                        )}
                                                    />
                                                    <TrendingFlatIcon color="action" style={{ position: 'relative', width: '100px', fontSize: 40 }} />
                                                    <div style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}>
                                                        <Select
                                                            labelId="provider-service-item"
                                                            label="Provider Service Item"
                                                            id="provider-service-item"
                                                            value={providerSvcItem.externalId}
                                                            onChange={handleExternalServiceItemSelection(providerSvcItem)}
                                                            style={{ minWidth: '150px' }}
                                                        >
                                                            {
                                                                externalServiceItems.map((externalSvcItem, i) => <MenuItem style={{fontFamily: 'Poppins'}} key={`item-${i}`} value={externalSvcItem.externalId}>{externalSvcItem.name}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <IconButton aria-label="save" style={{ marginLeft: '1rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceItemSave(providerSvcItem)}>
                                                            <CheckCircleIcon />
                                                        </IconButton>
                                                        {
                                                            providerSvcItem.id === null ? (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={removeProviderSvcItem(providerSvcItem)}>
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceitemDelete(providerSvcItem)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                ) || null
                            }
                            <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                                <AddButton label='+ Add Mapping' onClick={() => handleAddProviderSvcItem("Body Contour", (withCategory.filter(item => item.category === "Facials")).length + (withCategory.filter(item => item.category === "Injectables")).length + (withCategory.filter(item => item.category === "Hair Removal")).length + (withCategory.filter(item => item.category === "Body Contour")).length)}/>
                            </div>
                        </Accordion>
                    }

                    {
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Bundles
                            </AccordionSummary>

                            {
                                withCategory.length && (
                                    withCategory
                                        .filter(item => item.category === ItemType.BUNDLE)
                                        .map((providerSvcItem, index) => {
                                            return (
                                                <div className="svc-mapping-row mb-1" key={`provider-service-item-${index}`} >
                                                    <Autocomplete
                                                        style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}
                                                        options={products.filter(item => item.type === ItemType.BUNDLE)}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={handleProductSelection(providerSvcItem)}
                                                        value={getProductFromProviderServiceItem(providerSvcItem)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Upkeep Service Item"
                                                            />
                                                        )}
                                                    />
                                                    <TrendingFlatIcon color="action" style={{ position: 'relative', width: '100px', fontSize: 40 }} />
                                                    <div style={{ width: '100%', maxWidth: '300px', minWidth: '150px' }}>
                                                        <Select
                                                            labelId="provider-service-item"
                                                            label="Provider Service Item"
                                                            id="provider-service-item"
                                                            value={providerSvcItem.externalId}
                                                            onChange={handleExternalServiceItemSelection(providerSvcItem)}
                                                            style={{ minWidth: '150px' }}
                                                        >
                                                            {
                                                                externalServiceItems.map((externalSvcItem, i) => <MenuItem style={{fontFamily: 'Poppins'}} key={`item-${i}`} value={externalSvcItem.externalId}>{externalSvcItem.name}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <IconButton aria-label="save" style={{ marginLeft: '1rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceItemSave(providerSvcItem)}>
                                                            <CheckCircleIcon />
                                                        </IconButton>
                                                        {
                                                            providerSvcItem.id === null ? (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={removeProviderSvcItem(providerSvcItem)}>
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', left: '1rem' }} onClick={handleProviderServiceitemDelete(providerSvcItem)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                ) || null
                            }
                            <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                                <AddButton label='+ Add Mapping' onClick={() => handleAddProviderSvcItem(ItemType.BUNDLE, providerServiceItems.length)}/>
                            </div>
                        </Accordion>
                    }
                </> ) || (
                    <div style={{ textAlign: 'center' }}>
                        <p>Loading Service Item Mappings...</p>
                    </div>
                )
            }

            {/*
                providerServiceItems.length && (
                    providerServiceItems.map((providerSvcItem, index) => {

                        //console.log(providerSvcItem)
                        return (
                            <div className="svc-mapping-row shadow mb-1" key={`provider-service-item-${index}`}>
                                <Autocomplete
                                        style={{ width: '400px' }}
                                        options={products}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleProductSelection(index)}
                                        value={getProductFromProviderServiceItem(providerSvcItem)}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params.InputProps}
                                            variant="standard"
                                            label=""
                                            placeholder="Upkeep Service Item"
                                        />
                                        )}
                                    />
                                <TrendingFlatIcon color="action" style={{ position: 'relative', bottom: '-0.5rem', width: '100px', fontSize: 40 }} />
                                <FormControl style={{ width: '400px' }}>
                                    <InputLabel id="provider-service-item-label">Provider Service Item</InputLabel>
                                    <Select
                                        labelId="provider-service-item"
                                        id="provider-service-item"
                                        value={providerSvcItem.externalId}
                                        onChange={handleExternalServiceItemSelection(index)}
                                    >
                                        {
                                            externalServiceItems.map((externalSvcItem, i) => {
                                                return (
                                                    <MenuItem key={`external-svc-item-${index}-${i}`} value={externalSvcItem.externalId}>{externalSvcItem.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <IconButton aria-label="save" style={{ marginLeft: '1rem', position: 'relative', bottom: '-0.5rem', left: '1rem' }} onClick={handleProviderServiceItemSave(index)}>
                                    <CheckCircleIcon />
                                </IconButton>
                                {
                                    providerSvcItem.id === null ? (
                                        <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', bottom: '-0.5rem', left: '1rem' }} onClick={removeProviderSvcItem(index)}>
                                            <CancelIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton aria-label="delete" style={{ marginLeft: '0rem', position: 'relative', bottom: '-0.5rem', left: '1rem' }} onClick={handleProviderServiceitemDelete(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )
                                }
                            </div>
                        )
                    })
                ) || null
                */}

                {/*<div style={{ textAlign: 'center' }}>
                {
                    !state.isLoading && (
                        <IconButton aria-label="add" onClick={handleAddProviderSvcItem} color="primary">
                            <AddCircleIcon fontSize="large" />
                        </IconButton>
                    ) || (
                        <p>Loading Service Item Mappings...</p>
                    )
                }
            </div>*/}
        </div>
    )
}

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import AppState from './../';
import { OnboardActionTypes } from './types';

export const nextState = (): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.NEXT_STATE
    });
}

export const prevState = (): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.PREV_STATE
    });
}

export const setStatePointer = (statePointer: number): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_STATE_POINTER,
        statePointer
    });
}

export const setStrategy = (payload): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_STRATEGY,
        payload
    });
}

export const setCredentials = (payload): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_CREDENTIALS,
        payload
    });
}

export const setLocation = (payload): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_LOCATION,
        payload
    });
}

export const setProvider = (payload): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_PROVIDER,
        payload
    });
}

export const setSettlementAccount = (payload): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_SETTLEMENT_ACCOUNT,
        payload
    });
}

export const setRoomImportStatus = (payload): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_ROOM_IMPORT_STATUS,
        payload
    });
}

export const setProviderServiceItemsStatus = (payload): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: OnboardActionTypes.SET_PROVIDER_SVC_ITEMS_STATUS,
        payload
    });
}

export const abortOnboarding = (): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    localStorage.removeItem('state');
    dispatch({
        type: OnboardActionTypes.ABORT_ONBOARDING
    });
}

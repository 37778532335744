import React, { useState } from 'react';
import './index.css';

import Layout from '../../components/Layout';
import Stepper from '../../components/Stepper';
import _ from 'lodash';
import useDraft from '../../utils/useDraft';
import { TextField, Button, FormControlLabel } from '@material-ui/core';
import { PrimaryButton } from '../../components/Button';
import store, { actions } from '../../store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import apiClient from '../../utils/apiClient';
import { toast } from 'react-toastify';

export default function CreateAppointments() {

    actions.abortOnboarding();

    const history = useHistory();

    const handleNext = async () => {
        history.push('/services')
    }

    return (
        <Layout>
            <h4>Provider Onboarding</h4>
            <div className="mt-6">
                <Stepper/>
            </div>
            <div className="upk-form mt-2 mb-3" style={{ backgroundColor: "white" }}>
                <div className="body">
                    <div>
                        <h6 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>You've completed the onboarding process.</h6>
                        <div className="mt-1">
                            <PrimaryButton label='Return To Dashboard' onClick={handleNext}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

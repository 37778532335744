import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from "../firebaseAuth";
import UserRoles from "../enums/UserRoles";

const ProtectedRoute = (props: any) => {
    const { component, roles = [UserRoles.ADMIN], ...rest } = props;
    const Component: any = component;

    const isAuthed = isAuthenticated(roles);
    return (
        <Route {...rest} render={(_props) => (
            isAuthed
                ? <Component {..._props} />
                : <Redirect to={{ pathname: '/login' }} />
        )} />
    )
}

const ProviderProtectedRoute = (props: any) => {
    return <ProtectedRoute roles={[UserRoles.ADMIN, UserRoles.PROVIDER]} {...props} />
};

export { ProviderProtectedRoute };
export default ProtectedRoute;

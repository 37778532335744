import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, DialogActions, DialogContent, Grid, MenuItem } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment, { Moment } from 'moment-timezone';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import EmailIcon from '@mui/icons-material/Email';

import apiClient from '../../utils/apiClient';
import { CalendarUser } from '../../declarations';
import Modal from '../../components/Modal';
import { AppointmentStatus, ModalState } from '.';
import { PrimaryButton, TextButton } from '../../components/Button';
import Select from '../../components/Select';
import { Backdrop, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

type AppointModalProps = {
  provider: any;
  modalState: ModalState;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  selectedCalendarUser: CalendarUser;
  handleCancelAppt: () => void;
  callAppointments: () => void;
  lateCancel: any;
  setlateCancel: any;
  refundOption:any;
  setRefundOption: any;
};

enum Recipient {
  CUSTOMER = 'CUSTOMER',
  PROVIDER = 'PROVIDER',
  UPKEEP_SUPPORT = 'UPKEEP_SUPPORT',
}

const AppointModal = ({ provider, modalState, setModalState, selectedCalendarUser, handleCancelAppt, callAppointments, lateCancel, setlateCancel, refundOption, setRefundOption}: AppointModalProps) => {
  const [date, setDate] = useState(null);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [services, setServices] = useState([]);
  const [selected, setSelected] = useState({
    slot: null,
    service: null,
    bundle: [],
  });
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(0)
  const [slots, setSlots] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [backdrop, setBackdrop] = useState(false);
  const [value, setValue] = useState('AvailableSlot');
  const [dateTime, setDateTime] = useState(null);
  const tz = modalState.context?.appointments[selectedAppointment]?.location?.timezone || 'America/Los_Angeles'
  const startTime = moment.tz(modalState.context?.appointments[selectedAppointment].startDate, 'UTC').tz(tz);
  const now = moment.tz(tz);
  const isLessThan24Diff = Math.abs(moment.duration(startTime.diff(now)).asHours()) < 24 || false;

  useEffect(() => {
    setSelectedProvider(provider.id);
    apiClient.get('/providers').then(res => {
      let p = res.filter(i => i.location && i.name);
      setProviders(p?.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1) || p);
    });
  }, [provider])

  useEffect(() => {
    try {
      apiClient.get('/user').then(res => {
        const filtered = [];
        res.forEach(u => {
          if (!u.isDeleted) {
            filtered.push({ id: u.id, name: u.displayName, email: u.email });
          }
        });
        setUsers(filtered);
      });
    } catch (err) {
      toast.error('Error getting users.');
      console.error(err);
    }
  }, [provider])

  useEffect(() => {
    let temp = modalState.context?.appointments
    if (temp && temp.length) {
      let appointment = temp[selectedAppointment]
      setDate(dayjs(moment(appointment.startDate).tz(modalState.tz).format('YYYY-MM-DD')));
      if (appointment.serviceItem) {
        setSelected({ ...selected, service: appointment.serviceItem.id, bundle: [], slot: appointment.startDate })
        apiClient.get(`/providers/svc-items?location=${appointment.location.id}`).then(res => {
          setServices(res.map(i => i.serviceItem))
        });
      } else if (appointment.bundle) {
        apiClient.get(`/bundle/svc-items/${appointment.bundle.id}`).then(res => {
          setSelected({ ...selected, service: null, bundle: res.serviceItems.map(i => i.id), slot: appointment.startDate })
        });
      }
    }
  }, [modalState.context, selectedAppointment])

  useEffect(() => {
    if (selectedProvider && providers.length && (selected.service || selected.bundle.length)) {
      const selectedProviderIndex = providers.findIndex(i => i.id === selectedProvider);
      if (selectedProviderIndex !== -1) {
        let momentOne = moment(date.format('YYYY-MM-DD')).tz(providers[selectedProviderIndex]?.timezone || 'America/Los_Angeles', true).startOf('day');
        apiClient.post('/appointments/availability', {
          serviceItemIds: selected.bundle.length ? selected.bundle : [selected.service],
          startDate: momentOne.toISOString(),
          coordinates: providers[selectedProviderIndex]?.location.coordinates
        }).then(res => {
          let slots = []
          res.forEach(element => {
            if (element.providerId === selectedProvider) {
              slots.push(element)
            }
          });
          setSlots(slots);
        });
      }
    }
  }, [date, selected.service, selected.bundle, selectedProvider])

  useEffect(() => {
    if (selectedProvider && providers.length) {
      const selectedProviderIndex = providers.findIndex(i => i.id === selectedProvider);
      if (selectedProviderIndex !== -1) {
        apiClient.get(`/providers/svc-items?location=${providers[selectedProviderIndex]?.location.id}`).then(res => {
          setServices(res.map(i => i.serviceItem))
        });
      }
    }
  }, [selectedProvider])

  useEffect(() => {
    if (selectedCalendarUser && users.length) {
      const user = { id: selectedCalendarUser.id, name: selectedCalendarUser.displayName, email: selectedCalendarUser.email }
      setSelectedUser(user);
    }
  }, [selectedCalendarUser, users])

  const displayDate = (isoDate) => {
    if (isoDate) {
      return moment(new Date(isoDate)).tz(modalState.tz).format('dddd, MMMM Do YYYY, h:mm:ss a');
    }
    return '';
  };

  const sendReceipt = (recipient: Recipient) => {
    apiClient.post('/appointments/receipts', {
      recipient,
      groupId: modalState.context?.appointments[0].groupId,
      provider: provider.id,
    }).then(res => {
      toast.success('Receipt Sent Successfully');
    }).catch(err => toast.error('Failed to send receipt'));
  }

  const receiptButton = (recipient: Recipient) => {
    return (
      <TextButton
        label={<>
          <EmailIcon />
          <span style={{ paddingLeft: "5px" }}>{recipient.split("_")[0]}</span>
        </>}
        style={{
          backgroundColor: '#00af9b',
          color: "white",
          margin: "3px",
          padding: "7px",
          width: "120px"
        }}
        onClick={() => sendReceipt(recipient)}
      />
    )
  }

  const updateAppoinment = async (event) => {
    event.preventDefault();
    setBackdrop(true);
    const appointment = modalState.context?.appointments[selectedAppointment]
    await apiClient.put(`/appointments/reschedule/${appointment.id}`, {
      serviceItemIds: selected.bundle.length ? selected.bundle : [selected.service],
      provider: selectedProvider,
      startDate: selected.slot,
      user: selectedUser.id,
      isAvaliableSlots: (value === 'AvailableSlot'),
      isProviderChanged: (selectedProvider !== provider.id),
      previousProvider: provider
    }).then(res => {
      setBackdrop(false);
      setShowUpdateModal(false);
      setModalState({ ...modalState, open: false });
      callAppointments();
      setSlots(null);
      toast.success('Updated Appointment');
    }).catch(err => { setBackdrop(false); toast.error('Update Appointment Failed'); });
  }

  const filterUserOptions = createFilterOptions({
    stringify: ({ name, email }) => `${name} ${email}`
  })

  const handleDate = (newValue) => {
    setDate(newValue);
    setSelected({ ...selected, slot: newValue })
    setSlots(null);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleTime = (value) => {
    if(value){
      setDateTime(dayjs(value));
      setSelected({ ...selected, slot: moment(value.format()).tz(modalState.tz || 'America/Los_Angeles', true).utc().format() });
    }else{
      setDateTime(null);
    }
  }

  const handleUTCtoProvider = (value) => {
    const utcTime = moment.utc(value);
    const providerTime = utcTime.clone().tz(selectedProvider.tz || 'America/Los_Angeles');
    const formattedProviderTime = providerTime.format('YYYY-MM-DD HH:mm:ss');
    return dayjs(formattedProviderTime)
  }
  return (
    <>
      <Modal
        title="Are you sure you want to cancel the appointment?"
        open={showConfirmCancelModal}
        onChange={() => {
          setShowConfirmCancelModal(false);
        }}>
        <div style={{ marginLeft: "15px", marginTop: "5px"}}>
          <div style={{ fontSize: "16px"}}>Refund points:</div>
          <div style={{marginLeft: "5px"}}>
          <FormControl size='small' margin="dense">
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={0}
              value={refundOption}
              onChange={(e) => setRefundOption(e.target.value)}
            >
              <FormControlLabel value={0} control={<Radio size='small'/>} label="Refund" />
              <FormControlLabel value={1} control={<Radio size='small'/>} label="No Refund" />
              <FormControlLabel value={2} control={<Radio size='small'/>} label="Credit points in account" />
            </RadioGroup>
          </FormControl>
          </div>
        </div>
        {isLessThan24Diff && <div style={{ marginLeft: "15px", marginTop: "5px"}}>
          <div style={{ fontSize: "16px"}}>Charges:</div>
          <div style={{marginLeft: "5px"}}>
            <FormControl size='small' margin="dense">
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={1}
              value={lateCancel}
              onChange={(e) => setlateCancel(e.target.value)}
            >
              <FormControlLabel value={1} control={<Radio size='small' />} label="Charge Fee" />
              <FormControlLabel value={0} control={<Radio size='small' />} label="No Charge Fee" />
            </RadioGroup>
          </FormControl>
          </div>
        </div>}
        <DialogActions>
          <PrimaryButton label="Confirm" onClick={() => {
            handleCancelAppt();
            setShowConfirmCancelModal(false);
          }} />
        </DialogActions>
      </Modal>
      <Modal
        title={modalState.title}
        open={showUpdateModal}
        onChange={() => {
          setShowUpdateModal(false);
          setValue('AvailableSlot');
        }}>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={4}>
                <div style={{ marginLeft: '1rem' }}>User: </div>
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  value={selectedUser}
                  disabled={value === "CustomSlot" && true}
                  id="combo-box-demo"
                  options={users}
                  onChange={(event, newValue) => {
                    setSelectedUser(newValue);
                  }}
                  filterOptions={filterUserOptions}
                  getOptionLabel={({ name, email }) => name || email}
                  renderOption={({ name, email }) => {
                    return name ? (
                      <div>
                        <div>
                          {name}
                        </div>
                        <span>{email}</span>
                      </div>
                    ) : <div>{email}</div>;
                  }}
                  renderInput={(params) => <TextField {...params} label="User" />}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pt: 2 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={4}>
                <div style={{ marginLeft: '1rem' }}>Providers: </div>
              </Grid>
              <Grid item xs={8}>
                <Select
                  labelId="Service"
                  disabled={value === "CustomSlot" && true}
                  onChange={(event) => { setSelectedProvider(event.target.value); setSlots(null); }}
                  value={selectedProvider}>
                  {providers.map((p, index) => {
                    return p ? (<MenuItem style={{ fontFamily: 'Poppins' }} key={index} value={p.id}>
                      {p.name}
                    </MenuItem>) : null;
                  })}
                </Select>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pt: 2 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={4}>
                <div style={{ marginLeft: '1rem' }}>Slots: </div>
              </Grid>
              <Grid item xs={8}  >
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <div style={{ display: "flex" }}>
                    <FormControlLabel value="AvailableSlot" control={<Radio />} label="Available Slot" />
                    <FormControlLabel value="CustomSlot" control={<Radio />} label="Custom Slot" />
                  </div>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {value === "AvailableSlot" ?
                <Box sx={{ pt: 2 }}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <div style={{ marginLeft: '1rem' }}>Date: </div>
                    </Grid>
                    <Grid item xs={8}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Date"
                            value={date}
                            onChange={(newValue) => {
                              handleDate(newValue)
                            }}
                            renderInput={(params) => <TextField style={{ width: "100%" }} {...params} />}
                          />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={{ pt: 2 }}>
                        {
                          slots ? (slots.length ?
                            slots.map((slot, index) => {
                              return (<TextButton
                                key={index}
                                label={moment(slot.earliestAppointmentTime).tz(modalState.tz).format('LT')}
                                style={{
                                  backgroundColor: slot.earliestAppointmentTime === selected.slot ? 'grey' : '#00af9b',
                                  padding: "8px 10px",
                                  color: "white",
                                  marginTop: "15px",
                                  width: "90px",
                                }}
                                onClick={() => {
                                  setSelected({ ...selected, slot: slot.earliestAppointmentTime });
                                }}
                              />)
                            }) : <Box sx={{ textAlign: "center" }}>No slots available</Box>)
                            : (
                              <div style={{ textAlign: "center" }}>
                                <CircularProgress color="inherit" size={20} />
                              </div>
                            )
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                :
                <Box sx={{ pt: 2 }}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <div style={{ marginLeft: '1rem' }}>Date: </div>
                    </Grid>
                    <Grid item xs={8}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDateTimePicker
                            label="Custom Time Slot"
                            value={dateTime ? dateTime : handleUTCtoProvider(selected.slot)}
                            onChange={(newValue) => {
                              handleTime(newValue);
                            }}
                            renderInput={(params) => <TextField style={{ width: "100%" }} {...params} />}
                          />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              }
            </Grid>
          </Box>
          {!selected.bundle.length && services &&
            <Box sx={{ pt: 2 }}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={4}>
                  <div style={{ marginLeft: '1rem' }}>Services: </div>
                </Grid>
                <Grid item xs={8}>
                  <Select
                    labelId="Service"
                    disabled={value === "CustomSlot" && true}
                    onChange={(event) => { setSelected({ ...selected, service: event.target.value }); setSlots(null); }}
                    value={selected.service}>
                    {services.map((service, index) => {
                      return service ? (<MenuItem style={{ fontFamily: 'Poppins' }} key={index} value={service.id}>
                        {service.subCategory ? service.subCategory + " -> " + service.name : service.name}
                      </MenuItem>) : null;
                    })}
                  </Select>
                </Grid>
              </Grid>
            </Box>
          }
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <PrimaryButton sx={{ p: 3 }} label="Save" onClick={updateAppoinment} />
        </DialogActions>
      </Modal>
      <Modal
        title={modalState.title}
        subTitle={modalState.context?.status === AppointmentStatus.CANCELLED || modalState.context?.status === AppointmentStatus.LATE_CANCELLED ? <div style={{ color: "#0A5517", fontSize: "24px" }}>{modalState.context?.status}</div> : null}
        color={modalState.context?.status === AppointmentStatus.CANCELLED || modalState.context?.status === AppointmentStatus.LATE_CANCELLED ? "#AAAAAA" : ""}
        open={modalState.open}
        onChange={(isOpen) => setModalState({ ...modalState, open: isOpen })}>
        <DialogContent dividers>
          <Box p={2}>
            {(selectedCalendarUser && (
              <>
                <span>
                  <span style={{ fontWeight: 'bold' }}>Patient Name:</span> {selectedCalendarUser?.displayName}
                </span>
                <br />
                <span>
                  <span style={{ fontWeight: 'bold' }}>Patient Email:</span> {selectedCalendarUser?.email}
                </span>
                <br />
                <span>
                  <span style={{ fontWeight: 'bold' }}>Patient Phone:</span> {selectedCalendarUser?.phone}
                </span>
                <br />
                <span>
                  <span style={{ fontWeight: 'bold' }}>Patient Birthdate:</span>{' '}
                  {displayDate(selectedCalendarUser?.birthdate)}
                </span>
                <br />
              </>
            )) || (
                <>
                  <span>Loading...</span>
                  <br />
                </>
              )}
            {modalState.context?.appointments?.map((appointment, index) => (
              <React.Fragment key={index}>
                <br />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <span>
                      <span style={{ fontWeight: 'bold' }}>Treatment:</span> {appointment.serviceItemDisplayName}
                    </span>
                    <br />
                    <span>
                      <span style={{ fontWeight: 'bold' }}>Appointment Start Date:</span>{' '}
                      {displayDate(appointment.startDate)}
                    </span>
                  </div>
                  {modalState.context?.status !== AppointmentStatus.CANCELLED ?
                    <TextButton
                      label="Update"
                      style={{
                        backgroundColor: '#00af9b',
                        color: "white",
                        margin: "0"
                      }}
                      onClick={() => {
                        setSelectedAppointment(index);
                        setShowUpdateModal(true);
                      }}
                    />
                    : ""}
                </Box>
                <br />
                <br />
              </React.Fragment>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {receiptButton(Recipient.UPKEEP_SUPPORT)}
            {receiptButton(Recipient.CUSTOMER)}
            {receiptButton(Recipient.PROVIDER)}
          </Box>
          {modalState.context?.status !== AppointmentStatus.CANCELLED ?
            <TextButton
              label="Cancel Appointment"
              style={{ color: '#EF5DA8', padding: "5px" }}
              fullWidth
              onClick={() => {
                setShowConfirmCancelModal(true);
              }}
            />
            : ""}
        </DialogContent>
      </Modal>
    </>
  );
};

export default AppointModal;

import React from 'react';
import _ from 'lodash';

import { StateTransitionListener } from './StateTransitionListener';
import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import Layout from '../../components/ProviderOnboarding/Layout';
import SidePanel from '../../components/ProviderOnboarding/SidePanel';
import SidePanelImage from '../../components/ProviderOnboarding/SidePanelImage';
import OnboardingStepper from '../../components/ProviderOnboarding/Stepper';
import appointmentImage from '../../assets/appointment.png';
import TextInput from '../../components/ProviderOnboarding/TextInput';

import { useForm } from 'react-hook-form';

type Inputs = {
  username: string,
  password: string,
  providerExternalId: string,
}

interface MindbodyCredentialsPropsI {
  getInitialValue: (input: keyof Inputs) => string
  onValueUpdate: (input: keyof Inputs) => () => void
  handleAccountImport: (input: Inputs) => void
  handlePrevious: React.UIEventHandler
}

function MindbodyCredentials(props: MindbodyCredentialsPropsI) {
  const {
    getInitialValue,
    onValueUpdate,
    handleAccountImport,
    handlePrevious,
  } = props;
  const { register, handleSubmit, setError, formState: { errors }, getValues } = useForm<Inputs>({
    defaultValues: {
      username: getInitialValue('username'),
      password: getInitialValue('password'),
      providerExternalId: getInitialValue('providerExternalId'),
    }
  });

  return (
    <Layout>
      <StateTransitionListener/>
      <SidePanel>
          <SidePanelImage imgSrc={appointmentImage} caption={'“Let\'s import some information about your business.”'} />
      </SidePanel>
      <OnboardingStepper totalSteps={3} activeStep={0} checkedSteps={1} />
      <div className="content-container">
        <div className="content">
          <h1>Connect your <em>Mindbody</em> Credentials</h1>
          <p>By granting access, we’ll use your Mindbody account to list you on our Upkeep system. Fill out the following information to get started. </p>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(handleAccountImport)}>
            <div className="input-fields">
              <TextInput
                id="username"
                name="username"
                type="text"
                placeholder="Mindbody Username"
                ref={register({
                  required: 'Username required',
                })}
                onChange={onValueUpdate('username')}
                error={!!errors.username}
                helperText={errors.username && errors.username.message}
              />
              <TextInput
                id="password"
                name="password"
                type="password"
                placeholder="Mindbody Password"
                ref={register({
                  required: 'Password required',
                })}
                onChange={onValueUpdate('password')}
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
              />
              <TextInput
                id="providerExternalId"
                name="providerExternalId"
                type="text"
                placeholder="Mindbody Site ID"
                ref={register({
                  required: 'Site ID required',
                })}
                onChange={onValueUpdate('providerExternalId')}
                error={!!errors.providerExternalId}
                helperText={errors.providerExternalId && errors.providerExternalId.message}
              />
            </div>
            <div className="actions">
              <Button
                type="button"
                color={ColorEnum.transparent}
                textColor="#4E4B66"
                // @ts-ignore
                onClick={handlePrevious}
              >
                Back
              </Button>
              <Button
                type="submit"
                class="next"
                color={ColorEnum.secondary}
                textColor="white"
                active={_.isEmpty(errors)}
              >
                Next
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default MindbodyCredentials;

import React, { useState } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { OutlinedButton, PrimaryButton } from '../../components/Button';
import apiClient from '../../utils/apiClient';
import CloseIcon from '@material-ui/icons/Close';
import clock from '../../assets/Icons/clock.svg';
import profile from '../../assets/Icons/profile.svg';
import Modal from '../../components/Modal';
import CompletedAppointment from './CompleteAppointment';
import { DialogActions } from '@mui/material';
import { AppointmentStatus } from './ProviderOverview';

interface DrawerContentProps {
  closeDrawer: () => void;
  appointment: any;
  timezone: string;
  loadAppointments: () => void;
}

export default function DrawerContent({
  closeDrawer,
  appointment,
  timezone,
  loadAppointments,
}: DrawerContentProps): JSX.Element {

  return (
    <div className={(appointment.status === AppointmentStatus.CANCELLED || appointment.status === AppointmentStatus.LATE_CANCELLED ? "cancelled " : "") + "drawer-content"}>
      <div className="drawer-header">
        <div className="title">
          Scheduled Appointment
        <div style={{ color: "#0A5517", fontSize: "24px", textTransform: 'capitalize' }}>{appointment.status}</div>
        </div>
        <CloseIcon className="close" onClick={closeDrawer} />
      </div>
      <div className="section">
        <div className="subtitle">Booker</div>
        <div className="section-content booker">
          <img style={{ marginRight: '1rem' }} src={profile} alt="profile" />
          <div>
            <div className="subtitle">{appointment.user.displayName}</div>
            <div className="info">
              <span>Patient Email: </span>
              {appointment.user.email}
            </div>
            <div className="info">
              <span>Patient Phone: </span>
              {appointment.user.phone}
            </div>
            <div className="info">
              <span>Patient Birthdate: </span>
              {moment(appointment.user.birthdate).format('MM-DD-YYYY')}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="section-appointment">
        <div className="subtitle">Appointment</div>
        <div className="section-content appointment-drawer">
          <img src={clock} />
          <div className="ml-1">
            <div>
              <div className="item">
                <b>Date:&nbsp;</b>
                <span>
                  {moment(appointment.startDate).tz(timezone).format('ddd')}, {moment(appointment.startDate).tz(timezone).format('MMMM DD')}
                </span>
                <span className="ml-2">
                  {moment(appointment.startDate).tz(timezone).format('h:mm A')} - {moment(appointment.endDate).tz(timezone).format('h:mm A')}
                </span>
              </div>
              <div className="small-text">Time zone - {timezone}</div>
            </div>
            <div className="item treatment">
              <div>
                <b>Treatment:&nbsp;</b>
                <span>{appointment.serviceItemDisplayName}</span>
              </div>
              <div>prepaid</div>
            </div>
          </div>
        </div>
        {appointment.status !== AppointmentStatus.CANCELLED || appointment.status !== AppointmentStatus.LATE_CANCELLED ?
          <div className="mt-1"><CompletedAppointment appointment={appointment} loadAppointments={loadAppointments} className="completed" /></div>
        : ""}
      </div>
    </div>
  );
}

import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

import useStyles from './index.styles';

type PageSidebarProps = {
  title: string;
  listTitle: string;
  children?: React.ReactNode;
  addBtnLabel: string;
  onClickAddBtn: () => void;
};

const PageSidebar = (props: PageSidebarProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.sidebar} display="flex" flexDirection="column">
      <Box px={3} py={5}>
        <Typography variant="h5" className="header-title">
          {props.title}
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: grey[200] }} />
      <Box p={5} flex={1} style={{ overflow: 'auto' }}>
        <Typography gutterBottom className="title">
          {props.listTitle}
        </Typography>
        <Box className={classes.list}>{props.children}</Box>
      </Box>
      <Divider style={{ backgroundColor: grey[200] }} />
      <Box
        className={classes.addButton}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={props.onClickAddBtn}>
        <Typography>{props.addBtnLabel}</Typography>
      </Box>
    </Box>
  );
};

export default PageSidebar;

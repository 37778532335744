import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '../components/TextField';

const MapsAutocomplete = ({ onChange = (args) => {} }) => {
  const [state, setState] = useState({
    address: '',
  });

  const handleChange = (address) => {
    setState({
      ...state,
      address,
    });
  };

  const handleSelect = async (e, suggestion) => {
    if (typeof suggestion === 'object' && suggestion && suggestion.description) {
      const { formattedSuggestion, description, terms } = suggestion;
      try {
        const { lat, lng } = await geocodeByAddress(suggestion.description).then((results) => getLatLng(results[0]));
        const addressComponents = terms.map((term) => term.value);
        const country = addressComponents.pop();
        const state = addressComponents.pop();
        const city = addressComponents.pop();
        const payload = {
          coordinates: { lat, lng },
          name: '',
          fullAddress: description,
          streetAddress: formattedSuggestion.mainText,
          components: {
            streetAddress: formattedSuggestion.mainText,
            city,
            country,
            state,
          },
          originalObject: {
            ...suggestion,
            lat,
            lng,
          },
        };
        onChange(payload);
      } catch (err) {
        //supress error
      }
    }
  };

  
  return (
    <PlacesAutocomplete value={state.address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Autocomplete
            id="credits-autocomplete"
            options={suggestions}
            fullWidth
            getOptionLabel={(s: any) => s.description}
            onChange={(e, s) => handleSelect(e, s)}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => handleChange(e.target.value)}
                variant="outlined"
                {...getInputProps({
                  inputProps: {
                    ...params.inputProps,
                    placeholder: 'Enter your address',
                    id: 'location-search-input',
                  },
                })}
              />
            )}
          />
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default MapsAutocomplete;

import React, { useState, useEffect } from 'react';
import { FormHelperText, FormControl } from '@material-ui/core';
import apiClient from '../utils/apiClient';
import { toast } from 'react-toastify';
import TextField from './TextField';

export default ({ label = "Image URL", value = '', className = "", onChange = (e)=>{}, ...rest }) => {


    const hiddenFileInput = React.useRef(null);

    const handleUploadClick = (e: any) => {
        e && e.preventDefault();
        hiddenFileInput.current.click();
    }

    const handleUpload = async (files: Array<any>) => {
        if(files.length){
            try {
                toast.info('Uploading Image...')
                const file = files[0];
                const formData = new FormData();
                formData.append('file',file);
                
                const { path } = await apiClient.upload('/upload',formData);
                toast.success('Successfully Uploaded Image')
                onChange({
                    target: {
                        value: path
                    }
                });

            } catch(err){
                console.error(err);
            }
            
        }
    }

    return (
        <div>
            <FormControl fullWidth={true} className={className}>
                <TextField
                    id="description"
                    marginTop
                    label={label}
                    fullWidth={true}
                    onChange={onChange}
                    value={value}
                    key='image-uploader-textfield'
                />
                <FormHelperText style={{marginLeft: '.5rem', fontFamily: 'Poppins'}}>
                    <a href="#" onClick={handleUploadClick}>Upload Image</a>
                </FormHelperText>
            </FormControl>

            <input
                {...rest}
                style={{ display: "none" }}
                type="file"
                ref={hiddenFileInput}
                onChange={(e: any) => {handleUpload(e.target.files)}}
            />
        </div>
    )
}
import _ from 'lodash';
import React, { useState } from 'react';

export default (initialValue: any) => {
  const [entity, setEntity] = useState(initialValue);
  const draft = _.cloneDeep(entity);

  const updateEntityAttribute = (attr, val) => {
    _.set(draft, attr, val);
    setEntity(draft);
    return draft;
  };
  const inputHandler = (attr: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value: any = e.target.value;
    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }
    updateEntityAttribute(attr, value);
  };
  const getEntityAttribute = (attr) => {
    return _.get(entity, attr, '');
  };

  return [entity, setEntity, getEntityAttribute, updateEntityAttribute, inputHandler];
};

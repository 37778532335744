enum UserActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_ADMIN = 'SET_ADMIN',
  SET_VIEW_AS_PROVIDER = 'SET_VIEW_AS_PROVIDER',
  SET_VIEW_AS_ADMIN = 'SET_VIEW_AS_ADMIN',
}

interface LoginAction {
  type: UserActionTypes.LOGIN;
}

interface LogoutAction {
  type: UserActionTypes.LOGOUT;
}

interface SetAdminAction {
  type: UserActionTypes.SET_ADMIN;
}

interface SetViewAsProviderAction {
  type: UserActionTypes.SET_VIEW_AS_PROVIDER;
}

interface SetViewAsAdminAction {
  type: UserActionTypes.SET_VIEW_AS_ADMIN;
}

export { UserActionTypes };
export type UserActions = {
  LoginAction: LoginAction;
  LogoutAction: LogoutAction;
  SetAdminAction: SetAdminAction;
  SetViewAsProvider: SetViewAsProviderAction;
  SetViewAsAdmin: SetViewAsAdminAction;
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button, { ColorEnum } from '../../components/ProviderOnboarding/Button';
import OnboardingStepper from '../../components/ProviderOnboarding/Stepper';
import successMedal from './../../assets/success.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    background: '#F8F9FF',
  },
  container: {
    maxWidth: '600px',
    textAlign: 'center',
    margin: 'auto',
    marginTop: '30vh',
  },
  text: {
    fontFamily: 'Poppins !important',
    fontSize: '1rem',
  },
  button: {
    fontFamily: 'Poppins',
    color: 'white',
    maxWidth: '200px',
    margin: '0.5rem',
  },
  spinner: {
    color: 'inherit',
  },
  h1: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '38px',
    letterSpacing: '0.05em',
    color: '#00AF9B',
  },
  h2: {
    fontFamily: 'Poppins',
    fontSize: '35px',
    fontWeight: 700,
    lineHeight: '38px',
    letterSpacing: '0.015em',
    color: '#00C996',
  },
  img: {
    marginBottom: theme.spacing(2),
    width: '80%',
  },
  emoji: {
    display: 'inline',
    height: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const OnboardingComplete = ({ handleReset, gotoWebsite }: { handleReset?: () => void, gotoWebsite: () => void }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <OnboardingStepper totalSteps={2} activeStep={2} checkedSteps={2} />
      <div className={classes.container}>
        {/* <Typography variant="h1">
          Welcome to Upkeep!
        </Typography> */}
        <img src={successMedal} style={{width: '142px', height: '142px', marginBottom: '20px' }}/>
        {/* <Typography variant="h3" className={classes.h1}>
          MEDSPA PROVIDER
        </Typography> */}
        <Typography variant="h4" className={classes.h2} gutterBottom>
          Account Creation Successful
        </Typography>
        <Typography variant="body1" className={classes.text} style={{marginTop: '2rem'}}>
          We're working on tracking and insights for your personal Upkeep account. <br/>
          Check back in the coming weeks for your custom Portal! <br />
        </Typography>
        <div className={classes.actions}>
          <Button
            color={ColorEnum.black}
            active={true}
            type="button"
            onClick={gotoWebsite}
            class={classes.button}
          >
            Learn More
          </Button>
          <Button
            color={ColorEnum.black}
            active={true}
            type="button"
            onClick={() => history.push('/providers')}
            class={classes.button}
          >
            View Provider
          </Button>
          {handleReset &&
            <Button 
              color={ColorEnum.black}
              active={true}
              type="button"
              onClick={handleReset}
              class={classes.button}
            >
              Reset
            </Button>
          }
        </div>
      </div>
    </div>
  )
};

export default OnboardingComplete;

import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableFooter,
  TablePagination,
  Box,
} from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import useAsyncEffect from 'use-async-effect';
import { CSVLink } from 'react-csv';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useMediaQuery } from 'react-responsive';

import { PrimaryButton } from '../../components/Button';
import TablePaginationActions from '../../components/TablePagniationActions';
import CustomDrawer from '../../components/CustomDrawer';
import { Search } from '../../components/TextField';
import Layout from '../../components/Layout';
import useDraft from '../../utils/useDraft';
import apiClient from '../../utils/apiClient';
import DrawerContent from './DrawerContent';
import './index.css';
import UserRoles from '../../enums/UserRoles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  displayName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 500,
    color: '#4E4B66',
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '25px',
    backgroundColor: '#CBF6EF',
    color: '#009384',
    marginRight: '20px',
    fontWeight: 600,
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    "@media (max-width: 700px)": {
      flexDirection : 'column',
      justifyContent: 'center',
    },
    [theme.breakpoints.between('xs', 'md')]: {
      gap: theme.spacing(0.8),
    },
  },
  btn: {
    height: '50px',
    width: '50%',
    "@media (max-width: 700px)": {
      width: '85%'
    },
  },
  informationRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

interface ExpandedState {
  accountInformation: boolean;
  associatedProviders: boolean;
  bookingHistory: boolean;
  promoCreditUse: boolean;
}

const initialExpandedState: ExpandedState = {
  accountInformation: false,
  associatedProviders: false,
  bookingHistory: false,
  promoCreditUse: false,
};

interface LeadsInterface {
  email?: string,
  fullName?: string,
  id: number,
  interestedService?: string,
  location?: string,
  phone?: string
}

function Users() {
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [users, setUsers] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [leads, setLeads] = useState<LeadsInterface[]>([]);
  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const csvDownloadLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [appointments, setAppointments] = useState<null | any[]>([]);
  const [providers, setProviders] = useState<null | any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [drawerState, setDrawerState] = useState<{ open: boolean; context: any }>({
    open: false,
    context: null,
  });
  const [expanded, setExpanded] = useState(initialExpandedState);
  const [isUserChanged, setIsUserChanged] = useState<boolean>(false);

  const [user, setUser, residualUser, updateUser, handleUserInput] = useDraft({});
  const [filters, setFilters] = useState({
    text: null,
  });
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (filters.text === null) {
      return;
    }
    const lowercaseText = filters.text.toLowerCase();
    const userList = users.filter((u) => {
      if (u.displayName && u.displayName.toLowerCase().includes(lowercaseText)) {
        return true;
      }
      if (u.email && u.email.toLowerCase().includes(lowercaseText)) {
        return true;
      }
      return false;
    });
    setUserList(userList);
  }, [filters.text]);

  const fetchUsers = async () => {
    setLoading(true);
    const users = await apiClient.get('/user');
    const filtered = users.filter((u) => !u.isDeleted);
    setUsers(filtered);
    setUserList(filtered);
    setLoading(false);
  };

  const fetchAllUser = async () => {
    const users = await apiClient.get('/user');
    const filtered = users.filter((u) => !u.isDeleted);
    setData(filtered);
    csvDownloadLink.current.link.click()
  }

  const fetchLeads = async () => {
    const leads = await apiClient.get('/leads')
    setLeads(leads);
    csvLink.current.link.click()
  };

  useAsyncEffect(async () => {
    try {
      fetchUsers();
      const providers = await apiClient.get('/providers');
      setProviders(providers);
    } catch (err) {
      toast.error('Error getting users.');
      console.error(err);
    }
  }, []);

  const formatUser = (_user) => {
    const formatted = { ..._user };
    for (const key in formatted) {
      const value = formatted[key];
      if (key === 'birthdate' && value) {
        formatted[key] = moment(value).format('MM/DD/YYYY');
      }
      if (value === null) {
        formatted[key] = '';
      }
    }
    return formatted;
  };

  useAsyncEffect(async () => {
    if (_.has(user, 'id')) {
      try {
        const _user = await apiClient.get(`/user/${user.firebaseUid}`, user);
        _user.role = _user.customClaims?.role || UserRoles.USER;
        setUser(formatUser(_user));
      } catch (err) {
        toast.error('Error fetching user');
        console.error(err);
      }

      try {
        const _userAppts = await apiClient.get('/user/appointments', user);
        setAppointments(_userAppts);
      } catch (err) {
        toast.error('Error fetching appointments');
        console.error(err);
      }
    }
  }, [isUserChanged]);

  const splitString = (name: string = '') => {
    if (!name) {
      return '';
    }
    const uppercase = name.toUpperCase();
    return uppercase.split(' ')[0][0] + uppercase.split(' ')[0][1];
  };

  const closeDrawer = () => {
    const updated = { ...expanded };
    for (const name in expanded) {
      updated[name] = false;
    }
    setExpanded(updated);
    setDrawerState({ context: null, open: false });
  };

  const openDrawer = (user) => {
    setDrawerState({
      open: true,
      context: formatUser(user),
    });
  };

  const onShowUserInfo = (row) => {
    setUser(formatUser(row));
    setIsUserChanged(!isUserChanged);
    openDrawer(row);
  };

  return (
    <Layout>
      <div className="upk-form" style={{ backgroundColor: 'white' }}>
        <Box className={classes.header}>
          <div>
            <h4>Users</h4>
          </div>
          <div className={classes.search}>
            <Search label="Search" onChange={(e) => setFilters({ ...filters, text: e.target.value.trim() })} />
            <PrimaryButton label="Invite" className={classes.btn} />
            <PrimaryButton label="Download Waitlist" onClick={fetchLeads} className={classes.btn} />
            <PrimaryButton label="Download Users List" onClick={fetchAllUser} className={classes.btn} />
            <CSVLink
              data={leads}
              className='hidden'
              ref={csvLink}
              target='_blank'
              filename="upkeep-waitlist.csv"></CSVLink>
            <CSVLink
              data={data}
              ref={csvDownloadLink}
              target='_blank'
              filename="upkeep-user-list.csv">
            </CSVLink>
          </div>
        </Box>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="custom pagination table">
                <TableBody>
                { userList.length > 0 ? (rowsPerPage > 0
                    ? userList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : userList
                  ).map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ height: '50px', borderRadius: '2px' }}
                      onClick={() => {
                        return isTabletOrMobile ? onShowUserInfo(row) : null;
                      }}>
                      <TableCell component="th" scope="row">
                        <div className={classes.displayName}>
                          <div className={classes.circle}>{splitString(row.displayName)}</div>
                          {row.displayName ? row.displayName : ''}
                        </div>
                      </TableCell>
                      <TableCell style={{ color: '#6E7191' }} component="th" scope="row">
                        {row.email}
                      </TableCell>
                      <TableCell style={{ color: '#A0A3BD' }} component="th" scope="row">
                        {row.phone}
                      </TableCell>
                      <TableCell style={{ color: '#A0A3BD' }} component="th" scope="row">
                        <div
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => onShowUserInfo(row)}>
                          <MoreHoriz />
                        </div>
                      </TableCell>
                    </TableRow>
                  )) : <div style={{ textAlign: 'center', paddingTop: "25px", paddingBottom: "25px" }}>User Not Found</div> }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={userList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <CustomDrawer closeDrawer={closeDrawer} open={drawerState.open}>
              {drawerState.context && (
                <DrawerContent
                  closeDrawer={closeDrawer}
                  user={user}
                  handleUserInput={handleUserInput}
                  residualUser={residualUser}
                  setUser={setUser}
                  appointments={appointments}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  fetchUsers={fetchUsers}
                  providers={providers}
                />
              )}
            </CustomDrawer>
          </>
        )}
      </div>
    </Layout>
  );
}

export default Users;
